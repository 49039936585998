import { render, staticRenderFns } from "./NftImageComponent.vue?vue&type=template&id=8d0345d0&scoped=true"
import script from "./NftImageComponent.vue?vue&type=script&lang=ts"
export * from "./NftImageComponent.vue?vue&type=script&lang=ts"
import style0 from "./NftImageComponent.scss?vue&type=style&index=0&id=8d0345d0&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d0345d0",
  null
  
)

export default component.exports