export class MatrixSize {
    // eslint-disable-next-line no-useless-constructor
    constructor (
        public maxX: number,
        public maxY: number,
    ) { }

    checkAndSetMaxX (x: number): boolean {
        if (this.maxX < x) {
            this.maxX = x;
            return true;
        }
        return false;
    }

    checkAndSetMaxY (y: number): boolean {
        if (this.maxY < y) {
            this.maxY = y;
            return true;
        }
        return false;
    }

    public get width (): number {
        return this.maxX;
    }
    
    public get height (): number {
        return this.maxY;
    }
}