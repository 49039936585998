import { render, staticRenderFns } from "./Password.vue?vue&type=template&id=0aa7446d&scoped=true"
import script from "./Password.vue?vue&type=script&setup=true&lang=ts"
export * from "./Password.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Password.vue?vue&type=style&index=0&id=0aa7446d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aa7446d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseInputOnly: require('/root/dashboard/core/components/base/input/Only.vue').default,BaseInputTemplate: require('/root/dashboard/core/components/base/input/Template.vue').default})
