import { ParcelBufferState } from "../constants/EnumParcelBufferState";
import { ParcelType } from "../constants/EnumParcelType";
import { getParcelLandType } from "../helpers/getParcelLandType";
import { getParcelState } from "../helpers/getParcelState";
import { ParcelOwner } from "../models/ParcelOwner";
import { RowImageData } from "../types/RowImageData";
import { MetaParcel } from "./MetaParcel";
import { MetaReservedArea } from "./MetaReservedArea";
import { MetaMatrix } from "./MetaMatrix";
import { MatrixRow } from "./MatrixRow";
import { MapSquareCoordinates } from "./MapSquareCoordinates";

const LandTypeKeys = {
    [ParcelBufferState.PARCEL]: 'freeLandTypesInRect',
    [ParcelBufferState.IMAGE]: 'imageLandTypesInRect',
    [ParcelBufferState.OWNED]: 'ownedLandTypesInRect',
    [ParcelBufferState.SELF_OWNED]: 'selfOwnedLandTypesInRect',
    [ParcelBufferState.RESERVED]: 'reservedLandTypesInRect',
} as const;

const ParcelArrayKeys = {
    [ParcelBufferState.PARCEL]: 'free',
    [ParcelBufferState.IMAGE]: 'image',
    [ParcelBufferState.OWNED]: 'owned',
    [ParcelBufferState.SELF_OWNED]: 'selfOwned',
    [ParcelBufferState.RESERVED]: 'reserved',
} as const;

export class MapSquarePreciseStats {
    reserved: Array<MetaParcel>;
    owned: Array<MetaParcel>;
    selfOwned: Array<MetaParcel>;
    image: Array<MetaParcel>;
    free: Array<MetaParcel>;
    reservedLandTypesInRect = new Set<ParcelType>();
    ownedLandTypesInRect = new Set<ParcelType>();
    selfOwnedLandTypesInRect = new Set<ParcelType>();
    imageLandTypesInRect = new Set<ParcelType>();
    freeLandTypesInRect = new Set<ParcelType>();
    reservedAreas = new Set<MetaReservedArea>();
    images = new Set<RowImageData>();
    owners = new Set<ParcelOwner>();
    allLandTypesInRect = new Set<ParcelType>();
    noParcel = 0;
    total = 0;

    statsArrayCounters = {
        reserved: 0,
        owned: 0,
        selfOwned: 0,
        image: 0,
        free: 0,
    };

    constructor (
        x: number,
        y: number,
        x2: number,
        y2: number,
        matrix: MetaMatrix,
        continuationCheck?: (stats: MapSquarePreciseStats, state: ParcelBufferState) => boolean,
    ) {
        const { height, width } = matrix.matrixSize;
        const coords = new MapSquareCoordinates({ x, y, x2, y2 });
        const {
            x: startX,
            y: startY,
            x2: endX,
            y2: endY,
        } = coords.boundedCoords(
            0,
            0,
            width - 1,
            height - 1,
        );
        Object.values(ParcelArrayKeys).forEach((key) => { this[key] = new Array(coords.area); });
        const rows = matrix.rows;
        const matrixBuffer = matrix.matrixBuffer;
        const parcelHandlers = {
            [ParcelBufferState.NO_PARCEL]: this.handleParcel,
            [ParcelBufferState.PARCEL]: this.handleParcel,
            [ParcelBufferState.RESERVED]: this.handleReservedParcel,
            [ParcelBufferState.OWNED]: this.handleOwnedParcel,
            [ParcelBufferState.SELF_OWNED]: this.handleOwnedParcel,
            [ParcelBufferState.IMAGE]: this.handleImageParcel,
        };

        // eslint-disable-next-line no-labels
        outer:
        for (let row = startY; row <= endY; row++) {
            const currentRow = rows[row];
            for (let col = startX; col <= endX; col++) {
                const state = getParcelState(matrixBuffer, width, col, row);
                const handler = parcelHandlers[state];
                handler.call(this, matrixBuffer, width, currentRow, col, state);
                if (continuationCheck && !continuationCheck(this, state)) {
                    // eslint-disable-next-line no-labels
                    break outer;
                }
            }
        }
        for (const key in this.statsArrayCounters) {
            this[key].length = this.statsArrayCounters[key];
        }
    }

    private handleParcel (
        matrixBuffer: Uint8Array,
        matrixWidth: number,
        row: MatrixRow,
        x: number,
        state: ParcelBufferState,
    ) {
        const type = getParcelLandType(matrixBuffer, matrixWidth, x, row.rowNumber);
        const landTypeKey = LandTypeKeys[state];
        const parcelArrayKey = ParcelArrayKeys[state];
        this.total++;
        if (state === ParcelBufferState.NO_PARCEL) {
            this.noParcel++;
            return null;
        }
        const parcel = row.at(x);
        parcel.type = type;
        this.allLandTypesInRect.add(type);
        this[landTypeKey].add(type);
        this[parcelArrayKey][this.statsArrayCounters[parcelArrayKey]] = parcel;
        this.statsArrayCounters[parcelArrayKey]++;
        return parcel;
    };

    private handleReservedParcel (
        matrixBuffer: Uint8Array,
        matrixWidth: number,
        row: MatrixRow,
        x: number,
        state: ParcelBufferState,
    ) {
        const parcel = this.handleParcel(matrixBuffer, matrixWidth, row, x, state);
        this.reservedAreas.add(parcel.reservedArea);
    }

    private handleOwnedParcel (
        matrixBuffer: Uint8Array,
        matrixWidth: number,
        row: MatrixRow,
        x: number,
        state: ParcelBufferState,
    ) {
        const parcel = this.handleParcel(matrixBuffer, matrixWidth, row, x, state);
        this.owners.add(parcel.owner);
    }

    private handleImageParcel (
        matrixBuffer: Uint8Array,
        matrixWidth: number,
        row: MatrixRow,
        x: number,
        state: ParcelBufferState,
    ) {
        const parcel = this.handleParcel(matrixBuffer, matrixWidth, row, x, state);
        this.images.add(parcel.image);
        this.owners.add(parcel.owner);
    }
}