import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import JwtService from "~/core/services/JwtService";
import {getClientRuntimeConfig} from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();
const GYMNET_API_URL = $config.gymnetApi;

export const state = () => {
    return {
        address: "",
        oldAddress: "",
        initWalletInfo: {}
    };
};

export const actions = {
    async getCurrentWallet({commit, dispatch}) {

        let addresses = [];
        if (this.$ethereum()) { // TODO replace eth_requestAccounts with eth_accounts
            if (this.$ethereum().enable) {
                addresses = await this.$ethereum().enable();
            } else if (this.$ethereum().request) {
                addresses = await this.$ethereum().request({
                    method: "eth_requestAccounts",
                });
            } else if (this.$web3().requestAccounts) {
                addresses = await this.$web3().requestAccounts();
            }
            if (this.$ethereum().on) {
                this.$ethereum().on("accountsChanged", async (accounts) => {
                    const address = accounts[0];
                    dispatch('application/driver/setCurrentActiveAddress', address, {root: true})
                });
            }
            if (this.$web3()) {
                if (!addresses.length) {
                    addresses = await this.$web3().eth.getAccounts();
                }
                const address = this.$ethereum()?.selectedAddress || addresses[0];
                if (!address) {
                    throw new Error("NO_WEB3");
                }

                dispatch('application/driver/setCurrentActiveAddress', address, {root: true})
                return address;
            }
        }
        return null;
    },
    async login({commit, dispatch}) {
        let address = await dispatch("getCurrentWallet");
        if(address) {
            await dispatch('checkAddress', {address});
        } else {
            throw new Error("NO_WEB3");
        }
        return null;
    },
    async checkAddress({commit, dispatch, state}, payload) {
        if (payload.address?.toLowerCase() === state.address?.toLowerCase()) {
            return;
        }

        if (payload.address) {
            if (state.address !== '') {
                JwtService.destroySignature();
                JwtService.destroyToken();
            }
            commit('SET_OLD_ADDRESS', state.address)
            commit('SET_ADDRESS', payload.address);
            await MetaWorldManager.sharedInstance().setWeb3Properties(this.$web3, this.$readWeb3, this.$contracts, this.$write, this.$ethereum, this.$chainId, payload.address);
            await dispatch('application/driver/initializeApp', payload.address, {root: true});
        }
    },
    async cleanAddresses({commit}) {
        commit('SET_ADDRESS', '');
        commit('SET_OLD_ADDRESS', '');
    },
    async initWallet({commit}, {address, generateRefLink = 0}) {
        let refCodeQuery = '';
        let info = '';
        if (generateRefLink) {
          info = await this.$api.$get(`${GYMNET_API_URL}/api/init-wallet?address=${address}${refCodeQuery}&generateRefLink=${generateRefLink}`)
        } else {
          info = await this.$api.$get(`${GYMNET_API_URL}/api/init-wallet?address=${address}${refCodeQuery}`)
        }

        commit('SET_INIT_WALLET_INFO', info);
        return info
  },
};

export const mutations = {
    SET_ADDRESS(state, address) {
        state.address = address;
    },

    SET_OLD_ADDRESS(state, oldAddress) {
        state.oldAddress = oldAddress;
    },

    SET_INIT_WALLET_INFO(state, info) {
        state.initWalletInfo = info
    }
};

export const getters = {
    address: ({address}) => address,
    oldAddress: ({oldAddress}) => oldAddress,
    isVerified: ({isVerified}) => isVerified,
    initWalletInfo: ({initWalletInfo}) => initWalletInfo,
};
