import { render, staticRenderFns } from "./PasswordCriteria.vue?vue&type=template&id=9f5ff5ae&scoped=true"
import script from "./PasswordCriteria.vue?vue&type=script&setup=true&lang=ts"
export * from "./PasswordCriteria.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PasswordCriteria.vue?vue&type=style&index=0&id=9f5ff5ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f5ff5ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/dashboard/core/components/base/image/SvgThemedIcon.vue').default,BaseInputOnly: require('/root/dashboard/core/components/base/input/Only.vue').default,BaseInputTemplate: require('/root/dashboard/core/components/base/input/Template.vue').default})
