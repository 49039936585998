
export enum WebGLExtensionsByFormat {
    'dxt' = 'WEBGL_compressed_texture_s3tc',
    'dxt-srgb' = 'WEBGL_compressed_texture_s3tc_srgb',
    'etc1' = 'WEBGL_compressed_texture_etc1',
    'etc2' = 'WEBGL_compressed_texture_etc',
    'pvrtc' = 'WEBGL_compressed_texture_pvrtc',
    'atc' = 'WEBGL_compressed_texture_atc',
    'astc' = 'WEBGL_compressed_texture_astc',
    'rgtc' = 'EXT_texture_compression_rgtc',
};