import { ParcelBufferState } from "../constants/EnumParcelBufferState";
import { getParcelState } from "./getParcelState";

export function isNoParcel (
    matrixBuffer: Uint8Array,
    matrixWidth: number,
    x: number,
    y: number,
): boolean {
    return getParcelState(matrixBuffer, matrixWidth, x, y) === ParcelBufferState.NO_PARCEL;
}