import { ParcelBufferState } from "../constants/EnumParcelBufferState";
import { getParcelState } from "../helpers/getParcelState";
import { MapSquareCoordinates } from "./MapSquareCoordinates";
import { MetaMatrix } from "./MetaMatrix";

const ParcelCountKeys = {
    [ParcelBufferState.NO_PARCEL]: 'noParcel',
    [ParcelBufferState.PARCEL]: 'free',
    [ParcelBufferState.IMAGE]: 'image',
    [ParcelBufferState.OWNED]: 'owned',
    [ParcelBufferState.SELF_OWNED]: 'selfOwned',
    [ParcelBufferState.RESERVED]: 'reserved',
} as const;

export class MapSquareStats {
    reserved = 0;
    owned = 0;
    selfOwned = 0;
    image = 0;
    free = 0;
    noParcel = 0;
    total = 0;

    constructor (
        x: number,
        y: number,
        x2: number,
        y2: number,
        matrix: MetaMatrix,
        continuationCheck?: (stats: MapSquareStats, state: ParcelBufferState) => boolean,
    ) {
        const { height, width } = matrix.matrixSize;
        const coords = new MapSquareCoordinates({ x, y, x2, y2 });
        const {
            x: startX,
            y: startY,
            x2: endX,
            y2: endY,
        } = coords.boundedCoords(
            0,
            0,
            width - 1,
            height - 1,
        );
        const matrixBuffer = matrix.matrixBuffer;
        for (let row = startY; row <= endY; row++) {
            for (let col = startX; col <= endX; col++) {
                const state = getParcelState(matrixBuffer, width, col, row);
                const key = ParcelCountKeys[state];
                this[key]++;
                this.total++;
                if (continuationCheck && !continuationCheck(this, state)) {
                    return;
                }
            }
        }
    }
}