import { Purchase } from '~/core/types/purchase-popup-2/Purchase';
import { PURCHASE_STEPS } from '~/core/types/purchase-popup-2/PurchaseSteps';
import { MetaWeb3Transaction } from '~/core/models/MetaWeb3Transaction';
import { FinishReasonEnum } from '~/core/types/purchase-popup-2/FinishReasonEnum';
import { Blockchains, BlockchainsBUSD } from '~/core/constants/flows/purchase/Blockchains';
import TokenAsset from '~/core/models/purchase-popup-2/TokenAsset';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import { ExternalWalletPayment } from '~/core/models/purchase-popup-2/ExternalWalletPayment';
import {
    BUNDLE_TYPE_STARTER_BUNDLE,
    BUNDLE_TYPE_SUPER_BUNDLE_R22_L,
    BUNDLE_TYPE_SUPER_BUNDLE_R22_XL,
    BUNDLE_TYPE_SUPER_BUNDLE_R22_XXL,
    NULLABLE_WALLET_ADDRESS
} from '~/core/services/utils/Constants';

type PurchaseControlState = {
    isPurchasePopupVisible: boolean,
    startStep: PURCHASE_STEPS,
    purchase: Purchase,
    isFinished: boolean,
    finishStep: PURCHASE_STEPS,
    finishReason: FinishReasonEnum,
    externalPayment: ExternalWalletPayment,
}

const state = () => {
    return {
        isPurchasePopupVisible: false,
        purchase: {},
        startStep: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
        isFinished: false,
        finishReason: '',
        finishStep: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
        externalPayment: null,
    };
};

const actions = {
    show ({ commit }, purchase: Purchase) {
        commit('SET_PURCHASE_STEP', PURCHASE_STEPS.SELECT_PRODUCT_OWNER);
        commit('SET_PURCHASE_OBJECT', purchase);
        commit('SET_PURCHASE_POPUP_STATUS', true);
        commit('SET_IS_FINISHED', false);
    },
    hide ({ commit }, { finishReason, step = null }) {
        commit('SET_PURCHASE_STEP', PURCHASE_STEPS.SELECT_PRODUCT_OWNER);
        commit('SET_PURCHASE_OBJECT', {});
        commit('SET_PURCHASE_POPUP_STATUS', false);
        commit('SET_IS_FINISHED', true);
        commit('SET_FINISH_REASON', finishReason);
        commit('SET_FINISH_STEP', step);
        commit('SET_EXTERNAL_PAYMENT', null);
    },
    async showCompleted ({ commit }, completedPurchase: MetaWeb3Transaction) {
        commit('SET_PURCHASE_STEP', PURCHASE_STEPS.SELECT_PRODUCT_OWNER);
        commit('SET_PURCHASE_OBJECT', {params: []});
        commit('SET_PURCHASE_POPUP_STATUS', true);
        commit('SET_IS_FINISHED', false);
        const allExternalCurrencies = Blockchains.reduce((acc, blockchain) => {
            return acc.concat(blockchain.cryptocurrencies);
        }, []);

        const currency = [...allExternalCurrencies, BlockchainsBUSD].find((currency) => {
            return currency.code === completedPurchase.currency;
        });
        const purchaseName = completedPurchase.formattedProductType;
        const usdtAmount = Number(completedPurchase.amount_in_usdt);
        const amount = Number(completedPurchase.amount);
        const currencyRate = usdtAmount / amount;
        const currencyAsset = new TokenAsset(
            null,
            currency.name,
            currency.code,
            1,
            currencyRate,
            false,
            MetaWorldManager.sharedInstance(),
            currency.chainName,
        );
        const externalPayment = new ExternalWalletPayment(
            completedPurchase.rawPaymentData,
            currencyAsset,
            currency,
        );
        let purchase: Partial<Purchase> = {
            purchaseName,
            params: [],
            product_type: completedPurchase.productType,
            quantity: Number(completedPurchase.quantity),
        };
        if(completedPurchase.productType === 'bundle') {
            const bundlesData = await MetaWorldManager.sharedInstance().getBundlesDataFromContract(NULLABLE_WALLET_ADDRESS);
            const cashFtBundlesPromise = this.$contracts[this.metaverseId]
                .CashFT
                .methods
                .getInfo(NULLABLE_WALLET_ADDRESS)
                .call()
                .then((res) => {
                    return Object.keys(res)
                        .filter((key) => isNaN(Number(key)) && typeof res[key] === 'object')
                        .map((key) => {
                            const bundleData = res[key];
                            return [
                                Number(bundleData[0]),
                                Number(bundleData[1]),
                                0,
                            ];
                        });
                });
            let bundle = bundlesData.find((bnd) => Number(bnd[0]) === Number(completedPurchase.bundleId));
            if (!bundle) {
                if (completedPurchase.bundleId === BUNDLE_TYPE_SUPER_BUNDLE_R22_L) {
                    bundle = [800, 320];
                    externalPayment.canRequestAnotherPayment = false;
                } else if (completedPurchase.bundleId === BUNDLE_TYPE_SUPER_BUNDLE_R22_XL) {
                    bundle = [2000, 684];
                    externalPayment.canRequestAnotherPayment = false;
                } else if (completedPurchase.bundleId === BUNDLE_TYPE_SUPER_BUNDLE_R22_XXL) {
                    bundle = [5000, 2000];
                    externalPayment.canRequestAnotherPayment = false;
                }
            }
            if (!bundle) {
                const cashFtBundles = await cashFtBundlesPromise;
                bundle = cashFtBundles.find((bnd) => Number(bnd[0]) === Number(completedPurchase.bundleId));
            }
            purchase = {
                purchaseName,
                params: [],
                product_type: completedPurchase.productType,
                bundle_sc_id: completedPurchase.bundleId.toString(),
                bundleData: {
                    numberOfParcels: bundle ?  parseInt(bundle[1]) : 0,
                },
                quantity: Number(completedPurchase.quantity),
            };
        } else {
            await Promise.resolve(); // Workaround for vuex store not updating
        }
        commit('SET_PURCHASE_OBJECT', purchase);
        commit('SET_EXTERNAL_PAYMENT', externalPayment);
    },
};

const mutations = {
    SET_PURCHASE_POPUP_STATUS (state: PurchaseControlState, payload: boolean) {
        state.isPurchasePopupVisible = payload;
    },
    SET_PURCHASE_OBJECT (state: PurchaseControlState, payload: Purchase) {
        state.purchase = payload;
    },
    SET_PURCHASE_STEP (state: PurchaseControlState, payload: PURCHASE_STEPS) {
        state.startStep = payload;
    },
    SET_IS_FINISHED (state: PurchaseControlState, payload: boolean) {
        state.isFinished = payload;
    },
    SET_FINISH_REASON (state: PurchaseControlState, payload: FinishReasonEnum) {
        state.finishReason = payload;
    },
    SET_FINISH_STEP (state: PurchaseControlState, payload: PURCHASE_STEPS) {
        state.finishStep = payload;
    },
    SET_EXTERNAL_PAYMENT (state: PurchaseControlState, payload: ExternalWalletPayment) {
        state.externalPayment = payload;
    }
};

const getters = {
    isPurchasePopupVisible: (state: PurchaseControlState) => state.isPurchasePopupVisible,
    purchase: (state: PurchaseControlState) => state.purchase,
    startStep: (state: PurchaseControlState) => state.startStep,
    isFinished: (state: PurchaseControlState) => state.isFinished,
    finishReason: (state: PurchaseControlState) => state.finishReason,
    externalPayment: (state: PurchaseControlState) => state.externalPayment,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
