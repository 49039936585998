import { MatrixSize } from "./MatrixSize";
import { MatrixRow } from "./MatrixRow";
import { MetaReservedArea } from "./MetaReservedArea";
import { LandBoundaries } from "../types/LandBoundaries";
import { SquareSize } from "../types/SquareSize";
import { MapSquarePreciseStats } from "./MapSquarePreciseStats";
import { ParcelBufferState } from "../constants/EnumParcelBufferState";
import { RowImageData } from "../types/RowImageData";
import { MapSquareStats } from "./MapSquareStats";

export class MetaMatrix {
    public matrixBuffer: Uint8Array | null = null;
    public matrixSize = new MatrixSize(-1, -1);
    public rows: Array<MatrixRow> = [];

    constructor () {
        Object.defineProperty(this, 'matrixBuffer', { enumerable: false });
    }

    public createMatrix (boundaries: LandBoundaries, matrixSize: SquareSize) {
        const matrixHeight = matrixSize.height;
        const matrixWidth = matrixSize.width;
        this.matrixSize.checkAndSetMaxY(matrixHeight);
        this.matrixSize.checkAndSetMaxX(matrixWidth);
        this.initializeMatrixBuffer();
        const matrixBuffer = this.matrixBuffer;
        const rows = new Array(matrixHeight);
        for (let rowIndex = 0; rowIndex < matrixHeight; rowIndex++) {
            const rowBounds = boundaries[rowIndex];
            const rowBoundsLength = rowBounds.length;
            const matrixRow = new MatrixRow(matrixWidth, rowIndex, matrixBuffer);
            for (let boundIndex = 0; boundIndex < rowBoundsLength; boundIndex++) {
                const bounds = rowBounds[boundIndex];
                matrixRow.addBounds(bounds);
            }
            rows[rowIndex] = matrixRow;
        }
        this.rows = rows;
    }

    public addReservedArea (area: MetaReservedArea) {
        const rows = this.rows;
        const areaLastRow = area.endY;
        for (let i = area.y; i <= areaLastRow; i++) {
            rows[i].addReservedArea(area);
        }
    }

    public removeReservedArea (area: MetaReservedArea) {
        const rows = this.rows;
        const areaLastRow = area.endY;
        for (let i = area.y; i <= areaLastRow; i++) {
            rows[i].removeReservedArea(area);
        }
    }

    public clearReservedAreas () {
        for (const row of this.rows) {
            row.clearReservedAreas();
        }
    }

    public clearOwnedParcels () {
        for (const row of this.rows) {
            row.clearOwnedParcels();
        }
    }

    public initializeMatrixBuffer () {
        const matrixSize = this.matrixSize;
        this.matrixBuffer = new Uint8Array(matrixSize.width * matrixSize.height * 4);
    }

    public addNewParcelImage(imageData: RowImageData) {
        const startRow = imageData.y;
        const endRow = imageData.y + imageData.height - 1;
        for (let i = startRow; i <= endRow; i++) {
            this.rows[i].addNewParcelImage(imageData);
        }
    }

    public removeParcelImage(imageData: RowImageData) {
        const startRow = imageData.y;
        const endRow = imageData.y + imageData.height - 1;
        for (let i = startRow; i <= endRow; i++) {
            this.rows[i].removeParcelImage(imageData);
        }
    }

    public replaceParcelImage(imageData: RowImageData) {
        const startRow = imageData.y;
        const endRow = imageData.y + imageData.height - 1;
        for (let i = startRow; i <= endRow; i++) {
            this.rows[i].replaceParcelImage(imageData);
        }
    }

    public getImagesInRect (x: number, y: number, width: number, height: number) {
        const rows = this.rows;
        const images = new Set<RowImageData>();
        const viewportBottomEdge = y + height;
        for (let i = y; i <= viewportBottomEdge; i++) {
            const row = rows[i];
            if (!row) {
                continue;
            }
            row.imagesFromTo(x, x + width, images);
        }
        return images;
    }

    public getSquareStats (
        x: number,
        y: number,
        x2: number,
        y2: number,
        continuationCheck: (stats: MapSquareStats, state: ParcelBufferState) => boolean = null,
    ): MapSquareStats {
        return new MapSquareStats(x, y, x2, y2, this, continuationCheck);
    }

    public getSquarePreciseStats (
        x: number,
        y: number,
        x2: number,
        y2: number,
        continuationCheck: (stats: MapSquarePreciseStats, state: ParcelBufferState) => boolean = null,
    ) {
        return new MapSquarePreciseStats(x, y, x2, y2, this, continuationCheck);
    }

}
