export const STATE = {
    QUEUE: "queue",
    IS_SUCCESS_MODAL_OPEN: 'isSuccessModalOpen',
    SUCCESS_MODAL_TITLE: 'successModalTitle',
    SUCCESS_MODAL_DESC: 'successModalDesc',
    SUCCESS_MODAL_BTN_TEXT: 'successModalBtnText',
    SUCCESS_MODAL_BTN_ATTRS: 'successModalBtnAttrs',
    SUCCESS_MODAL_BUTTON_VISIBLE: 'successModalButtonVisible',
    SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL: 'successModalCloseByClickModal',
    SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE: 'successModalCloseOnPressEscape',
    SUCCESS_MODAL_TIMEOUT_ID: 'successModalTimeoutId',
} as const;

export const ACTIONS = {
    NEXT_MODAL: "nextModal",
    SHOW_SUCCESS_MODAL: 'showSuccessModal',
    HIDE_SUCCESS_MODAL: 'hideSuccessModal',
    CANCEL_SUCCESS_CLOSE_TIMEOUT: 'cancelSuccessCloseTimeout',
    AWAIT_FOR_CLOSED:"awaitForClosed",
} as const;

export const MUTATIONS  = {
    ADD_TO_QUEUE: "ADD_TO_QUEUE",
    REMOVE_FROM_QUEUE: "REMOVE_FROM_QUEUE",
    SET_CURRENT_MODAL: "SET_CURRENT_MODAL",
    SET_IS_SUCCESS_MODAL_OPEN: 'SET_IS_SUCCESS_MODAL_OPEN',
    SET_SUCCESS_MODAL_TITLE: 'SET_SUCCESS_MODAL_TITLE',
    SET_SUCCESS_MODAL_DESC: 'SET_SUCCESS_MODAL_DESC',
    SET_SUCCESS_MODAL_BTN_TEXT: 'SET_SUCCESS_MODAL_BTN_TEXT',
    SET_SUCCESS_MODAL_BTN_ATTRS: 'SET_SUCCESS_MODAL_BTN_ATTRS',
    SET_SUCCESS_MODAL_BUTTON_VISIBLE: 'SET_SUCCESS_MODAL_BUTTON_VISIBLE',
    SET_SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL: 'SET_SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL',
    SET_SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE: 'SET_SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE',
    SET_SUCCESS_MODAL_TIMEOUT_ID: 'SET_SUCCESS_MODAL_TIMEOUT_ID',
} as const;

export const GETTERS = {
    IS_SUCCESS_MODAL_OPEN: 'isSuccessModalOpen',
    SUCCESS_MODAL_TITLE: 'successModalTitle',
    SUCCESS_MODAL_DESC: 'successModalDesc',
    SUCCESS_MODAL_BTN_TEXT: 'successModalBtnText',
    SUCCESS_MODAL_BTN_ATTRS: 'successModalBtnAttrs',
    SUCCESS_MODAL_BUTTON_VISIBLE: 'successModalButtonVisible',
    SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL: 'successModalCloseByClickModal',
    SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE: 'successModalCloseOnPressEscape',
    SUCCESS_MODAL_TIMEOUT_ID: 'successModalTimeoutId',
    AGGREGATED_MODAL_STATE: 'aggregatedModalState',
} as const;
