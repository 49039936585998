
import { mapActions, mapGetters } from "vuex";
import { initializeComponents, reinitializeComponents } from '@/core/services/keenthemes';
import {
    AUTH_FLOW_STEPS,
    diagLog,
    METAVERSE_SWITCH_ASK_KEY,
    ERROR_STATUSES,
    NULLABLE_WALLET_ADDRESS,
} from "~/core/services/utils/Constants";
import { MetaWorldManager } from "@/core/services/map/MetaWorldManager";
import { PopupHelper } from "@/core/helpers/PopupHelper";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { PartnerAreaPages } from "@/constants/constants";
import { METAVERSE_IDS } from "@/core/services/utils/MetaverseConstants";
import SignatureDeclinedError from "@/core/helpers/SignatureDeclinedError";
import LayoutService from '@/core/services/LayoutService';
import ResponsiveLayout from "@/layouts/responsive.vue";
import { GETTERS as SIDEBAR_GETTERS, ACTIONS as SIDEBAR_ACTIONS } from '~/store/affility/layout/sidebar/names';
import { ACTIONS as UNREAD_NOTIFICATION_ACTIONS } from '~/store/affility/user/notifications/unread/names';
import { ACTIONS as USER_ACTIONS } from '~/store/affility/user/names';
import { ACTIONS as PROMOS_ACTIONS } from '~/store/affility/promos/names';
import { ACTIONS as MY_TEAM_ACTIONS } from '~/store/affility/pages/my-team/names';

export default {
    name: 'Public',
    components: {
        ResponsiveLayout,
    },
    data () {
        return {
            showLoading: true,
            isCountDownActive: false,
            isSignInVisible: false,
            ERROR_STATUSES,
            maintenanceLoop: null,
            ResponsiveLayout,
        };
    },
    computed: {
        ...mapGetters('application/maintenances', ['nearestMaintenance']),
        ...mapGetters('application/driver',
            ['applicationStatus', 'isInitWalletInProgress', 'isLoginInProgress', 'authUser', 'headerVisible']),
        ...mapGetters('affility/layout/sidebar', {
            sidebarConfig: SIDEBAR_GETTERS.CONFIG,
        }),
        ...mapGetters('map/mapModule', ['metaverseId']),
    },
    watch: {
        $route: {
            immediate: true,
            handler (to) {
                this.refreshSidebarActiveItem(to);
                this.$nextTick(() => {
                    this.setupLocale();
                    reinitializeComponents();
                });
                if (to.path.startsWith('/partner-area')) {
                    if (!this.authUser?.hasAccessToPartnerArea()) {
                        this.$router.push('/dashboard');
                    }
                }
            },
        },
        sidebarConfig: {
            handler (config) {
                if (config) {
                    this.refreshSidebarActiveItem(this.$route);
                }
            },
        },
        authUser: {
            deep: true,
            async handler (userData, oldUserData) {
                const mwm = MetaWorldManager.sharedInstance();
                if(userData) {
                    if (userData.isEmailVerified || (userData.email && !userData.emailConfirmationOverdued)) {
                        this.$store.dispatch(
                            `affility/user/notifications/unread/${UNREAD_NOTIFICATION_ACTIONS.POLL_DATA}`,
                            { initial: true },
                        );
                    } else if (userData.email && userData.emailConfirmationOverdued) {
                        this.$store.dispatch(`affility/user/notifications/unread/${UNREAD_NOTIFICATION_ACTIONS.STOP_POLLING}`);
                    }

                    this.setPartnerAreaPageLinks();
                    MetaInventoryContainer.sharedInstance().setAuthUserData(userData);
                    mwm.setUserGoodsFetchingLoop();
                    if(!oldUserData) {
                        // this.handleMetaverseSwitch();
                    }
                    if(oldUserData?.id !== userData?.id) {
                        this.refetchReservedAreas();
                        this.$store.commit('map/mapModule/SWITCH_USER', userData);
                    }
                    const wallet = userData.walletAddress || userData.relatedWalletAddress;
                    this.$store.dispatch('cashFT/products/loadCashFtBundles', {
                        walletAddress: wallet,
                        withLoading: true,
                    });
                    if (!(userData.hasAccessToPartnerArea())) {
                        const sum = await mwm.fetchUserOwnedNFTsSum();
                        if (sum > 0) {
                            this.$store.commit('application/driver/UPDATE_AUTH_USER', {hasPurchasedNFT: true});
                        }
                    }
                } else if (!userData && oldUserData) {
                    this.refetchReservedAreas();
                    this.$store.commit('map/mapModule/SWITCH_USER', userData);

                    this.$store.dispatch(`affility/user/${USER_ACTIONS.CLEAR_USER_STATE}`);
                    this.$store.dispatch(`affility/pages/my-team/${MY_TEAM_ACTIONS.CLEAR_MY_TEAM_DATA}`);
                    this.$store.dispatch(`affility/promos/${PROMOS_ACTIONS.CLEAR_PROMOS}`);

                    MetaInventoryContainer.sharedInstance().setAuthUserData(null);
                    mwm.stopUserGoodsFetchingLoop();
                    this.$store.dispatch('application/mystery-box/resetMysteryBoxHistoryData');
                    this.$store.dispatch('cashFT/products/loadCashFtBundles', {
                        walletAddress: NULLABLE_WALLET_ADDRESS,
                        withLoading: true,
                    });
                }

            },
        },
        metaverseId: {
            async handler (metaverseId, oldMetaverseId) {
                if(metaverseId !== oldMetaverseId) {
                    MetaInventoryContainer.sharedInstance().destroyUserInventoryData();
                    const mwm = MetaWorldManager.sharedInstance();
                    mwm.setUserGoodsFetchingLoop();
                    await this.$store.dispatch('map/mapModule/reinitMap');
                }
            },
        },
    },
    async mounted () {
        if(!this.authUser) {
            this.handleRefId();
        }
        this.handleMetaverseId();
        this.setMaintenanceLoop();
        this.setPartnerAreaPageLinks();
        const mwm = MetaWorldManager.sharedInstance();
        this.setupLocale();
        await this.handleWeb3AutoConnect();
        if(this.authUser) {
            MetaInventoryContainer.sharedInstance().setAuthUserData(this.authUser);
            mwm.setUserGoodsFetchingLoop();
        }
        LayoutService.init();
        this.$nextTick(async () => {
            initializeComponents();
            this.setupLocale();
            this.$store.dispatch('map/mapModule/reinitMap');
            this.showLoading = false;
        });
    },
    beforeDestroy () {
        clearInterval(this.maintenanceLoop);
    },
    methods: {
        ...mapActions('application/maintenances', ['loadUpcomingMaintenances']),
        ...mapActions('map/mapModule', ['refetchReservedAreas']),
        ...mapActions('affility/layout/sidebar', {
            refreshSidebarActiveItem: SIDEBAR_ACTIONS.REFRESH_ACTIVE_ITEM,
        }),
        setMaintenanceLoop () {
            clearInterval(this.maintenanceLoop);
            this.loadUpcomingMaintenances();
            this.maintenanceLoop = setInterval(() => {
                this.loadUpcomingMaintenances();
            }, 1000 * 60 * 5); // Fetch every 5 minutes
        },
        handleMetaverseId () {
            const queryMvId = Number(this.$route?.query?.metaverseId);
            const isMvIdSupported = queryMvId && Object.values(METAVERSE_IDS).includes(queryMvId);
            const isMvIdDifferent = queryMvId && queryMvId !== this.metaverseId;
            if(isMvIdSupported && isMvIdDifferent) {
                this.$setMetaverseId(queryMvId);
            }
        },
        handleRefId() {
            if(this.$route?.query?.ref) {
                this.$store.dispatch('application/driver/setAuthStep', AUTH_FLOW_STEPS.SIGN_UP_WITH_EMAIL);
                this.$store.dispatch('application/popup-control/changeConnectWalletPopupStatus', true);
                return true;
            }
            return false;
        },
        handleMetaverseSwitch () {
            const dontAskMetaverse = localStorage.getItem(METAVERSE_SWITCH_ASK_KEY);
            if(dontAskMetaverse === 'false' || !dontAskMetaverse) {
                const queryMvId = Number(this.$route?.query?.metaverseId);
                if(!this.$route.path.startsWith('/oauth') &&
                    !this.$route.path.startsWith('/auth') &&
                    !this.$route.path.includes('/metaverse-switch') &&
                    (!queryMvId || Number(queryMvId) !== this.metaverseId)) {
                    localStorage.setItem('redirect', this.$route.fullPath);
                    this.$router.push({path: '/metaverse-switch', query: {ref: this.$route.query.ref}});
                }
            }
        },
        async handleWeb3AutoConnect () {
            try {
                const provider = this.$ethereum();

                if (provider) {
                    if(provider.on) {
                        provider.on("accountsChanged", async (accounts) => {
                            diagLog({}, 'ACCOUNT CHANGED TO =>', {accounts});
                            if(this.authUser && accounts.length) {
                                const address = accounts[0];
                                await this.$store.dispatch('application/driver/setCurrentActiveAddress', address);
                            }
                        });
                    }
                }
            } catch (e) {
                if(e instanceof SignatureDeclinedError) {
                    PopupHelper.showErrorAlert(this.$t('Signature declined'), this.$t('You declined signature request. Please try again.'));
                }
                console.error('public handleWeb3AutoConnect', e);
            }
        },
        setupLocale () {
            let loc = this.$i18n.defaultLocale;
            if (process.browser) {
                const selectedLocale = localStorage.getItem('locale');
                if (selectedLocale) {
                    loc = selectedLocale;
                }
            }
            this.$i18n.locale = loc;
            this.currentLocale = loc;
        },
        setPartnerAreaPageLinks () {
            if ((!this.authUser?.hasAccessToPartnerArea()) && PartnerAreaPages.includes(this.$route.name)) {
                if (this.authUser) {
                    this.$router.push('/dashboard');
                } else {
                    this.$router.push('/nfts/products');
                }
            }
        },
        reload () {
            window.location.reload();
        },
    },
};
