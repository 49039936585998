import ApiService from "~/core/services/api-interaction/ApiService";
import { CashFtCardBundle } from "~/pages/nfts/products/models/CashFtCardBundle";

export const state = () => {
    return {
        userKYCStatusFromContract: null,
        cashFtBundles: [],
        cashFtBundlesLoading: false,
        cashFtBundleLoadingError: null,
        activeCashFtBundles: [],
        availableTopupCurrencies: [],
        availableTopupCurrenciesLoading: false,
        availableTopupCurrenciesLoadingError: null,
    };
};
export const actions = {
    async loadCashFtBundles ({ commit }, {walletAddress, withLoading}) {
        commit('SET_CASHFT_BUNDLES_LOADING', true);
        try {
            const cashFtContract = this.$contracts[this.$metaverseId()].CashFT;
            const cashFtBundlesFromContract = await cashFtContract.methods.getInfo(walletAddress).call();

            commit('SET_USER_KYC_STATUS_FROM_CONTRACT', cashFtBundlesFromContract.userKyc);

            const cashFtBundles = Object.keys(cashFtBundlesFromContract)
                .filter((key) => isNaN(Number(key)) && typeof cashFtBundlesFromContract[key] === 'object')
                .map((key) => {
                    const bundleData = cashFtBundlesFromContract[key];
                    return {
                        bundle_sc_id: Number(bundleData[0]),
                        number_of_parcels: Number(bundleData[1]),
                        price: Number(this.$readWeb3().utils.fromWei(bundleData[2])),
                        total_purchased: Number(bundleData[3]),
                        is_active: Boolean(Number(bundleData[4])),
                        minimum_gymnet_deposit: Number(this.$readWeb3().utils.fromWei(bundleData[5])),
                    };
                })
                .map((bundle) => new CashFtCardBundle(
                    bundle.bundle_sc_id,
                    bundle.number_of_parcels,
                    bundle.is_active,
                    bundle.total_purchased,
                    bundle.price,
                    bundle.minimum_gymnet_deposit,
                ));
            commit('SET_CASHFT_BUNDLES', cashFtBundles);
        } catch (e) {
            console.error(e);
            commit('SET_CASHFT_BUNDLE_LOADING_ERROR', e);
        } finally {
            commit('SET_CASHFT_BUNDLES_LOADING', false);
        }
    },
    async loadAvailableTopupCurrencies ({ commit, state }) {
        commit('SET_AVAILABLE_TOPUP_CURRENCIES_LOADING_ERROR', null);
        commit('SET_AVAILABLE_TOPUP_CURRENCIES_LOADING', true);
        try {
            if (state.availableTopupCurrencies?.length) {
                return state.availableTopupCurrencies;
            }
            const response = await ApiService.query('/cards/currencies');
            const { currencies } = response.data;
            commit('SET_AVAILABLE_TOPUP_CURRENCIES', currencies);
            return currencies;
        } catch (e) {
            commit('SET_AVAILABLE_TOPUP_CURRENCIES_LOADING_ERROR', e);
        } finally {
            commit('SET_AVAILABLE_TOPUP_CURRENCIES_LOADING', false);
        }
    },
    async getTopupInfo ({ commit }, { tokenSymbol, cardId, amount }) {
        if (!Number.isInteger(cardId)) {
            throw new Error('Card ID must be int');
        }
        if (!Number.isInteger(amount)) {
            throw new Error('Amount must be int');
        }
        const response = await ApiService.post('/cards/deposit', {
            token_symbol: tokenSymbol,
            card_id: cardId,
            amount,
        });
        return response.data;
    },
    async getTransactionHistory({ commit }, { page, pageSize, cardIds }) {
        const response = await ApiService.query('cards/transactions/history', {
            params: {
                page,
                per_page: pageSize,
                card_ids: cardIds,
            },
        });
        return response.data;
    },
    async loadCardsBalances (){
        const result = await ApiService.get('cards/balance');
        return result?.data?.balances;
    },
    async loadExchangeRate (_,{tokenCode}){
        const result = await ApiService.query('cards/exchange-rate',{
            params: {
                token_code: tokenCode,
            },
        });
        return result?.data;
    }
};

export const mutations = {
    SET_USER_KYC_STATUS_FROM_CONTRACT (state, value) {
        state.userKYCStatusFromContract = value;
    },
    SET_CASHFT_BUNDLES (state, value) {
        state.cashFtBundles = value;
    },
    SET_CASHFT_BUNDLES_LOADING (state, value) {
        state.cashFtBundlesLoading = value;
    },
    SET_CASHFT_BUNDLE_LOADING_ERROR (state, value) {
        state.cashFtBundleLoadingError = value;
    },
    SET_AVAILABLE_TOPUP_CURRENCIES (state, value) {
        state.availableTopupCurrencies = value;
    },
    SET_AVAILABLE_TOPUP_CURRENCIES_LOADING (state, value) {
        state.availableTopupCurrenciesLoading = value;
    },
    SET_AVAILABLE_TOPUP_CURRENCIES_LOADING_ERROR (state, value) {
        state.availableTopupCurrenciesLoadingError = value;
    },
};

export const getters = {
    cashFtBundles (state) {
        return state.cashFtBundles;
    },
    cashFtBundlesLoading (state) {
        return state.cashFtBundlesLoading;
    },
    cashFtBundleLoadingError (state) {
        return state.cashFtBundleLoadingError;
    },
    userKYCStatusFromContract (state) {
        return state.userKYCStatusFromContract;
    },
    topupCurrencies (state) {
        return state.availableTopupCurrencies;
    },
    topupCurrenciesLoading (state) {
        return state.availableTopupCurrenciesLoading;
    },
    topupCurrenciesLoadingError (state) {
        return state.availableTopupCurrenciesLoadingError;
    },
};
