import { BoundsEdgeIndexes } from "../constants/EnumBoundsEdgeIndexes";
import { RowImageData } from "../types/RowImageData";
import { RowBounds } from "../types/RowBounds";
import { ParcelBufferState } from "../constants/EnumParcelBufferState";
import { getParcelLandType } from "../helpers/getParcelLandType";
import { getParcelState } from "../helpers/getParcelState";
import { MetaParcel } from "./MetaParcel";
import { MetaReservedArea } from "./MetaReservedArea";

export class MatrixRow {
    public readonly rowStart: number;
    public maxX = -1;
    public minX = Infinity;
    public bounds: RowBounds[] = [];
    public reservedAreas: Set<MetaReservedArea> = new Set();
    public parcels = new Map<number, MetaParcel>();
    public images = new Set<RowImageData>();

    constructor (
        public rowWidth: number,
        public rowNumber: number,
        public matrixBuffer: Uint8Array,
    ) {
        Object.defineProperty(this, 'matrixBuffer', { enumerable: false });
        this.rowStart = rowNumber * rowWidth;
    }

    addBounds (bounds: RowBounds) {
        this.bounds.push(bounds);
        const boundsStart = bounds[BoundsEdgeIndexes.START];
        const boundsEnd = bounds[BoundsEdgeIndexes.END];
        if (this.minX > boundsStart) {
            this.minX = boundsStart;
        }
        if (this.maxX < boundsEnd) {
            this.maxX = boundsEnd;
        }
        const rowStart = this.rowStart;
        this.matrixBuffer.fill(ParcelBufferState.PARCEL, (rowStart + boundsStart) * 4, (rowStart + boundsEnd + 1) * 4);
    }

    findBoundsForRange (x: number, endX: number): RowBounds[] {
        const foundBounds = this.bounds.filter(b =>
            b[BoundsEdgeIndexes.START] <= x && b[BoundsEdgeIndexes.END] >= endX ||
            b[BoundsEdgeIndexes.START] <= x && b[BoundsEdgeIndexes.END] <= endX ||
            b[BoundsEdgeIndexes.START] >= x && b[BoundsEdgeIndexes.END] >= endX ||
            b[BoundsEdgeIndexes.START] >= x && b[BoundsEdgeIndexes.END] <= endX,
        );
        if (!foundBounds.length) {
            throw new Error(`No bounds found for range ${x} - ${endX}`);
        }
        return foundBounds;
    }

    findReservedAreasInAnotherArea (reservedArea: MetaReservedArea): MetaReservedArea[] {
        const reservedAreasWithin = [];
        const startX = reservedArea.x;
        const endX = reservedArea.endX;
        for (const area of this.reservedAreas) {
            if (area.x >= startX && area.endX <= endX && area !== reservedArea) {
                reservedAreasWithin.push(area);
            }
        }
        return reservedAreasWithin;
    }

    modifyMatrixByReservedArea (
        reservedArea: MetaReservedArea,
        matrixModifier: (parcelX: number) => void,
    ) {
        const foundBounds = this.findBoundsForRange(reservedArea.x, reservedArea.endX);
        const reservedAreasWithin = this.findReservedAreasInAnotherArea(reservedArea);
        for (const b of foundBounds) {
            const reservedAreaStart = Math.max(b[BoundsEdgeIndexes.START], reservedArea.x);
            const reservedAreaEnd = Math.min(b[BoundsEdgeIndexes.END], reservedArea.endX);
            for (let i = reservedAreaStart; i <= reservedAreaEnd; i++) {
                const isParcelWithinNestedReservedArea = reservedAreasWithin.some(area => area.x <= i && i <= area.endX);
                if (!isParcelWithinNestedReservedArea) {
                    matrixModifier(i);
                }
            }
        }
    }

    addReservedArea (reservedArea: MetaReservedArea, updateMatrix = true) {
        this.reservedAreas.add(reservedArea);
        if (updateMatrix) {
            this.modifyMatrixByReservedArea(
                reservedArea,
                (i) => {
                    const additionalData = reservedArea.imageLocationDataIndex ?? reservedArea.id;
                    this.setParcelState(
                        i,
                        ParcelBufferState.RESERVED,
                        additionalData >> 8,
                        additionalData,
                        typeof reservedArea.imageLocationDataIndex === 'number' ? 1 : 0,
                    );
                },
            );
        }
    }

    removeReservedArea (reservedArea: MetaReservedArea, updateMatrix = true) {
        this.reservedAreas.delete(reservedArea);
        if (updateMatrix) {
            this.modifyMatrixByReservedArea(reservedArea, (i) => this.setParcelState(i, ParcelBufferState.PARCEL));
        }
    }

    clearReservedAreas () {
        for (const area of this.reservedAreas) {
            this.removeReservedArea(area);
        }
    }

    addOwnedParcel (parcel: MetaParcel) {
        const x = parcel.x;
        const image = parcel.image;
        const owner = parcel.owner;
        let parcelState = ParcelBufferState.OWNED;
        if (image) {
            parcelState = ParcelBufferState.IMAGE;
        } else if (owner?.isCurrentUser) {
            parcelState = ParcelBufferState.SELF_OWNED;
        }
        const ownerId = owner.id;
        this.setParcelState(x, parcelState, ...this.getOwnerByParts(ownerId));
        if (image) {
            this.images.add(image);
        }
        return this.parcels.set(x, parcel);
    }

    removeOwnedParcel (x: number) {
        this.setParcelState(x, ParcelBufferState.PARCEL);
        const parcel = this.parcels.get(x);
        if (parcel?.image) {
            this.images.delete(parcel.image);
        }
        this.parcels.delete(x);
    }

    clearOwnedParcels () {
        for(const [x] of this.parcels) {
            this.removeOwnedParcel(x);
        }
    }

    setParcelState (x: number, state: ParcelBufferState, additionalData1?: number, additionalData2?: number, additionalData3?: number) {
        const rowStart = this.rowStart;
        this.matrixBuffer[(rowStart + x) * 4] = state;
        this.matrixBuffer[(rowStart + x) * 4 + 1] = additionalData1;
        this.matrixBuffer[(rowStart + x) * 4 + 2] = additionalData2;
        this.matrixBuffer[(rowStart + x) * 4 + 3] = additionalData3;
    }

    getParcelState (x: number): ParcelBufferState {
        return getParcelState(this.matrixBuffer, this.rowWidth, x, this.rowNumber);
    }

    getParcelLandType (x: number): number {
        return getParcelLandType(this.matrixBuffer, this.rowWidth, x, this.rowNumber);
    }

    imagesFromTo (from: number, to: number, imagesSet: Set<RowImageData> = null): Set<RowImageData> {
        const images = imagesSet || new Set<RowImageData>();
        for (const image of this.images) {
            if (image.x >= from || image.x <= to) {
                images.add(image);
            }
        }
        return images;
    }

    removeImageById(imageId: number) {
        return this.images.delete([...this.images].find(image => image.image_id === imageId));
    }

    addNewParcelImage(imageData: RowImageData) {
        this.images.add(imageData);
        const startX = imageData.x;
        const endX = startX + imageData.width - 1;
        for (let i = startX; i <= endX; i++) {
            const parcel = this.parcels.get(i);
            if (parcel) {
                const ownerId = parcel.owner.id;
                const state = ParcelBufferState.IMAGE;
                parcel.image = imageData;
                this.setParcelState(i, state, ...this.getOwnerByParts(ownerId));
            }
        }
    }

    removeParcelImage(imageData: RowImageData) {
        const deleted = this.images.delete(imageData);
        if (!deleted) {
            this.removeImageById(imageData.image_id);
        }
        const startX = imageData.x;
        const endX = startX + imageData.width - 1;
        for (let i = startX; i <= endX; i++) {
            const parcel = this.parcels.get(i);
            if (parcel) {
                const ownerId = parcel.owner.id;
                const state = parcel.owner.isCurrentUser ? ParcelBufferState.SELF_OWNED : ParcelBufferState.OWNED;
                parcel.image = null;
                this.setParcelState(i, state, ...this.getOwnerByParts(ownerId));
            }
        }
    }

    replaceParcelImage(imageToPlace: RowImageData) {
        const parcel = this.parcels.get(imageToPlace.x);
        this.images.delete(parcel.image);
        const startX = imageToPlace.x;
        const endX = startX + imageToPlace.width - 1;
        for (let i = startX; i <= endX; i++) {
            const parcel = this.parcels.get(i);
            if (parcel) {
                const ownerId = parcel.owner.id;
                const state = ParcelBufferState.IMAGE;
                parcel.image = imageToPlace;
                this.setParcelState(i, state, ...this.getOwnerByParts(ownerId));
            }
        }
    }

    getOwnerByParts(ownerId: number): number[] {
        return [ownerId >> 16, ownerId >> 8, ownerId];
    }

    at (x: number): MetaParcel | null {
        const parcel = this.parcels.get(x);
        if (parcel) {
            return parcel;
        }
        const inBounds = this.bounds.find(b => b[BoundsEdgeIndexes.START] <= x && b[BoundsEdgeIndexes.END] >= x);
        if (!inBounds) {
            return null;
        }
        let foundArea = null;
        for (const area of this.reservedAreas) {
            if (area.x <= x && x <= area.endX) {
                if (!foundArea || foundArea.width >= area.width) {
                    foundArea = area;
                }
            }
        }
        return new MetaParcel(x, this.rowNumber, foundArea);
    }
}