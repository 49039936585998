import { ParcelBufferState } from "../constants/EnumParcelBufferState";

export function getParcelState (
    matrixBuffer: Uint8Array,
    matrixWidth: number,
    x: number,
    y: number,
): ParcelBufferState {
    const index = y * matrixWidth + x;
    return matrixBuffer[index * 4];
}