import { ACTIONS, MUTATIONS } from "./names";
import { DEFAULT_ADDRESS } from "~/constants/constants";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { MetaUser } from "~/core/models/MetaUser";
import { PaymentType } from "~/core/models/purchase-popup-2/PaymentType";
import { METAVERSES } from "~/core/services/utils/MetaverseConstants";
import { fromWei } from "~/core/helpers/GlobalHelpers";
import bundlesInfoData from "~/store/affility/pages/shop/bundleConfig";
import { ParcelStats } from "~/store/affility/pages/shop/types";


export default {
    async [ACTIONS.GET_BUNDLES_DATA]({ commit, rootGetters, dispatch }, user: MetaUser) {
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
        const handleGetBundles = municipalityContract.methods.getBundles;
        const metaverseId = rootGetters['map/mapModule/metaverseId'];
        const bundlesNullableWallet = '0xB865188207b32c2fCa73C29BA546d5d00c432fe2';
        let bundles = [];

        try {
            const bundlesData = await handleGetBundles(bundlesNullableWallet).call();

            const bundleInfo = bundlesInfoData(metaverseId);

            const splitPrices = await dispatch(ACTIONS.GET_BUNDLES_SPLIT_PRICES, {
                bundles: bundleInfo,
                contract: municipalityContract,
                walletAddress: user ? user.walletAddress : DEFAULT_ADDRESS
            })

            if (bundlesData) {
                for (let i = 0; i < bundlesData.length; i ++) {
                    const bnd = bundlesData[i];
                    const bundleData = {
                        name: bundleInfo[bnd[0]].name,
                        sc_id: parseInt(bnd[0]),
                        number_of_parcels: parseInt(bnd[1]),
                        discounted_price: fromWei(bnd[4]),
                        price: fromWei(bnd[5]),
                        commission_volume: bnd[6] ? fromWei(bnd[6]) : 0,
                        benefitsInfo: bundleInfo[bnd[0]].benefitsInfo,
                        splitPrices: splitPrices.get(bundleInfo[bnd[0]].typeID),
                        terahashValue: bundleInfo[bnd[0]]?.terahashValue,
                    };

                    if ('preservedIndex' in bundleInfo[bnd[0]]) {
                        const position = bundleInfo[bnd[0]].preservedIndex;
                        if (bundles.length < position) {
                            bundles[position] = bundleData;
                        } else {
                            bundles.splice(position, 0, bundleData);
                        }
                    } else {
                        bundles.push(bundleData);
                    }
                }
                bundles = bundles.filter((val) => val);
            }

            commit(MUTATIONS.SET_BUNDLES_DATA, bundles);
        } catch (e) {
            console.error(e);
        } finally {
            commit(MUTATIONS.SET_BUNDLES_DATA_LOADING, false);
        }
    },
    [ACTIONS.GET_BUNDLES_SPLIT_PRICES]: async function ({ }, { bundles, contract, walletAddress }) {
        try {
            const splitPricesBundlePromises = Object.values(bundles)
                .filter(({ isSuper }) => isSuper)
                .map(async ({ typeID }: any) => {
                    const price = await contract.methods
                        .getPriceForSuperBundle(typeID, walletAddress, PaymentType.GYMNET)
                        .call();
                    return { typeID, price };
                });

            const splitPricesWei = await Promise.all(splitPricesBundlePromises);

            const splitPrices = new Map<number, { usdt: number; gymnet: number; commission_volume: number }>();
            splitPricesWei.forEach(({ typeID, price }) => {
                splitPrices.set(typeID, {
                    usdt: Number(fromWei(price[0])),
                    gymnet: Number(fromWei(price[2])),
                    commission_volume: Number(fromWei(price[3])),
                });
            });

            return splitPrices;
        } catch (error) {
            console.error('Error fetching bundle prices:', error);
            throw error;
        }
    },
    [ACTIONS.ON_BUNDLES_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_BUNDLES_DATA_LOADING, value);
    },
    async [ACTIONS.GET_PARCELS_SLOTS_DATA]({ commit, rootGetters }) {
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
        const handleGetSoledParcels = municipalityContract.methods.currentlySoldStandardParcelsCount().call;
        const metaverseId = rootGetters['map/mapModule/metaverseId'];

        try {
            let parcelStats: ParcelStats;
            const soldParcelsCount = await handleGetSoledParcels();
            const priceInfo = await MetaWorldManager.sharedInstance().fetchParcelPriceInfo(1);
            const soldCount = Number(soldParcelsCount);
            const metaverse = METAVERSES[metaverseId];
            const soldPercentage = 100 * soldCount / metaverse.standard_parcels_count;
            parcelStats = {
                soldParcelsCount: soldCount,
                parcelsCurrentPrice: priceInfo.priceForBuyParcel,
                percentages: [10, 25, 50, 75, 100],
                prices: [100, 116, 144, 209, 301],
                standardParcelsTotalCount: metaverse.standard_parcels_count,
                soldPercentage,
                leftParcelsCount: metaverse.standard_parcels_count - soldParcelsCount,
                nextPriceChanges: 0,
                nextPercentage: 0,
                soldPercentageOptional: {
                    full: soldPercentage.toFixed(3),
                    integer: soldPercentage.toFixed(3).split('.')[0],
                    decimal: soldPercentage.toFixed(3).split('.')[1],
                },
            };
            const nextPercentage = findClosest(parcelStats.percentages, parcelStats.soldPercentage);
            parcelStats.nextPriceChanges = ((parcelStats.standardParcelsTotalCount / 100) * nextPercentage) - soldParcelsCount;
            parcelStats.nextPercentage = nextPercentage;

            commit(MUTATIONS.SET_PARCELS_SLOTS_DATA, parcelStats);
        } catch (e) {
            console.error(e);
        } finally {
            commit(MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING, false);
        }
    },
    [ACTIONS.ON_PARCELS_SLOTS_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING, value);
    },
    [ACTIONS.ON_CLEAR_SHOP_DATA]({ commit }) {
        commit(MUTATIONS.SET_BUNDLES_DATA, []);
        commit(MUTATIONS.SET_BUNDLES_DATA_LOADING, true);
        commit(MUTATIONS.SET_PARCELS_SLOTS_DATA, null);
        commit(MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING, true);
    },
}

const findClosest = (arr, num) => {
    if (arr == null) return;
    let goal = 0;

    for (const [index, el] of arr.entries()) {
        if (num <= el) {
            goal = arr[index];
            break;
        }
    }

    return goal;
};
