import { RawAssignedImageData } from "../types/RawAssignedImageData";
import { RawOwnedParcelData } from "../types/RawOwnedParcelData";
import ApiService from "../../api-interaction/ApiService";
import { MAP_ENDPOINTS } from "../../utils/Constants";
import { RawOwnedAreasData } from "../types/RawOwnedAreasData";
import { MapBuilder } from "../MapBuilder";
import { MetaParcel } from "../models/MetaParcel";
import { OwnedParcelsProcessingResult } from "../types/OwnedParcelsProcessingResult";
import { MapCreationStep } from "./MapCreationStep";
import { ParcelOwner } from "../models/ParcelOwner";

export class MapOwnedParcelsStep extends MapCreationStep {
    constructor (
        metaverseId: number,
        private map: MapBuilder,
        nextStep?: MapCreationStep,
        public userIdGetter?: () => number | null,
    ) {
        super(metaverseId, nextStep);
    }

    protected async loadFromIndexedDB (): Promise<RawOwnedAreasData> {
        const idb = await MapCreationStep.idb;
        const promises = [
            idb.get('owned-parcels', this.metaverseId),
            idb.get('image-parcels', this.metaverseId),
        ];
        const results = await Promise.all(promises);
        return {
            ownedParcelsData: (results[0]?.data as RawOwnedParcelData[]) || null,
            assignedImagesData: (results[1]?.data as RawAssignedImageData[]) || null,
        };
    }

    protected async loadFromAPI (): Promise<RawOwnedAreasData> {
        const promises = [
            ApiService.get(MAP_ENDPOINTS.owned_parcels_data),
            ApiService.get(MAP_ENDPOINTS.image_parcels_data),
        ];
        const [ownedParcelsData, imageParcelsData] = await Promise.all(promises);
        return {
            ownedParcelsData: ownedParcelsData.data as RawOwnedParcelData[],
            assignedImagesData: imageParcelsData.data as RawAssignedImageData[],
        };
    }

    protected process (data: RawOwnedAreasData): OwnedParcelsProcessingResult {
        const {
            ownedParcelsData: ownedParcels,
            assignedImagesData: assignedImages,
        } = data;
        const assignedImagesByRows = {};
        if (assignedImages) {
            for (const parcel of assignedImages) {
                const maxRow = parcel.y + parcel.height;
                for (let i = parcel.y; i < maxRow; i++) {
                    const row = assignedImagesByRows[i] || [];
                    row.push(parcel);
                    assignedImagesByRows[i] = row;
                }
            }
        }
        //! Uncomment commented lines if it will be necessary to find all owned parcels of 1 user
        const userId = this.userIdGetter?.();
        const ownersMap = new Map();
        const ownedParcelsCount = ownedParcels.length;
        const ownedMetaParcels = new Array(ownedParcelsCount);
        for (let i = 0; i < ownedParcelsCount; i++) {
            const parcel = ownedParcels[i];
            const id = parcel?.id;
            if (typeof id === 'number') {
                let owner = ownersMap.get(parcel.id);
                if (!owner) {
                    owner = ParcelOwner.create(
                        id,
                        parcel?.wallet_address,
                        id === userId && typeof userId === 'number',
                    );
                    ownersMap.set(id, owner);
                }
                const parcelX = parcel.x;
                const parcelY = parcel.y;

                const rowImages = assignedImagesByRows[parcelY];
                let image = null;

                if (rowImages) {
                    const parcelImage = rowImages.find(
                        (rowImage: RawAssignedImageData) => parcelX >= rowImage.x && parcelX < rowImage.x + rowImage.width,
                    );
                    image = parcelImage || null;
                }
                ownedMetaParcels[i] = new MetaParcel(parcelX, parcelY, null, owner, image);
                owner.addToOwnedParcelsUnsafe(ownedMetaParcels[i]);
            }
        }

        return {
            ownedMetaParcels,
            ownersMap,
        };
    }

    protected setupOnMap (data: OwnedParcelsProcessingResult): void {
        const { ownedMetaParcels, ownersMap } = data;
        this.map.setOwnersMap(ownersMap);
        this.map.setupOwnedParcels(ownedMetaParcels);
        this.map.redraw();
    }

    protected async save ({ ownedParcelsData, assignedImagesData }: RawOwnedAreasData): Promise<IDBValidKey[]> {
        const idb = await MapCreationStep.idb;
        const promises = [
            idb.put('owned-parcels', {
                metaverseId: this.metaverseId,
                data: ownedParcelsData,
            }),
            idb.put('image-parcels', {
                metaverseId: this.metaverseId,
                data: assignedImagesData,
            }),
        ];
        return Promise.all(promises);
    }

    public async restoreFromIndexedDB (): Promise<boolean> {
        const ownedParcelsData = await this.loadFromIndexedDB();
        if (ownedParcelsData.assignedImagesData && ownedParcelsData.ownedParcelsData) {
            const processedOwnedParcels = this.process(ownedParcelsData);
            this.setupOnMap(processedOwnedParcels);
        }
        return !!ownedParcelsData;
    }

    public async createFromAPI (): Promise<void> {
        const rawOwnedParcelsData = await this.loadFromAPI();
        const processedOwnedParcels = this.process(rawOwnedParcelsData);
        this.setupOnMap(processedOwnedParcels);
        this.save(rawOwnedParcelsData);
    }

    public async execute (): Promise<void> {
        await this.restoreFromIndexedDB();
        this.createFromAPI();
        if (this.nextStep) {
            return this.nextStep.execute();
        }
    }

}
