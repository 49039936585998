import APIServiceError from "~/core/helpers/APIServiceError";
import { CommissionTypes } from "../types/CommissionTypes";
import { CashFtCommissionTypes } from "../types/CashFtCommissionTypes";
import { fromWei, getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import ApiService from "~/core/services/api-interaction/ApiService";
import { CommissionsServiceException } from "../exceptions/CommissionsServiceException";
import { RawCommissionBalanceInfo } from "../types/RawCommissionBalanceInfo";
import { CommissionBalanceInfo } from "../models/CommissionBalanceInfo";
import { ConditionalType } from "~/core/types/global/ConditionalType";
import { RawCommissionHistoryChangeData } from "../types/RawCommissionHistoryChangeData";
import { PeriodFilterValue } from "~/pages/finances/commissions/constants/PeriodFilterValue";
import { binaryRanks } from "~/constants/constants";
import { UtilInfMethodResult } from "../types/UtilInfMethodResult";
import { UserInfMethodResult } from "../types/UserInfMethodResult";
import { CommissionsUpdateIntervalResult } from "../types/CommissionsUpdateIntervalResult";
import { generateEmptyChartData } from "../helpers/generateEmptyChartData";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { BTCCommissionTypes } from "~/pages/finances/commissions/types/BTCCommissionTypes";

const $config = getClientRuntimeConfig();

const BINARY_API = `${$config.binaryTreeApi}/api`;
const DEFAULT_PAGE_SIZE = 10;
const USER_NOT_FOUND_CODE = 400;

export class CommissionsService {

    static async getCommissionHistory (
        commissionType: Exclude<CommissionTypes, CommissionTypes.CLAIMED_COMMISSIONS> | null = null,
        page = 1,
        itemsPerPage = DEFAULT_PAGE_SIZE,
    ) {
        let type: string = commissionType;
        switch (commissionType) {
            case CommissionTypes.ALL:
                type = undefined;
                break;
            case CommissionTypes.MISSED_COMMISIONS:
                type = 'Missed';
                break
            case CommissionTypes.INCENTIVES:
                type = 'Incomes from Subscriptions';
                break;
        }

        try {
            const response = await ApiService.query(`${ BINARY_API }/statistics/commissions`, {
                params: {
                    page,
                    perPage: itemsPerPage,
                    type,
                },
            });
            return response.data;
        } catch(err: unknown) {
            if (err instanceof APIServiceError) {
                if (err.httpErrorCode === USER_NOT_FOUND_CODE) {
                    return {
                        meta: {
                            page,
                            perPage: itemsPerPage,
                            total: 0,
                        },
                        data: [],
                    }
                }
                throw new CommissionsServiceException(`Cannot get commissions history for ${commissionType} commissions`, err);
            }
            throw err;
        }
    }

    static async getCashFtCommissionHistory(
        type: CashFtCommissionTypes = CashFtCommissionTypes.DIRECT_SALES,
        page = 1,
        itemsPerPage = DEFAULT_PAGE_SIZE,
    ) {
        try {
            const response = await ApiService.query(`${ BINARY_API }/statistics/cashft-commissions`, {
                params: {
                    type: type === CashFtCommissionTypes.MISSED_COMMISIONS ? 'Missed' : type,
                    page,
                    perPage: itemsPerPage,
                },
            });
            return response.data;
        } catch(err: unknown) {
            if (err instanceof APIServiceError) {
                if (err.httpErrorCode === USER_NOT_FOUND_CODE) {
                    return {
                        meta: {
                            page,
                            perPage: itemsPerPage,
                            total: 0,
                        },
                        data: [],
                    }
                }
                throw new CommissionsServiceException(`Cannot get cash ft commissions history`, err);
            }
            throw err;
        }
    }

    static async getBTCCommissionHistory(
        type: BTCCommissionTypes = BTCCommissionTypes.PROFIT_MATCHING,
        page = 1,
        perPage = DEFAULT_PAGE_SIZE,
    ) {
        try {
            const response = await ApiService.query(`${ BINARY_API }/btc/commissions`, {
                params: { type, page, perPage }
            })
            return response.data;
        } catch {
            return {
                meta: {
                    page,
                    perPage,
                    total: 0,
                },
                data: [],
            }
        }
    }

    static async fetchClaimedCommissions(page = 1, perPage = DEFAULT_PAGE_SIZE) {
        try {
            const response = await ApiService.query(`${ BINARY_API }/balance/claimed-commissions`, {
                params: { page, perPage }
            })
            return response.data;
        } catch {
            return {
                meta: {
                    page,
                    perPage,
                    total: 0,
                },
                data: [],
            }
        }
    }

    static async netGymstreetContractUserInfoMethod(blockId = null) {
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const walletAddress = authUser.walletAddress ?? authUser.relatedWalletAddess;
        return MetaWorldManager.sharedInstance().contracts
            .NetGymStreet
            .methods
            .userInf(walletAddress)
            .call({}, blockId);
    }

    static async getCommissionsInfo<T extends boolean>(transformData?: T): Promise<ConditionalType<T, CommissionBalanceInfo, RawCommissionBalanceInfo>> {
        try {
            let prevRes = await this.netGymstreetContractUserInfoMethod(30670374);
            let nextRes = await this.netGymstreetContractUserInfoMethod();
            const prevTotalClaimed = parseFloat(MetaWorldManager.sharedInstance().readWeb3().utils.fromWei(prevRes.totalClaimed));
            const nextTotalClaimed = parseFloat(MetaWorldManager.sharedInstance().readWeb3().utils.fromWei(nextRes.totalClaimed));
            const pendingRew = parseFloat(MetaWorldManager.sharedInstance().readWeb3().utils.fromWei(nextRes.pendingRew));
            const response = await ApiService.get(BINARY_API, 'balance');

            const data: RawCommissionBalanceInfo = response.data;


            if (data) {
                // for (const [key, value] of Object.entries(data)) {
                //     if (typeof value !== 'number') {
                //         value.USDT = value.USDT;
                //     }
                // }
                data.claimedCommissions.USDT = data.claimedCommissions.USDT + (nextTotalClaimed - prevTotalClaimed);
                data.availableCommissions.USDT = data.availableCommissions.USDT + pendingRew;
            }

            let result: RawCommissionBalanceInfo | CommissionBalanceInfo = data;
            if (transformData) {
                result = new CommissionBalanceInfo(data);
            }
            return result as ConditionalType<T, CommissionBalanceInfo, RawCommissionBalanceInfo>;
        } catch (err: unknown) {
            if (err instanceof APIServiceError) {
                if(err.httpErrorCode === USER_NOT_FOUND_CODE) {
                    const defaultBalance = {
                        USDT: 0,
                        GYMNET: 0,
                        UTILITY: 0,
                    };
                    let result = {
                        binaryWeeklyCap: binaryRanks[0].maxWeeklyPayout,
                        binaryCurrentProgress: 0,
                        totalBinaryCommission: 0,
                        availableCommissions: {...defaultBalance},
                        claimedCommissions: {...defaultBalance},
                        collectedBinaryBonus: {...defaultBalance},
                        collectedMatchingBonus: {...defaultBalance},
                    };
                    if(transformData) {
                        result = new CommissionBalanceInfo(result as RawCommissionBalanceInfo);
                    }
                    return result as ConditionalType<T, CommissionBalanceInfo, RawCommissionBalanceInfo>;
                }
                throw new CommissionsServiceException("Cannot get commissions balance info", err);
            }
            throw err;
        }
    }

    static async getCommissionHistoryStateChange(period: PeriodFilterValue, transformData: boolean = false): Promise<RawCommissionHistoryChangeData> {
        try {
            const response = await ApiService.query(`${ BINARY_API }/statistics/commissions/changing-history`, {
                params: {
                    period,
                },
            });
            if(transformData) {
                const responseData = {
                    binaryBonus: response.data.binaryBonus,
                    directSales: response.data.directSales,
                    matchingBonus: response.data.matchingBonus,
                    subscriptionBonus: response.data.subscriptionBonus,
                    cardDirectSales: response.data.cardDirectSales,
                };
                let msToSubtract = 60 * 60 * 1000;
                if (period === PeriodFilterValue.SEVEN_DAYS || period === PeriodFilterValue.ONE_MONTH) {
                    msToSubtract *= 24;
                } else if (period === PeriodFilterValue.ONE_YEAR || period === PeriodFilterValue.ALL) {
                    msToSubtract *= 24 * 30;
                }
                let months = 11;
                if(period === PeriodFilterValue.ALL) {
                    const minExistingDate = 1688083200000 // july 2023
                    const maxExistingDate = Date.now();
                    const minDateBinaryBonus = new Date(responseData.binaryBonus[0]?.date || minExistingDate);
                    const minDateDirectSales = new Date(responseData.directSales[0]?.date || minExistingDate);
                    const minDateMatchingBonus = new Date(responseData.matchingBonus[0]?.date || minExistingDate);
                    const minDateSubscriptionBonus = new Date(responseData.subscriptionBonus[0]?.date || minExistingDate);
                    const minDateCardDirectSales = new Date(responseData.cardDirectSales[0]?.date || minExistingDate);
                    const maxDateBinaryBonus = new Date(responseData.binaryBonus[responseData.binaryBonus.length - 1]?.date || maxExistingDate);
                    const maxDateDirectSales = new Date(responseData.directSales[responseData.directSales.length - 1]?.date || maxExistingDate);
                    const maxDateMatchingBonus = new Date(responseData.matchingBonus[responseData.matchingBonus.length - 1]?.date || maxExistingDate);
                    const maxDateSubscriptionBonus = new Date(responseData.subscriptionBonus[responseData.subscriptionBonus.length - 1]?.date || maxExistingDate);
                    const maxDateCardDirectSales = new Date(responseData.cardDirectSales[responseData.cardDirectSales.length - 1]?.date || maxExistingDate);
                    const minDate = Math.min(
                        minDateBinaryBonus.getTime(),
                        minDateDirectSales.getTime(),
                        minDateMatchingBonus.getTime(),
                        minDateSubscriptionBonus.getTime(),
                        minDateCardDirectSales.getTime(),
                    );
                    const maxDate = Math.max(
                        maxDateBinaryBonus.getTime(),
                        maxDateDirectSales.getTime(),
                        maxDateMatchingBonus.getTime(),
                        maxDateSubscriptionBonus.getTime(),
                        maxDateCardDirectSales.getTime(),
                    );
                    months = Math.floor((maxDate - minDate) / msToSubtract);
                }
                response.data.binaryBonus = generateEmptyChartData(period, months);
                response.data.directSales = response.data.binaryBonus.slice(0);
                response.data.matchingBonus = response.data.binaryBonus.slice(0);
                response.data.subscriptionBonus = response.data.binaryBonus.slice(0);
                response.data.cardDirectSales = response.data.binaryBonus.slice(0);
                const currentDate = new Date();
                for(const key in response.data) {
                    const data = response.data[key];
                    response.data[key] = {
                        USDT: data.slice(0),
                        GYMNET: data.slice(0),
                        Utility: data.slice(0),
                    };
                    const reduced = {
                        USDT: 0,
                        GYMNET: 0,
                        Utility: 0,
                    };
                    if (!responseData[key]) {
                        continue;
                    }
                    for(let i = 0; i < responseData[key].length; i++) {
                        const responseObjectDate = new Date(responseData[key][i].date);
                        const diff = currentDate.getTime() - responseObjectDate.getTime();
                        let index = response.data[key].USDT.length - Math.floor(diff / msToSubtract);
                        if (period !== PeriodFilterValue.ONE_DAY) {
                            index--;
                        }
                        reduced.USDT += Number(responseData[key][i].usdt);
                        reduced.GYMNET += Number(responseData[key][i].gymnet);
                        reduced.Utility += Number(responseData[key][i].utility);
                        response.data[key].USDT.splice(index, 1, {
                            x: responseData[key][i].date,
                            y: reduced.USDT,
                        });
                        response.data[key].GYMNET.splice(index, 1, {
                            x: responseData[key][i].date,
                            y: reduced.GYMNET,
                        });
                        response.data[key].Utility.splice(index, 1, {
                            x: responseData[key][i].date,
                            y: reduced.Utility,
                        });
                        let j = index + 1;
                        while (j < response.data[key].USDT.length) {
                            response.data[key].USDT[j] = Object.assign({}, response.data[key].USDT[j], {
                                y: response.data[key].USDT[j].y + Number(responseData[key][i].usdt),
                            });
                            response.data[key].GYMNET[j] = Object.assign({}, response.data[key].GYMNET[j], {
                                y: response.data[key].GYMNET[j].y + Number(responseData[key][i].gymnet),
                            });
                            response.data[key].Utility[j] = Object.assign({}, response.data[key].Utility[j], {
                                y: response.data[key].Utility[j].y + Number(responseData[key][i].utility),
                            });
                            j++;
                        }
                    }

                }
            }
            return response.data;
        } catch (err: unknown) {
            console.error(err);
            if (err instanceof APIServiceError) {
                const defaultEmptyData = transformData ? {
                    USDT: [],
                    GYMNET: [],
                    Utility: [],
                } : [];
                if(err.httpErrorCode === USER_NOT_FOUND_CODE) {
                    return {
                        binaryBonus: defaultEmptyData,
                        directSales: defaultEmptyData,
                        matchingBonus: defaultEmptyData,
                        subscriptionBonus: defaultEmptyData,
                        cardDirectSales: defaultEmptyData,
                    } as RawCommissionHistoryChangeData;
                }
                throw new CommissionsServiceException("Cannot get commissions balance info", err);
            }
            throw err;
        }
    }

    static async withdrawCommissionBalance() {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ BINARY_API }/balance/withdraw`, {}).then(res => {
                resolve(res.data.uuid);
            }).catch(() => {
                reject();
            });
        })
    }

    static async withdrawCommissionBalanceStatus(uuid) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${ BINARY_API }/balance/withdraw`, uuid).then(res => {
                resolve(res.data.status);
            }).catch(() => {
                reject();
            });
        });
    }

    static async* commissionBalanceStream(
        interval: number,
        stillRunning: () => boolean,
        transformData: boolean = false
    ) {
        let balanceInfo: CommissionBalanceInfo | RawCommissionBalanceInfo | null = null;
        while (stillRunning()) {
            try {
                balanceInfo = await CommissionsService.getCommissionsInfo(transformData);
            } catch { /* ignore */ } finally {
                yield balanceInfo;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        return balanceInfo;
    }

    static async* commissionChangeHistoryStream(
        interval: number,
        stillRunning: () => boolean,
        period: () => PeriodFilterValue,
        transformData: boolean = false
    ) {
        let chartData: RawCommissionHistoryChangeData = null;
        while (stillRunning()) {
            try {
                chartData = await CommissionsService.getCommissionHistoryStateChange(
                    period(),
                    transformData,
                );
            } catch { /* ignore */ } finally {
                yield chartData;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        return chartData;
    }

    static async* commissionHistoryStream(
        interval: number,
        stillRunning: () => boolean,
        commissionType: () => CommissionTypes,
        page: () => number,
        itemsPerPage: () => number,
    ) {
        let historyData = null;
        while (stillRunning()) {
            const resolvedCommissionType: any = commissionType();
            const resolvedPage = page();
            const resolvedItemsPerPage = itemsPerPage();
            try {
                if (resolvedCommissionType !== CommissionTypes.CLAIMED_COMMISSIONS) {
                    historyData = await CommissionsService.getCommissionHistory(
                        resolvedCommissionType,
                        resolvedPage,
                        resolvedItemsPerPage,
                    );
                    if(resolvedCommissionType !== commissionType() ||
                        resolvedPage !== page() ||
                        resolvedItemsPerPage !== itemsPerPage()) {
                        continue;
                    }
                }
            } catch { /* ignore */ } finally {
                if(resolvedCommissionType !== commissionType() ||
                    resolvedPage !== page() ||
                    resolvedItemsPerPage !== itemsPerPage()) {
                    continue;
                }
                yield historyData;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        return historyData;
    }

    static async* contractMethodUpdateStream<T>(
        interval: number,
        stillRunning: () => boolean,
        contractMethod: () => (() => Promise<T>),
    ): AsyncGenerator<T, T, T> {
        let result = null;
        while(stillRunning()) {
            const method = contractMethod();
            try {
                result = method();
            } catch { /* ignore */ } finally {
                yield result;
            }
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        return result;
    }

    static async* utilityInfoStream(
        interval: number,
        stillRunning: () => boolean,
        utilInfoMethod: () => (() => Promise<UtilInfMethodResult>)
    ): AsyncGenerator<
        CommissionsUpdateIntervalResult,
        CommissionsUpdateIntervalResult,
        CommissionsUpdateIntervalResult
    > {
        const methodUpdate = this.contractMethodUpdateStream(
            interval,
            stillRunning,
            utilInfoMethod,
        )
        let result = null;
        for await(const callResult of methodUpdate) {
            if(callResult) {
                result = {
                    claimedCommissions: {
                        UTILITY: Number(fromWei(callResult.total)),
                    },
                    availableCommissions: {
                        UTILITY: Number(fromWei(callResult.pendingUtility)),
                    }
                }
            }
            yield result;
        }
        return result;
    }

    static async* gymnetInfoStream(
        interval: number,
        stillRunning: () => boolean,
        gymnetInfoMethod: () => (() => Promise<UserInfMethodResult>)
    ): AsyncGenerator<
        CommissionsUpdateIntervalResult,
        CommissionsUpdateIntervalResult,
        CommissionsUpdateIntervalResult
    > {
        const methodUpdate = this.contractMethodUpdateStream(
            interval,
            stillRunning,
            gymnetInfoMethod,
        )
        let result = null;
        for await(const callResult of methodUpdate) {
            if(callResult) {
                result = {
                    claimedCommissions: {
                        GYMNET: Number(fromWei(callResult.totalClaimedGym)),
                    },
                    availableCommissions: {
                        GYMNET: Number(fromWei(callResult.pendingGym)),
                    }
                }
            }
            yield result;
        }
        return result;
    }

}
