import { Coords } from "../types/Coords";
import { Square } from "../types/Square";
import { SquareCoords } from "../types/SquareCoords";

export class MapSquareCoordinates {
    public readonly x: number;
    public readonly y: number;
    public readonly x2: number;
    public readonly y2: number;
    public readonly width: number;
    public readonly widthHalf: number;
    public readonly height: number;
    public readonly heightHalf: number;
    public readonly minX: number;
    public readonly minY: number;
    public readonly maxX: number;
    public readonly maxY: number;
    public readonly area: number;

    constructor(props: Coords | Square | SquareCoords) {
        this.x = Number(props.x);
        this.y = Number(props.y);
        this.x2 = Number((props as SquareCoords).x2);
        this.y2 = Number((props as SquareCoords).y2);

        const squareProps = props as Square;

        if (isNaN(this.x2)) {
            if (squareProps.width) {
                this.x2 = MapSquareCoordinates.calcSecondCoord(this.x, squareProps.width);
            } else {
                this.x2 = this.x;
            }
        }

        if (isNaN(this.y2)) {
            if (squareProps.height) {
                this.y2 = MapSquareCoordinates.calcSecondCoord(this.y, squareProps.height);
            } else {
                this.y2 = this.y;
            }
        }

        this.width = MapSquareCoordinates.calcWidth(this.x, this.x2);
        this.widthHalf = this.width / 2;
        this.height = MapSquareCoordinates.calcWidth(this.y, this.y2);
        this.heightHalf = this.height / 2;
        this.minX = Math.min(this.x, this.x2);
        this.minY = Math.min(this.y, this.y2);
        this.maxX = Math.max(this.x, this.x2);
        this.maxY = Math.max(this.y, this.y2);
        this.area = this.width * this.height;
    }

    get asSquare(): Square {
        return {
            x: this.minX,
            y: this.minY,
            width: this.width,
            height: this.height,
        };
    }

    get asCoords(): SquareCoords {
        return {
            x: this.x,
            y: this.y,
            x2: this.x2,
            y2: this.y2,
        };
    }

    get asArray(): [number, number, number, number] {
        return [this.x, this.y, this.x2, this.y2];
    }

    get center(): { x: number, y: number } {
        return {
            x: this.minX + this.widthHalf,
            y: this.minY + this.heightHalf,
        };
    }

    get roundedCenter(): { x: number, y: number } {
        const center = this.center;
        return {
            x: Math.round(center.x),
            y: Math.round(center.y),
        };
    }

    boundedCoords(
        startX: number,
        startY: number,
        endX: number,
        endY: number,
    ): SquareCoords {
        return {
            x: Math.min(Math.max(this.x, startX), endX),
            y: Math.min(Math.max(this.y, startY), endY),
            x2: Math.max(Math.min(this.x2, endX), startX),
            y2: Math.max(Math.min(this.y2, endY), startY),
        };
    }

    static calcSecondCoord(firstCoord: number, size: number): number {
        return firstCoord + size - 1;
    }

    static calcWidth(firstCoord: number, secondCoord: number): number {
        return Math.abs(secondCoord - firstCoord) + 1;
    }
}