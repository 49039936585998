var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.tag,{tag:"component",staticClass:"copy-to-clipboard d-inline-flex",class:{
        'cursor-pointer': !_setup.alreadyCopied,
        'cursor-not-allowed': _setup.alreadyCopied,
        'hoverable': _vm.hoverable,
    },style:({
        '--hover-color': _vm.hoverColor,
    }),on:{"click":function($event){!_setup.alreadyCopied && _setup.handleClick()}}},[(!_setup.alreadyCopied)?_c('BaseImageSvgThemedIcon',{attrs:{"name":_setup.icon.name || 'copy',"path":_setup.icon.path || 'actions',"size":_setup.icon.size || 'm',"color":_setup.icon.color || 'primary-surface-primary'}}):_c('BaseImageSvgThemedIcon',{attrs:{"name":_setup.copiedIcon.name || 'check-blue-rounded',"path":_setup.copiedIcon.path || 'general',"size":_setup.copiedIcon.size || 'm',"color":_setup.copiedIcon.color || 'primary-surface-primary'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }