import { render, staticRenderFns } from "./UpgradeToAffiliatePrompt.vue?vue&type=template&id=3687f563&scoped=true"
import script from "./UpgradeToAffiliatePrompt.vue?vue&type=script&setup=true&lang=ts"
export * from "./UpgradeToAffiliatePrompt.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UpgradeToAffiliatePrompt.vue?vue&type=style&index=0&id=3687f563&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3687f563",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLoaderAffility: require('/root/dashboard/core/components/base/loader/Affility.vue').default,BaseCardSimpleRounded: require('/root/dashboard/core/components/base/card/SimpleRounded.vue').default,ComposedFormItemCheckbox: require('/root/dashboard/core/components/composed/form-item/Checkbox.vue').default,ComposedActionDecisionButtons: require('/root/dashboard/core/components/composed/action/DecisionButtons.vue').default,ComposedPopupNoCloseButtonAffility: require('/root/dashboard/core/components/composed/popup/NoCloseButtonAffility.vue').default})
