import { render, staticRenderFns } from "./TermsAndConditions.vue?vue&type=template&id=a4136570&scoped=true"
import script from "./TermsAndConditions.vue?vue&type=script&setup=true&lang=ts"
export * from "./TermsAndConditions.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TermsAndConditions.vue?vue&type=style&index=0&id=a4136570&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4136570",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonSimple: require('/root/dashboard/core/components/base/button/Simple.vue').default,BaseLoaderAffility: require('/root/dashboard/core/components/base/loader/Affility.vue').default,ComposedPopupCloseButtonAndHeading: require('/root/dashboard/core/components/composed/popup/CloseButtonAndHeading.vue').default})
