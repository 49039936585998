import { getStorePollingNames } from "~/core/utilities/store-polling/getStorePollingNames";

export const UNIQUE_IDENTIFIER = 'rank' as const;
export const POLLING_NAMES = getStorePollingNames(UNIQUE_IDENTIFIER);

export const STATE = {
    RANK_DATA_LOADING: 'rankDataLoading',
    RANK_DATA_LOADING_ERROR: 'rankDataLoadingError',
    RANK_DATA_LOADING_PROMISE: 'rankDataLoadingPromise',
    ...POLLING_NAMES.state,
} as const;

export const ACTIONS = {
    LOAD_PROCESS_AND_SAVE_RANK_DATA: 'loadProcessAndSaveRankData',
    ON_UPDATE_RANK_UPGRADE_NOTIFICATION_SEEN: 'onUpdateRankUpgradeNotificationSeen',
    LOAD_AND_PROCESS_RANK_DATA: 'loadAndProcessRankData',
    LOAD_RANK_DATA: 'loadRankData',
    PROCESS_RANK_DATA: 'processRankData',
    CLEAR_RANK_STATE: 'clearRankState',
    ...POLLING_NAMES.actions,
} as const;

export const MUTATIONS = {
    SET_RANK_DATA_LOADING: 'SET_RANK_DATA_LOADING',
    SET_RANK_DATA_LOADING_PROMISE: 'SET_RANK_DATA_LOADING_PROMISE',
    SET_RANK_DATA_LOADING_ERROR: 'SET_RANK_DATA_LOADING_ERROR',
    ...POLLING_NAMES.mutations,
} as const;

export const GETTERS = {
    IS_MEMBER: 'isMember',
    IS_RANK_UPGRADE_NOTIFIED: 'isRankUpdateNotified',
    IS_LAST: 'isLast',
    NEXT: 'next',

    NEXT_RANK_REQUIRED_OWN_INVESTMENT: 'nextRankRequiredOwnInvestment',
    CURRENT_OWN_INVESTMENT: 'currentOwnInvestment',

    NEXT_RANK_REQUIRED_BINARY_NFT_TURNOVER: 'nextRankRequiredBinaryNftTurnover',
    NEXT_RANK_REQUIRED_UNILEVEL_NFT_TURNOVER: 'nextRankRequiredUnilevelNftTurnover',
    TOTAL_TURNOVER_BY_WEAK_LEG: 'totalTurnoverByWeakLeg',
    TOTAL_TURNOVER_BY_UNILEVEL_EQUAL_SIDES: 'totalTurnoverByUnilevelEqualSides',
    NEXT_RANK_REQUIRED_TEAM_TURNOVER: 'nextRankRequiredTeamTurnover', // sum of nextRankRequiredBinaryNftTurnover and nextRankRequiredUnilevelNftTurnover
    CURRENT_TEAM_TURNOVER: 'currentTeamTurnover',

    NEXT_RANK_REQUIRED_QUALIFIED_PARTNERS: 'nextRankRequiredQualifiedPartners',
    CURRENT_QUALIFIED_PARTNERS: 'currentQualifiedPartners',


    RANK_DATA_LOADING: 'rankDataLoading',
    RANK_DATA_LOADING_PROMISE: 'rankDataLoadingPromise',
    RANK_DATA_LOADING_ERROR: 'rankDataLoadingError',
    ...POLLING_NAMES.getters,
} as const;