export default function ({ store, redirect, route, from }, ) {
    if (store.state?.application?.driver?.authUser) {
        redirect('/dashboard');
    } else {
        if (route.query.ref) {
            redirect(`/nfts/products?ref=${route.query.ref}`)
        } else if (route.query.token && from.name !== 'auth-one-time-token') {
            redirect(`/auth/password-recovery/?token=${route.query.token}`)
        } else {
            redirect('/nfts/products')
        }
    }
}
