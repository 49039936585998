
import {mapGetters} from "vuex";
import {defineComponent} from "vue";
import ProductOwnerSelection from "./steps/product-owner-selection/ProductOwnerSelection.vue";
import PaymentMethodSelection from "./steps/payment-method-selection/PaymentMethodSelection.vue";
import Web3WalletConnection from "./steps/web3-wallet-connection/Web3WalletConnection.vue";
import StepIndicator from "~/components/utils/indicators/step-indicator/StepIndicator.vue";
import CurrencySelection from "./steps/currency-selection/CurrencySelection.vue";
import InsufficientFunds from "./steps/insufficient-funds/InsufficientFunds.vue";
import ExternalWalletCurrencySelection
    from "./steps/external-wallet-currency-selection/ExternalWalletCurrencySelection.vue";
import ExternalWalletTxScanning from "./steps/external-wallet-tx-scanning/ExternalWalletTxScanning.vue";
import ExternalWalletMissingAmount from "./steps/external-wallet-missing-amount/ExternalWalletMissingAmount.vue";
import FlowError from "./steps/flow-error/FlowError.vue";
import PaymentSuccessful from "./steps/payment-successful/PaymentSuccessful.vue";
import BlurLoader from "~/components/utils/loader/blur-loader/BlurLoader.vue";
import PurchaseSummary from "./steps/purchase-summary/PurchaseSummary.vue";
import {MetaUser} from "~/core/models/MetaUser";
import {PURCHASE_STEPS} from "~/core/types/purchase-popup-2/PurchaseSteps";
import {UserBalance} from "~/core/models/purchase-popup-2/UserBalance";
import WalletService from "~/pages/finances/wallet/services/WalletService";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import Asset from "~/pages/finances/wallet/models/Asset";
import MunicipalityTx from "~/core/models/purchase-popup-2/MunicipalityTx";
import Web3MunicipalityTx from "~/core/models/purchase-popup-2/Web3MunicipalityTx";
import {PriceInfo} from "~/core/models/purchase-popup-2/PriceInfo";
import {PaymentMethodEnum} from "~/core/types/purchase-popup-2/PaymentMethodEnum";
import {PaymentSpecialConditions} from "~/core/types/purchase-popup-2/PaymentSpecialConditions";
import EthereumProvider from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";
import ContractService from "~/pages/finances/wallet/services/ContractService";
import {CurrencyName} from "~/pages/finances/wallet/models/CurrencyName";
import {ExternalWalletPayment} from "~/core/models/purchase-popup-2/ExternalWalletPayment";
import ExchangeService from "~/pages/finances/wallet/services/ExchangeService";
import {fromWei} from "~/core/helpers/GlobalHelpers";
import {FinishReasonEnum} from "~/core/types/purchase-popup-2/FinishReasonEnum";
import {USDT_UTILITY_SWITCHER, WALLET_ERROR_CODES} from "~/core/services/utils/Constants";
import {Purchase} from "~/core/types/purchase-popup-2/Purchase";
import WalletAsset, {WalletCurrencyName} from "~/pages/finances/wallet/models/WalletAsset";
import PapCurrencySelection
    from "~/core/components/flows/purchase/steps/pap-currency-selection/PapCurrencySelection.vue";
import PapPurchaseSummary from "~/core/components/flows/purchase/steps/pap-purchase-summary/PapPurchaseSummary.vue";

export type PurchaseCore = {
    user: MetaUser,
    PURCHASE_STEPS: typeof PURCHASE_STEPS,
    currentStep: number,
    initialExternalPayment: ExternalWalletPayment,
    currentStepIndicatorNumber: number,
    isLoading: boolean,
    purchase: Purchase,
    userBalance: UserBalance;
    transactionObject: MunicipalityTx | Web3MunicipalityTx | ExternalWalletPayment,
    mustUseUtility: boolean,
    mustUseGymnet: boolean,
    paymentMethod: PaymentMethodEnum,
    flowError: Error,
    createInitialTransaction: () => MunicipalityTx,
    handleProductOwnerSelected: () => void,
    handlePaymentMethodSelected: (paymentMethod: PaymentMethodEnum, specialConditions: PaymentSpecialConditions) => void,
    handleWeb3WalletConnected: (address: string, provider: EthereumProvider) => void,
    handleCurrencySelected: () => void;
    handleTryTxAgain: () => void,
    handleExternalPaymentCreated: (payment: ExternalWalletPayment) => void,
    handleCryptoSentClick: () => void,
    handleSuccessfulPayment: () => void,
    handleGoToNFTs: () => void;
    handleGoToMyOrders: () => void;
    handleMissingCrypto: () => void,
    handleBack: () => void,
    startLoading: () => void,
    stopLoading: (err?: unknown) => void,
}

const BALANCE_UPDATE_INTERVAL = 5000;
const BUSD_FEE_VALUE = 3;

export default defineComponent<keyof PurchaseCore, PurchaseCore>({
    name: "PurchaseCore",
    components: {
        PapPurchaseSummary,
        PapCurrencySelection,
        StepIndicator,
        BlurLoader,
        ProductOwnerSelection,
        PaymentMethodSelection,
        Web3WalletConnection,
        CurrencySelection,
        InsufficientFunds,
        ExternalWalletCurrencySelection,
        ExternalWalletTxScanning,
        ExternalWalletMissingAmount,
        PaymentSuccessful,
        FlowError,
        PurchaseSummary,
    },
    props: {
        user: {
            type: MetaUser,
            required: true,
        },
        purchase: {
            type: Object as () => Purchase,
            required: true,
        },
        initialExternalPayment: {
            type: ExternalWalletPayment,
            default: null,
        },
    },
    emits: [
        'onStepChanged',
        'onFlowFinished',
        'onFinishReasonUpdate',
    ],
    data() {
        return {
            PURCHASE_STEPS,
            currentStep: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
            previousActiveStep: null,
            isLoading: false,
            userBalance: null,
            userBalanceUpdatePromise: null,
            userBalanceUpdateInterval: null,
            municipalityTx: null,
            transactionObject: (this as unknown as PurchaseCore).createInitialTransaction(),
            paymentMethod: null,
            mustUseUtility: false,
            mustUseGymnet: false,
            canUseGymnetOrUtility: false,
            web3Provider: null,
            flowError: null,
            redirectedFromWeb3Balance: false,
        };
    },
    computed: {
        ...mapGetters('map/mapModule', ['metaverseId']),
        currentStepIndicatorNumber(): number {
            if ([PURCHASE_STEPS.SELECT_PRODUCT_OWNER].includes(this.currentStep)) {
                return 1;
            } else if ([PURCHASE_STEPS.SELECT_PAYMENT_METHOD].includes(this.currentStep)) {
                return 2;
            } else if ([
                PURCHASE_STEPS.SELECT_CURRENCY,
                PURCHASE_STEPS.SELECT_PAP_CURRENCY,
                PURCHASE_STEPS.SELECT_BLOCKCHAIN_CURRENCY,
                PURCHASE_STEPS.INSUFFICIENT_FUNDS,
                PURCHASE_STEPS.CONNECT_WALLET,
            ].includes(this.currentStep)) {
                return 3;
            } else if ([
                PURCHASE_STEPS.PURCHASE_SUMMARY,
                PURCHASE_STEPS.PAP_PURCHASE_SUMMARY,
                PURCHASE_STEPS.TRANSACTION_REJECTED,
                PURCHASE_STEPS.PAYMENT_SUCCESS,
                PURCHASE_STEPS.MISSING_CRYPTO_AMMOUNT,
                PURCHASE_STEPS.SCANNING,
            ].includes(this.currentStep)) {
                return 4;
            }
        },
        initiallyChosenTokenPAP() {
            return this.transactionObject && this.transactionObject.canUsePAP && this.purchase.selectedToken === USDT_UTILITY_SWITCHER.PAP;
        },
        isCalledFromMap() {
            return this.purchase.isCalledFromMap;
        }
    },
    watch: {
        currentStep: {
            handler(newStep, oldStep) {
                if (newStep !== oldStep) {
                    this.$emit('onStepChanged', newStep);
                    this.previousActiveStep = oldStep;
                }
            }
        },
        initialExternalPayment: {
            handler(newPayment) {
                if (newPayment) {
                    this.transactionObject = newPayment;
                    if (newPayment.isExpired ||
                        newPayment.isMissingAmount) {
                        this.currentStep = PURCHASE_STEPS.MISSING_CRYPTO_AMMOUNT;
                    } else {
                        this.currentStep = PURCHASE_STEPS.PURCHASE_SUMMARY;
                    }
                    this.paymentMethod = PaymentMethodEnum.EXTERNAL;
                }
            }
        },
    },
    mounted() {
        this.updateUserBalance();
        this.setUserBalanceUpdateInterval();
    },
    beforeDestroy() {
        clearInterval(this.userBalanceUpdateInterval);
    },
    methods: {
        async handleProductOwnerSelected() {
            await this.userBalanceUpdatePromise?.catch(() => {
            });
            this.currentStep = PURCHASE_STEPS.SELECT_PAYMENT_METHOD;
        },
        async handlePaymentMethodSelected(
            paymentMethod: PaymentMethodEnum,
            specialConditions: PaymentSpecialConditions
        ) {
            this.paymentMethod = paymentMethod;
            switch (paymentMethod) {
                case PaymentMethodEnum.INTERNAL:
                    this.mustUseUtility = specialConditions.mustUseUtility;
                    this.mustUseGymnet = specialConditions.mustUseGymnet;
                    this.currentStep = PURCHASE_STEPS.SELECT_CURRENCY;
                    break;
                case PaymentMethodEnum.PAP_BALANCE:
                    this.currentStep = PURCHASE_STEPS.SELECT_PAP_CURRENCY;
                    break;
                case PaymentMethodEnum.WEB3_WALLET:
                    this.currentStep = PURCHASE_STEPS.CONNECT_WALLET;
                    this.transactionObject = new Web3MunicipalityTx(this.transactionObject);
                    break;
                case PaymentMethodEnum.EXTERNAL:
                    this.currentStep = PURCHASE_STEPS.SELECT_BLOCKCHAIN_CURRENCY;
            }
        },
        async handleWeb3WalletConnected(address: string, provider: EthereumProvider) {
            if (this.web3Provider !== provider) {
                this.web3Provider?.removeListener('accountsChanged', this.updateWeb3WalletInfo);
                this.web3Provider = provider;
                provider.on('accountsChanged', this.updateWeb3WalletInfo);
            }
            await this.updateWeb3WalletInfo([address]);
            this.stopLoading();
        },
        async handleCurrencySelected() {
            if (this.transactionObject.priceInfo.totalAmount.currency === 'PAP') {
                this.currentStep = PURCHASE_STEPS.PAP_PURCHASE_SUMMARY;
            } else {
                this.currentStep = PURCHASE_STEPS.PURCHASE_SUMMARY;
            }
        },
        async handleExternalPaymentCreated(payment: ExternalWalletPayment) {
            this.municipalityTx = this.transactionObject;
            this.transactionObject = payment;
            this.currentStep = PURCHASE_STEPS.PURCHASE_SUMMARY;
        },
        handleCryptoSentClick() {
            this.currentStep = PURCHASE_STEPS.SCANNING;
        },
        handleGoToNFTs() {
            this.$emit('onFlowFinished');
            this.$router.push('/nfts/my-nfts');
        },
        handleGoToMyOrders() {
            this.$emit('onFlowFinished');
            this.$router.push('/finances/wallet#order-history');
        },
        handleTryTxAgain() {
            this.$emit('onFinishReasonUpdate', FinishReasonEnum.CLOSED_BY_USER);
            if (this.previousActiveStep) {
                this.currentStep = this.previousActiveStep;
                this.previousActiveStep = null;
            } else {
                this.currentStep = PURCHASE_STEPS.SELECT_PRODUCT_OWNER;
            }
        },
        handleSuccessfulPayment() {
            this.$emit('onFinishReasonUpdate', FinishReasonEnum.SUCCESS);
            this.currentStep = PURCHASE_STEPS.PAYMENT_SUCCESS;
            if (this.paymentMethod !== PaymentMethodEnum.PAP_BALANCE) {
                this.updateAuthUserRank();
            }
        },
        updateAuthUserRank() {
            if (this.purchase?.benefitsCommissionRank) {
                const transactionOwner = this.transactionObject?.owner?.toLowerCase();
                const currentUserAddress = this.user.walletAddress ?? this.user.relatedWalletAddress;
                if (currentUserAddress.toLowerCase() === transactionOwner) {
                    this.$store.commit('application/driver/UPDATE_AUTH_USER', {
                        commissionRankName: this.purchase?.benefitsCommissionRank
                    });
                }
            }
        },
        handleMissingCrypto() {
            this.currentStep = PURCHASE_STEPS.MISSING_CRYPTO_AMMOUNT;
        },
        async handleBack() {
            this.startLoading();
            this.transactionObject.account = this.user.walletAddress ?? this.user.relatedWalletAddress;
            let transactionToCreateFrom = this.transactionObject;
            if (this.paymentMethod === PaymentMethodEnum.EXTERNAL) {
                transactionToCreateFrom = this.municipalityTx ?? this.transactionObject;
            }
            this.municipalityTx = MunicipalityTx.fromMunicipalityTx(transactionToCreateFrom);
            await this.userBalanceUpdatePromise?.catch(() => {
            });
            await this.updateUserBalance();
            this.transactionObject = this.municipalityTx;
            this.paymentMethod = null;
            this.municipalityTx = null;
            switch (this.currentStep) {
                case PURCHASE_STEPS.SELECT_PAYMENT_METHOD:
                case PURCHASE_STEPS.PURCHASE_SUMMARY:
                case PURCHASE_STEPS.PAP_PURCHASE_SUMMARY:
                case PURCHASE_STEPS.INSUFFICIENT_FUNDS:
                    this.currentStep = PURCHASE_STEPS.SELECT_PRODUCT_OWNER;
                    break;
                case PURCHASE_STEPS.SELECT_CURRENCY:
                case PURCHASE_STEPS.SELECT_PAP_CURRENCY:
                case PURCHASE_STEPS.SELECT_BLOCKCHAIN_CURRENCY:
                case PURCHASE_STEPS.CONNECT_WALLET:
                    this.currentStep = PURCHASE_STEPS.SELECT_PAYMENT_METHOD;
                    break;
            }
            this.stopLoading();
        },
        async handleResetUserBalanceData() {
            this.redirectedFromWeb3Balance = false;
            const transactionSender = this.transactionObject.account.toLowerCase();
            const currentUserAddress = this.user.walletAddress ?? this.user.relatedWalletAddress;

            if (
                currentUserAddress.toLowerCase() !== transactionSender &&
                (
                    this.previousActiveStep === PURCHASE_STEPS.SELECT_PAYMENT_METHOD ||
                    this.previousActiveStep === PURCHASE_STEPS.INSUFFICIENT_FUNDS ||
                    this.previousActiveStep === PURCHASE_STEPS.SELECT_CURRENCY
                )
            ) {
                this.redirectedFromWeb3Balance = true;
                this.transactionObject.account = this.user.walletAddress ?? this.user.relatedWalletAddress;
                let transactionToCreateFrom = this.transactionObject;
                this.municipalityTx = MunicipalityTx.fromMunicipalityTx(transactionToCreateFrom);
                this.transactionObject = this.municipalityTx;
                this.paymentMethod = null;
                this.municipalityTx = null;
                await this.updateUserBalance();
                this.setUserBalanceUpdateInterval();
            }
        },
        async updateWeb3WalletInfo(addresses: string[]) {
            this.mustUseUtility = false;
            this.mustUseGymnet = false;
            const stepsToReact = [
                PURCHASE_STEPS.PURCHASE_SUMMARY,
                PURCHASE_STEPS.INSUFFICIENT_FUNDS,
                PURCHASE_STEPS.SELECT_CURRENCY,
                PURCHASE_STEPS.TRANSACTION_REJECTED,
                PURCHASE_STEPS.CONNECT_WALLET
            ];
            if (addresses?.length) {
                if (this.paymentMethod === PaymentMethodEnum.WEB3_WALLET &&
                    stepsToReact.includes(this.currentStep)) {
                    try {
                        if (this.purchase.canBuyFor) {
                            const canBuyFor = await this.purchase.canBuyFor(this.transactionObject.owner, addresses[0]);
                            if (!canBuyFor) {
                                return;
                            }
                        }
                        this.startLoading();
                        const metaverseId = this.metaverseId;
                        const metaWorldManager = MetaWorldManager.sharedInstance();
                        const municipalityContract = await ContractService.getContract(this.purchase.contract, metaWorldManager, metaverseId, true, this.web3Provider);
                        const BUSDContract = await ContractService.getContract('USDT', metaWorldManager, metaverseId, true, this.web3Provider);
                        const gymnetContract = await ContractService.getContract('GYMNET', metaWorldManager, metaverseId, true, this.web3Provider);
                        this.transactionObject._municipality = municipalityContract;
                        this.transactionObject._currency = BUSDContract;
                        this.transactionObject._gymnet = gymnetContract;
                        this.transactionObject.account = addresses[0];
                        await this.userBalanceUpdatePromise?.catch(() => {});
                        const userBalance = await this.updateUserBalance();
                        await this.transactionObject.estimateGas();
                        const priceInfo = this.transactionObject.priceInfo;

                        const bnbBalance = userBalance.bnb.value;
                        const usdtBalance = userBalance.usdt.value;
                        const utilityBalance = userBalance.utility.value;
                        const papBalance = userBalance.pap.value;
                        const gymnetBalance = userBalance.gymnet.value;

                        const totalUSDTPrice = this.transactionObject.isEnabled
                            ? ((bnbBalance && bnbBalance > 0) ? priceInfo.totalAmount.value : priceInfo.totalAmount.value + BUSD_FEE_VALUE)
                            : priceInfo.totalAmount.value;

                        const useUtilityGymnetBalance_USDT_Price = priceInfo.splitAmountUtility?.usdt.value || 0;
                        const useUtilityGymnetBalance_GYMNET_Price = priceInfo.splitAmountUtility?.gymnet.value || 0;

                        const isTotalUSDTSufficient = usdtBalance >= totalUSDTPrice;
                        const isUseUtilityGymnetBalanceUSDTSufficient = usdtBalance >= useUtilityGymnetBalance_USDT_Price;
                        const isUseUtilityGymnetBalance_UtilitySufficient = utilityBalance >= useUtilityGymnetBalance_GYMNET_Price;
                        const isUseUtilityGymnetBalance_GYMNETSufficient = gymnetBalance >= useUtilityGymnetBalance_GYMNET_Price;

                        const canUseGymnetOrUtility = this.transactionObject.canUseGymnetOrUtility;
                        const canUsePaa = this.transactionObject.canUsePAP;
                        const isTotalPAPSufficient = papBalance >= totalUSDTPrice;

                        const papSelected = this.paymentMethod === PaymentMethodEnum.PAP_BALANCE;

                        if ((
                            !papSelected && (
                                (!canUseGymnetOrUtility && !isTotalUSDTSufficient) ||
                                (
                                    canUseGymnetOrUtility &&
                                    !isTotalUSDTSufficient &&
                                    (
                                        !isUseUtilityGymnetBalanceUSDTSufficient ||
                                        (
                                            !isUseUtilityGymnetBalance_UtilitySufficient &&
                                            !isUseUtilityGymnetBalance_GYMNETSufficient
                                        )
                                    )
                                )
                            )) ||
                            (papSelected && canUsePaa && !isTotalPAPSufficient)
                        ) {
                            await this.handleResetUserBalanceData();
                            this.currentStep = PURCHASE_STEPS.INSUFFICIENT_FUNDS;
                        } else {
                            if (this.paymentMethod === PaymentMethodEnum.PAP_BALANCE) {
                                this.currentStep = PURCHASE_STEPS.SELECT_PAP_CURRENCY;
                            } else {
                                if (canUseGymnetOrUtility && !isTotalUSDTSufficient && isUseUtilityGymnetBalanceUSDTSufficient) {
                                    if (isUseUtilityGymnetBalance_UtilitySufficient && this.transactionObject.canUseUtility) {
                                        this.mustUseUtility = true;
                                    }
                                    if (isUseUtilityGymnetBalance_GYMNETSufficient) {
                                        this.mustUseGymnet = true;
                                    }
                                }

                                this.currentStep = PURCHASE_STEPS.SELECT_CURRENCY;
                            }
                        }
                    } finally {
                        this.stopLoading();
                    }
                }
            }
        },
        startLoading() {
            this.isLoading = true;
        },
        stopLoading(err?: unknown) {
            this.isLoading = false;
            if (err) {
                const code = (err as { code: number })?.code;
                if (code === WALLET_ERROR_CODES.USER_REJECTED) {
                    this.$emit('onFinishReasonUpdate', FinishReasonEnum.CLOSED_BY_USER);
                } else if (err) {
                    this.$emit('onFinishReasonUpdate', FinishReasonEnum.FAILED);
                }

                this.flowError = err;
                this.currentStep = PURCHASE_STEPS.TRANSACTION_REJECTED;
            }
        },
        setUserBalanceUpdateInterval() {
            clearInterval(this.userBalanceUpdateInterval);
            this.userBalanceUpdateInterval = setInterval(this.updateUserBalance, BALANCE_UPDATE_INTERVAL);
        },
        async updateUserBalance() {
            if (this.paymentMethod !== PaymentMethodEnum.EXTERNAL) {
                try {
                    const inventoryContainer = MetaInventoryContainer.sharedInstance();
                    const metaWorldManager = MetaWorldManager.sharedInstance();
                    const walletAddress = this.transactionObject.account;
                    const assets = WalletService.getAssets(
                        inventoryContainer,
                        walletAddress,
                        metaWorldManager,
                        this.metaverseId,
                        true,
                    );
                    const utilityPromise = this.getUtilityAsset(
                        metaWorldManager,
                        inventoryContainer,
                        await ExchangeService.getRate("GYMNET"),
                    )
                    const papPromise = this.getAutoshipPointsAsset( metaWorldManager, inventoryContainer );

                    this.userBalanceUpdatePromise = Promise.all([assets, utilityPromise, papPromise]);
                    const internalAssets = await this.userBalanceUpdatePromise;
                    this.userBalanceUpdatePromise = null;
                    const [[bnb, _filecoin, gymnet, busd, _, usdt], utility, pap] = internalAssets;
                    this.userBalance = new UserBalance(
                        bnb,
                        gymnet,
                        busd,
                        utility,
                        usdt,
                        pap
                    );

                    const userBalance = this.userBalance;
                    const priceInfo = this.transactionObject.priceInfo;

                    const bnbBalance = userBalance.bnb.value;
                    const usdtBalance = userBalance.usdt.value;
                    const utilityBalance = userBalance.utility.value;
                    const papBalance = userBalance.pap.value;
                    const gymnetBalance = userBalance.gymnet.value;

                    const totalUSDTPrice = this.transactionObject.isEnabled
                        ? ((bnbBalance && bnbBalance > 0) ? priceInfo.totalAmount.value : priceInfo.totalAmount.value + BUSD_FEE_VALUE)
                        : priceInfo.totalAmount.value;

                    const useUtilityGymnetBalance_USDT_Price = priceInfo.splitAmountUtility?.usdt.value || 0;
                    const useUtilityGymnetBalance_GYMNET_Price = priceInfo.splitAmountUtility?.gymnet.value || 0;

                    const isTotalUSDTSufficient = usdtBalance >= totalUSDTPrice;
                    const isUseUtilityGymnetBalanceUSDTSufficient = usdtBalance >= useUtilityGymnetBalance_USDT_Price;
                    const isUseUtilityGymnetBalance_UtilitySufficient = utilityBalance >= useUtilityGymnetBalance_GYMNET_Price;
                    const isUseUtilityGymnetBalance_GYMNETSufficient = gymnetBalance >= useUtilityGymnetBalance_GYMNET_Price;

                    const canUseGymnetOrUtility = this.transactionObject.canUseGymnetOrUtility;
                    const canUsePaa = this.transactionObject.canUsePAP;
                    const isTotalPAPSufficient = papBalance >= totalUSDTPrice;

                    const papSelected = this.paymentMethod === PaymentMethodEnum.PAP_BALANCE;

                    if (this.currentStep === PURCHASE_STEPS.SELECT_CURRENCY ||
                        this.currentStep === PURCHASE_STEPS.SELECT_PAP_CURRENCY ||
                        (this.currentStep === PURCHASE_STEPS.INSUFFICIENT_FUNDS && !this.redirectedFromWeb3Balance))
                    {
                        if ((
                            !papSelected && (
                                (!canUseGymnetOrUtility && !isTotalUSDTSufficient) ||
                                (
                                    canUseGymnetOrUtility &&
                                    !isTotalUSDTSufficient &&
                                    (
                                        !isUseUtilityGymnetBalanceUSDTSufficient ||
                                        (
                                            !isUseUtilityGymnetBalance_UtilitySufficient &&
                                            !isUseUtilityGymnetBalance_GYMNETSufficient
                                        )
                                    )
                                )
                            )) ||
                            (papSelected && canUsePaa && !isTotalPAPSufficient)
                        ) {
                            this.redirectedFromWeb3Balance = false;
                            this.currentStep = PURCHASE_STEPS.INSUFFICIENT_FUNDS;
                        } else {
                            if (this.paymentMethod === PaymentMethodEnum.PAP_BALANCE) {
                                this.currentStep = PURCHASE_STEPS.SELECT_PAP_CURRENCY;
                            } else {
                                if (canUseGymnetOrUtility && !isTotalUSDTSufficient && isUseUtilityGymnetBalanceUSDTSufficient) {
                                    if (isUseUtilityGymnetBalance_UtilitySufficient && this.transactionObject.canUseUtility) {
                                        this.mustUseUtility = true;
                                    }
                                    if (isUseUtilityGymnetBalance_GYMNETSufficient) {
                                        this.mustUseGymnet = true;
                                    }
                                }

                                this.currentStep = PURCHASE_STEPS.SELECT_CURRENCY;
                            }
                        }
                    }

                    return this.userBalance;
                } catch (err) {
                    console.log('Error updating balance');
                    this.userBalanceUpdatePromise = null;
                    return this.userBalance;
                }
            }
        },
        async getUtilityAsset(
            metaWorldManager: MetaWorldManager,
            inventoryContainer: MetaInventoryContainer,
            gymnetRate: number
        ) {
            const transactionSender = this.transactionObject.account.toLowerCase();
            const currentUserAddress = this.user.walletAddress ?? this.user.relatedWalletAddress;
            let utilityAmount = inventoryContainer?.minerRewardsData
                ?.minerRewards
                ?.minerRewardsUtilityInfo
                ?.realAvailable ?? 0;
            if (currentUserAddress.toLowerCase() !== transactionSender) {
                const utilityInfo = await this.$contracts[this.metaverseId].MiningSC.methods.utilInfo(transactionSender).call();
                utilityAmount = Number(fromWei(utilityInfo.available));
            }
            const utilityAsset = new Asset(
                null,
                "Utility",
                "Utility" as CurrencyName,
                require('~/assets/images/gymstreet/currencies/utility-balance.svg'),
                utilityAmount,
                gymnetRate,
                false,
                metaWorldManager,
            );
            return utilityAsset;
        },
        async getAutoshipPointsAsset(
            metaWorldManager: MetaWorldManager,
            inventoryContainer: MetaInventoryContainer,
        ) {
            const subscriptionPointsRate = 1;
            const metaverseID = this.metaverseId;
            let subscriptionPointsAmount = inventoryContainer?.userCryptoBalance.subscriptionPoints ?? 0;

            return new WalletAsset(
                null,
                "Product Autoship Points",
                'Product Autoship Points' as WalletCurrencyName,
                require('~/assets/images/gymstreet/currencies/Subscription.svg'),
                subscriptionPointsAmount,
                subscriptionPointsRate,
                false,
                metaverseID,
                metaWorldManager,
                await ContractService.getContract('USDT', metaWorldManager, metaverseID)
            );
        },
        createInitialTransaction() {
            const self = this as any;
            const contracts = self.$contracts[self.$metaverseId()];
            const purchase = self.$props.purchase;
            const user = self.$props.user;
            const priceInfo = new PriceInfo(
                new Asset(
                    null,
                    "Tether USD",
                    'USDT',
                    require('~/assets/images/wallets/usdt.png'),
                    Number(purchase.price),
                    1,
                    false,
                    MetaWorldManager.sharedInstance(),
                    "Binance Smart Chain (BEP20)"
                ),
            );

            let transaction = new MunicipalityTx(
                contracts.USDT,
                contracts.GymNetwork,
                contracts[purchase.contract],
                purchase.method,
                [...purchase.params],
                user.walletAddress ?? user.relatedWalletAddress,
                priceInfo,
            );

            if (!user.isFullyWeb2User) {
                transaction = new Web3MunicipalityTx(transaction);
            }

            return transaction;

        },
        handleClosePopup() {
            this.$emit('onFlowFinished')
        }
    }
});

