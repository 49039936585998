import { ACTIONS, MUTATIONS } from "./names";

export default {
    [ACTIONS.OPEN_FULL_PAGE_LOADING]({ commit }, text = '') {
        commit(MUTATIONS.SET_FULL_PAGE_LOADING, { visible: true, text });
    },
    [ACTIONS.CLOSE_FULL_PAGE_LOADING]({ commit }, payload: any = {}) {
        const delay = payload?.delay || 0;
        setTimeout(() => {
            commit(MUTATIONS.SET_FULL_PAGE_LOADING, { visible: false, text: '' });
        }, delay);
    }
};
