import { RANKS_CONST } from "~/constants/constants";
import { METAVERSE_IDS } from "~/core/services/utils/MetaverseConstants";

export default function bundlesInfoData(metaverseId: 1 | 2) {
    const bundles = {
        26: {
            name: 'Starter Bundle',
            preservedIndex: 13,
            isSuper: false,
            typeID: 26,
        },
        13: {
            name: 'Super Bundle R6',
            isSuper: true,
            typeID: 13,
            preservedIndex: 14,
            terahashValue: 15,
            benefitsInfo: createBenefits(9, 3, 4, RANKS_CONST.SILVER),
        },
        14: {
            name: 'Super Bundle R10',
            isSuper: true,
            typeID: 14,
            terahashValue: 92,
            benefitsInfo: createBenefits(10, 5, 6, RANKS_CONST.PLATIN),
        },
        15: {
            name: 'Super Bundle R15',
            isSuper: true,
            typeID: 15,
            terahashValue: 162,
            benefitsInfo: createBenefits(10, 6, 7, RANKS_CONST.DOUBLE_PLATIN),
        },
        17: {
            name: 'Super Bundle R22 S',
            isSuper: true,
            typeID: 17,
            terahashValue: 341,
            benefitsInfo: createBenefits(10, 7, 10, RANKS_CONST.DIAMOND),
        },
    }

    if (metaverseId === METAVERSE_IDS.GYMSTREET) {
        Object.assign(bundles, {
            25: {
                name: 'Super Bundle R8',
                isSuper: true,
                typeID: 25,
                preservedIndex: 15,
                terahashValue: 37,
                benefitsInfo: createBenefits(10, 4, 5, RANKS_CONST.GOLD),
            },
            27: {
                name: 'Super Bundle R17',
                isSuper: true,
                typeID: 27,
                preservedIndex: 18,
                terahashValue: 198,
                benefitsInfo: createBenefits(10, 7, 8, RANKS_CONST.DIAMOND),
            },
            16: {
                name: 'Super Bundle R20',
                isSuper: true,
                typeID: 16,
                terahashValue: 233,
                benefitsInfo: createBenefits(10, 7, 9, RANKS_CONST.DIAMOND),
            },
            18: {
                name: 'Super Bundle R22 M',
                isSuper: true,
                typeID: 18,
                terahashValue: 609,
                benefitsInfo: createBenefits(10, 7, 11, RANKS_CONST.DIAMOND),
            },
        });
    }

    return bundles;
}

const createBenefits = (
    binaryCommission: number,
    matchingBonusLevel: number,
    profitMatchingLevelBenefits: number,
    commissionRank: string,
) => ({
    binaryCommission,
    matchingBonusLevel,
    profitMatchingLevelBenefits,
    commissionRank,
});
