import { MapSquareCoordinates } from "./MapSquareCoordinates";

export class MetaReservedArea {
    constructor (
        public readonly id: number,
        public readonly name: string,
        public readonly x: number,
        public readonly y: number,
        public readonly endX: number,
        public readonly endY: number,
        public readonly matrixBuffer: Uint8Array,
        public readonly imagePath?: string,
        public readonly imagesSpriteNumber?: number,
        public readonly imageLeftTopX?: number,
        public readonly imageLeftTopY?: number,
        public readonly imageRightBottomX?: number,
        public readonly imageRightBottomY?: number,
        public readonly infoImage?: string,
        public readonly infoVideo?: string,
        public readonly link?: string,
        public readonly affiliateLink?: string,
        public readonly partnershipId?: number,
        public readonly partnerLogo?: string,
        public readonly partnerName?: string,
        public readonly partnerUrl?: string,
        public readonly partnershipDataUrl?: string,
        public readonly imageLocationDataIndex?: number,
    ) {
        Object.defineProperty(this, 'matrixBuffer', { enumerable: false });
    }

    public get width () {
        return this.endX - this.x;
    }

    public get height () {
        return this.endY - this.y;
    }

    public get coords () {
        return new MapSquareCoordinates({
            x: this.x,
            y: this.y,
            x2: this.endX,
            y2: this.endY,
        });
    }

    public static fromAnotherArea (area: Partial<MetaReservedArea>, modifier: Partial<MetaReservedArea> = {}) {
        return new MetaReservedArea(
            modifier.id ?? area.id,
            modifier.name ?? area.name,
            modifier.x ?? area.x,
            modifier.y ?? area.y,
            modifier.endX ?? area.endX,
            modifier.endY ?? area.endY,
            modifier.matrixBuffer ?? area.matrixBuffer,
            modifier.imagePath ?? area.imagePath,
            modifier.imagesSpriteNumber ?? area.imagesSpriteNumber,
            modifier.imageLeftTopX ?? area.imageLeftTopX,
            modifier.imageLeftTopY ?? area.imageLeftTopY,
            modifier.imageRightBottomX ?? area.imageRightBottomX,
            modifier.imageRightBottomY ?? area.imageRightBottomY,
            modifier.infoImage ?? area.infoImage,
            modifier.infoVideo ?? area.infoVideo,
            modifier.link ?? area.link,
            modifier.affiliateLink ?? area.affiliateLink,
            modifier.partnershipId ?? area.partnershipId,
            modifier.partnerLogo ?? area.partnerLogo,
            modifier.partnerName ?? area.partnerName,
            modifier.partnerUrl ?? area.partnerUrl,
            modifier.partnershipDataUrl ?? area.partnershipDataUrl,
            modifier.imageLocationDataIndex ?? area.imageLocationDataIndex,
        );
    }

}