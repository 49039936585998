export const STATE = {
} as const;

export const ACTIONS = {
    WATCH_ASSET: 'watchAsset',
    ON_REQUEST_TO_CHANGE_CHAIN: 'onRequestToChangeChain',
    ON_REQUEST_TO_ADD_CHAIN: 'onRequestToAddChain',
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
    MAIN_CONTRACT_ADDRESSES: 'mainContractAddresses',
} as const;
