import { GETTERS as USER_AUTH_GETTERS } from "../auth/names";
import { GETTERS as BINARY_PROGRESS_GETTERS } from "../binary/progress/names";
import { GETTERS as UNILEVEL_PROGRESS_GETTERS } from "../unilevel/progress/names";
import { GETTERS, STATE } from "./names";
import type { State } from "./types";
import { binaryRanks, RANKS_CONST, RANKS_ORDER } from "~/constants/constants";
import { POLLING_STORE_FUNCTIONS } from "./polling";

const UNILEVEL_PROGRESS_PART = 0.1 as const;

export default {
    [GETTERS.IS_MEMBER]: (_state: State, _getters, _rootState, rootGetters) => {
        const currentUser = rootGetters[`affility/user/auth/${USER_AUTH_GETTERS.CURRENT_USER}`];
        return currentUser?.currentRankName === binaryRanks[RANKS_ORDER[RANKS_CONST.MEMBER]].name;
    },
    [GETTERS.IS_RANK_UPGRADE_NOTIFIED]: (_state: State, _getters, _rootState, rootGetters) => {
        const currentUser = rootGetters[`affility/user/auth/${USER_AUTH_GETTERS.CURRENT_USER}`];
        return currentUser?.isRankUpdateNotified;
    },

    [GETTERS.IS_LAST]: (_state: State, _getters, _rootState, rootGetters) => {
        const currentUser = rootGetters[`affility/user/auth/${USER_AUTH_GETTERS.CURRENT_USER}`];
        return currentUser?.currentRankName === binaryRanks[binaryRanks.length - 1].name;
    },
    [GETTERS.NEXT]: (_state: State, _getters, _rootState, rootGetters) => {
        const currentUser = rootGetters[`affility/user/auth/${USER_AUTH_GETTERS.CURRENT_USER}`];
        const currentRankIndex = RANKS_ORDER[currentUser?.currentRankName];
        return binaryRanks[Math.min(currentRankIndex + 1, binaryRanks.length - 1)];
    },

    [GETTERS.NEXT_RANK_REQUIRED_OWN_INVESTMENT]: (_state: State, getters) => {
        const nextRank = getters[GETTERS.NEXT];
        return nextRank?.nftOwnInvest;
    },
    [GETTERS.CURRENT_OWN_INVESTMENT]: (_state: State, _getters, _rootState, rootGetters) => {
        return rootGetters[`affility/user/binary/progress/${BINARY_PROGRESS_GETTERS.TOTAL_OWN_PURCHASE}`];
    },

    [GETTERS.NEXT_RANK_REQUIRED_BINARY_NFT_TURNOVER]: (_state: State, getters) => {
        const nextRank = getters[GETTERS.NEXT];
        return nextRank?.nftTurnoverInWeakLeg;
    },
    [GETTERS.NEXT_RANK_REQUIRED_UNILEVEL_NFT_TURNOVER]: (_state: State, getters) => {
        const binaryRequiredBinaryNftTurnover = getters[GETTERS.NEXT_RANK_REQUIRED_BINARY_NFT_TURNOVER];
        return (binaryRequiredBinaryNftTurnover || 0) * UNILEVEL_PROGRESS_PART;
    },
    [GETTERS.TOTAL_TURNOVER_BY_WEAK_LEG]: (_state: State, _getters, _rootState, rootGetters) => {
        const weakLegTurnover = rootGetters[`affility/user/binary/progress/${BINARY_PROGRESS_GETTERS.CURRENT_TURNOVER_IN_WEAK_LEG}`];
        return weakLegTurnover * 2;
    },
    [GETTERS.TOTAL_TURNOVER_BY_UNILEVEL_EQUAL_SIDES]: (_state: State, getters, _rootState, rootGetters) => {
        const requiredNftTurnoverByUnilevel = getters[GETTERS.NEXT_RANK_REQUIRED_UNILEVEL_NFT_TURNOVER];
        const requiredTurnoverBySide = requiredNftTurnoverByUnilevel / 2;
        const leftLegTurnover = rootGetters[`affility/user/unilevel/progress/${UNILEVEL_PROGRESS_GETTERS.PROGRESS_BY_BINARY_LEFT}`];
        const rightLegTurnover = rootGetters[`affility/user/unilevel/progress/${UNILEVEL_PROGRESS_GETTERS.PROGRESS_BY_BINARY_RIGHT}`];
        return Math.min(leftLegTurnover, requiredTurnoverBySide) +
               Math.min(rightLegTurnover, requiredTurnoverBySide);
    },
    [GETTERS.NEXT_RANK_REQUIRED_TEAM_TURNOVER]: (_state: State, getters) => {
        const requiredBinaryTurnover = getters[GETTERS.NEXT_RANK_REQUIRED_BINARY_NFT_TURNOVER];
        const requiredUnilevelTurnover = getters[GETTERS.NEXT_RANK_REQUIRED_UNILEVEL_NFT_TURNOVER];
        return getters[GETTERS.IS_MEMBER] ? 200 : requiredBinaryTurnover + requiredUnilevelTurnover;
    },
    [GETTERS.CURRENT_TEAM_TURNOVER]: (_state: State, getters, _rootState, rootGetters) => {
        const isMember = getters[GETTERS.IS_MEMBER];
        const binaryProgressPrefix = 'affility/user/binary/progress/';
        if (isMember) {
            const leftLegPurchasesSatisfiedCoef = Number(!!rootGetters[`${binaryProgressPrefix}${BINARY_PROGRESS_GETTERS.LEFT_LEG_MIN_PURCHASE_SATISFIED}`]);
            const rightLegPurchaseSatisfiedCoef = Number(!!rootGetters[`${binaryProgressPrefix}${BINARY_PROGRESS_GETTERS.RIGHT_LEG_MIN_PURCHASE_SATISFIED}`]);
            // return 100 dollar for each partner purchase, casting boolean to number to get 0 or 1
            return (leftLegPurchasesSatisfiedCoef + rightLegPurchaseSatisfiedCoef) * 100;
        }

        const rankCappedBinaryTurnover = Math.min(
            getters[GETTERS.TOTAL_TURNOVER_BY_WEAK_LEG],
            getters[GETTERS.NEXT_RANK_REQUIRED_BINARY_NFT_TURNOVER],
        );
        const rankCappedUnilevelTurnover = Math.min(
            getters[GETTERS.TOTAL_TURNOVER_BY_UNILEVEL_EQUAL_SIDES],
            getters[GETTERS.NEXT_RANK_REQUIRED_UNILEVEL_NFT_TURNOVER],
        );
        return rankCappedBinaryTurnover + rankCappedUnilevelTurnover;
    },

    [GETTERS.NEXT_RANK_REQUIRED_QUALIFIED_PARTNERS]: (_state: State, getters) => {
        const nextRank = getters[GETTERS.NEXT];
        return nextRank?.requiredPartners;
    },
    [GETTERS.CURRENT_QUALIFIED_PARTNERS]: (_state: State, getters, _rootState, rootGetters) => {
        const isMember = getters[GETTERS.IS_MEMBER];
        const binaryProgressPrefix = 'affility/user/binary/progress/';
        if (isMember) {
            const leftLegUnilevelRanksSatisfiedCoef = Number(!!rootGetters[`${binaryProgressPrefix}${BINARY_PROGRESS_GETTERS.UNILEVEL_RANKS_BY_LEFT_LEG_SATISFIED}`]);
            const rightLegUnilevelRanksSatisfiedCoef = Number(!!rootGetters[`${binaryProgressPrefix}${BINARY_PROGRESS_GETTERS.UNILEVEL_RANKS_BY_RIGHT_LEG_SATISFIED}`]);
            // return sum after casting boolean to number to get 0's or 1's
            return leftLegUnilevelRanksSatisfiedCoef + rightLegUnilevelRanksSatisfiedCoef;
        }

        const lPartnersCount = rootGetters[`${binaryProgressPrefix}${BINARY_PROGRESS_GETTERS.LEFT_LEG_PARTNERS_COUNT}`] || 0;
        const rPartnersCount = rootGetters[`${binaryProgressPrefix}${BINARY_PROGRESS_GETTERS.RIGHT_LEG_PARTNERS_COUNT}`] || 0;
        const totalPartners = lPartnersCount + rPartnersCount;
        const requiredPartners = getters[GETTERS.NEXT_RANK_REQUIRED_QUALIFIED_PARTNERS];
        const cappedPartners = Math.min(totalPartners, requiredPartners);
        // don't count a single partner if one of the legs is empty
        if ([lPartnersCount, rPartnersCount].includes(0) &&
            cappedPartners === requiredPartners) {
            return cappedPartners - 1;
        }
        return cappedPartners;
    },

    [GETTERS.RANK_DATA_LOADING]: (state: State) => state[STATE.RANK_DATA_LOADING],
    [GETTERS.RANK_DATA_LOADING_ERROR]: (state: State) => state[STATE.RANK_DATA_LOADING_ERROR],
    [GETTERS.RANK_DATA_LOADING_PROMISE]: (state: State) => state[STATE.RANK_DATA_LOADING_PROMISE],

    ...POLLING_STORE_FUNCTIONS.getters,
}