import {
    diagLog,
    BUNDLE_TYPE_PARCELS_MAX,
    BUNDLE_TYPE_PARCELS_MINERS_MAX,
    BUNDLE_TYPE_MINERS_MAX,
    MINER_HASH_POWER,
    NULLABLE_WALLET_ADDRESS,
    PARCEL_UPGRADED_MINERS_COUNT,
    MINER_UNIT_PRICE,
    PARCEL_MINERS_COUNT,
    WALLET_ERROR_CODES,
    PRODUCT_TYPES,
    USDT_UTILITY_SWITCHER,
} from '../utils/Constants';
import { MetaNotificationsContainer } from "../notifications/MetaNotificationsContainer";
import {METAVERSES, METAVERSE_IDS} from "../utils/MetaverseConstants";
import ApiService from "../api-interaction/ApiService";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import {GlobalMakerService} from "~/core/services/GlobalMakerService";
import {
    createEventName,
    GOOGLE_ANALYTICS_EVENT_NAMES,
    GOOGLE_ANALYTICS_EVENT_TYPES,
} from "~/constants/gtag-events";
import {HashIdService} from "~/core/services/HashIdService";
import {PopupHelper} from "~/core/helpers/PopupHelper";
import {TranslationHelper} from "~/core/services/TranslationHelper";
import {
    addAndPromisifyBatchCalls,
    getClientRuntimeConfig,
    getHidedAccountAddress,
} from "~/core/helpers/GlobalHelpers";
import { InsufficientFundsError } from "~/core/exceptions/InsufficientFundsError";
import { TxRejectedByUserException } from "~/core/exceptions/TxRejectedByUserException";
import { FinishReasonEnum } from '~/core/types/purchase-popup-2/FinishReasonEnum';
import { PaymentType } from '~/core/models/purchase-popup-2/PaymentType';
import { FileCoinService } from '~/pages/finances/wallet/services/FileCoinService';
import { SubscriptionService } from '~/pages/finances/wallet/services/SubscriptionService';
import { PURCHASE_STEPS } from '~/core/types/purchase-popup-2/PurchaseSteps';
import { EthService } from '~/pages/finances/wallet/services/EthService';

const GAS_FEE_MULTIPLIER = 1.5;
const $config = getClientRuntimeConfig();

export class MetaWorldManager {
    static sharedInstance () {
        if(MetaWorldManager.instance === null) {
            MetaWorldManager.instance = new MetaWorldManager();
        }
        return MetaWorldManager.instance;
    }

    constructor () {
        this.isSCDataLoaded = false;
        this.parcelsStats = null;
        this.minerEventsSubscription = null;
        this.metaverseId = null;
        this.allContracts = null;
        this.allWriteContracts = null;
        this.fetchUserGoodsLoop = null;
        this.bundlesCache = new Map();
    }

    get contracts () {
        return this.allContracts?.[this.metaverseId];
    }

    get writeContracts () {
        return this.allWriteContracts?.[this.metaverseId];
    }

    async fetchUserBalanceFromContract (contractName, batchRequest = null) {
        let balance = '0';
        const address = this.getWalletAddressForFetchInfo();
        if(address && this.contracts) {
            if(!batchRequest) {
                balance = this.readWeb3().utils.fromWei(await this.contracts[contractName].methods.balanceOf(address).call());
            } else {
                const balanceCall = {method: this.contracts[contractName].methods.balanceOf(address).call, name: contractName};
                const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [balanceCall]);

                await Promise.all(promisesArray);
                balance = this.readWeb3().utils.fromWei(resolvedValues[contractName]);
            }
        }
        return Number(balance).toFixed(4);
    }

    async fetchUserCryptoBalance (batchRequest = null) {
        const result = {
            busd: 0,
            gymnet: 0,
            subscriptionPoints: 0,
            usdt: 0,
            filecoinNative: 0,
            eth: 0,
            moonberg: 0,
        };

        try {
            const address = this.getWalletAddressForFetchInfo();
            const busd = this.fetchUserBalanceFromContract('BUSD', batchRequest);
            const usdt = this.fetchUserBalanceFromContract('USDT', batchRequest);
            const gymnet = this.fetchUserBalanceFromContract('GymNetwork', batchRequest);
            const subscriptionPoints = SubscriptionService.getSubscriptionPoints();
            const filecointNative = FileCoinService.getNativeBalance(address);
            const eth = EthService.getNativeBalance(address);
            const moonberg = EthService.getMoonbergBalance(address);
            const btc = this.fetchUserBalanceFromContract('BTCB', batchRequest);

            const mapEntries = [
                [busd, 'busd'],
                [gymnet, 'gymnet'],
                [subscriptionPoints, 'subscriptionPoints'],
                [usdt, 'usdt'],
                [filecointNative, 'filecoinNative'],
                [eth, 'eth'],
                [moonberg, 'moonberg'],
                [btc, 'btc'],
            ];

            const promisesMap = new Map(mapEntries);

            const resolvedValues = await Promise.allSettled(promisesMap.keys());

            let errorInnerText = '';

            for (let i = 0; i < resolvedValues.length; i++) {
                result[mapEntries[i][1]] = resolvedValues[i].status === 'fulfilled' ? Number(resolvedValues[i].value) : null;
                if (resolvedValues[i].status !== 'fulfilled') {
                    errorInnerText += mapEntries[i][1] + '(' + resolvedValues[i].reason + '), ';
                }
            }

            if (errorInnerText) {
                let errorTxt = `Balance for [${errorInnerText}] have thrown an error`;
                throw new Error(errorTxt);
            }
        } catch (e) {
            console.log(e);
        }
        MetaInventoryContainer.sharedInstance().setUserCryptoBalance(result);
        return result;
    }

    getWalletAddressForFetchInfo (defaultWallet = null, userToGet = null) {
        const authUser = userToGet ?? MetaInventoryContainer.sharedInstance().authUserData;
        let address = defaultWallet ?? NULLABLE_WALLET_ADDRESS;
        if (authUser) {
            if (authUser.walletAddress) {
                address = authUser.walletAddress;
            } else if (authUser.relatedWalletAddress) {
                address = authUser.relatedWalletAddress;
            }
        }

        return address;
    }

    async fetchUserOwnedNFTsSum () { // TODO check usages of this method and refactor
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const userAddress = authUser?.walletAddress;
        if(userAddress) {
            const p = await this.contracts.StandardParcel.methods.balanceOf(userAddress).call();
            const m = await this.contracts.MinerNFT.methods.balanceOf(userAddress).call();
            return Number(p) + Number(m);
        }
        return 0;
    }

    async fetchMinerPriceInfo (minerCount) {
        let result = null;
        try {
            const priceInfo = await this.contracts.Municipality.methods.getPriceForPurchaseMiners(minerCount).call();

            result = {
                priceForMintMiner: parseFloat(this.web3().utils.fromWei(priceInfo[0])),
                priceForBuyMiner: parseFloat(this.web3().utils.fromWei(priceInfo[1])),
            };
            diagLog(this, minerCount, result);
        } catch (e) {
            console.error('Unknown error at MetaWorldManager.js fetchMinerPriceInfo Failed to fetch miner price info', e);
        }

        return result;
    }

    async fetchParcelPriceInfo (parcelCount) {
        let result = null;
        const paymentType = 10;
        try {
            const priceInfo = await this.contracts.Municipality.methods.getPriceForPurchaseParcels(parcelCount, paymentType).call();

            result = {
                priceForMintParcel: parseFloat(this.web3().utils.fromWei(priceInfo[0])),
                priceForBuyParcel: parseFloat(this.web3().utils.fromWei(priceInfo[1])),
            };
        } catch (e) {
            console.error('Failed to fetch parcel price info', e);
        }

        return result;
    }

    async fetchUserBalance (batchRequest = null) {
        let miners = 0;
        let parcels = 0;
        let upgrades = 0;
        const address = this.getWalletAddressForFetchInfo();
        if(batchRequest) {
            const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.contracts.Municipality.methods.usersMintableNFTAmounts(address).call,
                name: 'userBalance',
            }]);
            await Promise.all(promisesArray);
            ({miners, parcels, upgrades} = resolvedValues.userBalance);
        } else {
            ({miners, parcels, upgrades} = await this.contracts.Municipality.methods.usersMintableNFTAmounts(address).call());
        }
        MetaInventoryContainer.sharedInstance().setUserBalance({miners, parcels, upgrades});
    }

    async fetchUserPurchases (batchRequest = null) {
        let userPurchases = 0;
        const GS_METAVERSE = 1;
        const KB_METAVERSE = 2;
        const address = this.getWalletAddressForFetchInfo();

        if(batchRequest) {
            const { resolvedValues: resolvedValuesGS, promisesArray: promisesArrayGS } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.allContracts?.[GS_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call,
                name: 'userPurchases',
            }]);

            const { resolvedValues: resolvedValuesKB, promisesArray: promisesArrayKB } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.allContracts?.[KB_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call,
                name: 'userPurchases',
            }]);

            await Promise.all([...promisesArrayGS, ...promisesArrayKB]);

            userPurchases = parseFloat(this.web3().utils.fromWei(resolvedValuesGS.userPurchases)) + parseFloat(this.web3().utils.fromWei(resolvedValuesKB.userPurchases));
        } else {
            const results = await Promise.all([
                this.allContracts?.[GS_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call(),
                this.allContracts?.[KB_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call()
            ])
            userPurchases = parseFloat(this.web3().utils.fromWei(results[0])) + parseFloat(this.web3().utils.fromWei(results[1]));
        }
        GlobalMakerService.$store.commit('application/driver/UPDATE_AUTH_USER', {userPurchases});
    }

    setUserGoodsFetchingLoop () {

        if(this.fetchUserGoodsLoop) {
            this.stopUserGoodsFetchingLoop();
        }

        const fetchData = async () => {
            const batchRequest = new (this.readWeb3().BatchRequest)();
            const promises = [
                this.fetchUserCryptoBalance(batchRequest),
                this.fetchUserPurchases(batchRequest),
                this.fetchUserInventoryInfo(batchRequest),
                this.fetchUserBalance(batchRequest),
                this.fetchMinerRewardsData(batchRequest),
            ];
            batchRequest.execute();
            await Promise.all(promises);
            const userExists = MetaInventoryContainer.sharedInstance().authUserData;
            if(!userExists) {
                this.destroyData();
                this.stopUserGoodsFetchingLoop();
            }
        };
        fetchData();
        this.fetchUserGoodsLoop = setInterval(() => {
            const userExists = MetaInventoryContainer.sharedInstance().authUserData;
            if(!userExists) {
                this.stopUserGoodsFetchingLoop();
            } else {
                fetchData();
            }
        }, 5000);
    }

    stopUserGoodsFetchingLoop () {
        clearInterval(this.fetchUserGoodsLoop);
        this.fetchUserGoodsLoop = null;
    }

    async fetchUserInventoryInfo (batchRequest = null) {

        try {
            const walletAddress = this.getWalletAddressForFetchInfo();
            let parcelInfo = {};
            let minerInfo = {};
            let dualMiners = {};
            if(walletAddress) {
                const promisesArray = [
                    this.fetchParcelInfo(walletAddress, batchRequest),
                    this.fetchMinerInfo(walletAddress, batchRequest),
                    // GlobalMakerService.$store.dispatch('finances/minerRewards/getBitopexDualMiners', { walletAddress }),
                    // GlobalMakerService.$store.dispatch('finances/minerRewards/getBitopexDualMiners', { walletAddress }),
                    GlobalMakerService.$store.dispatch('finances/minerRewards/getFilecoinDualMiners', { walletAddress }),
                ];
                const results = await Promise.all(promisesArray);
                parcelInfo = results[0];
                minerInfo = results[1];
                dualMiners = {
                    dualMinersActive: results[2].dualMinersActive,
                    dualMinersInactive: results[2].dualMinersInactive,
                    dualMinersTotal: results[2].dualMinersTotal,
                    activeDualMinersForAllMetaverses: results[2].activeDualMinersForAllMetaverses,
                    dualMinersHashpower: results[2].dualMinersHashpower,
                    dualMinersHashpowerForAllMetaverses: results[2].dualMinersHashpowerForAllMetaverses,
                };
            }

            MetaInventoryContainer.sharedInstance().userInventoryInfo.setData({
                ...parcelInfo,
                ...minerInfo,
                ...dualMiners,
            });
        } catch (e) {
            console.error('MetaWorldManager.js fetchUserInventoryInfo', e);
        }

    }

    async fetchMinerInfo (address, batchRequest = null) {
        let totalHash = null;
        let freeHash = null;
        if(batchRequest) {
            const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.contracts.Municipality.methods.minerInf(address).call,
                name: 'minerInfo',
            }]);
            await Promise.all(promisesArray);
            ({ totalHash, freeHash } = resolvedValues.minerInfo);
        } else {
            ({ totalHash, freeHash } = await this.contracts.Municipality.methods.minerInf(address).call());
        }

        const totalMinersCount = totalHash / MINER_HASH_POWER;
        const freeMinersCount = freeHash / MINER_HASH_POWER;
        return {
            totalMinersCount,
            freeMinersCount,
            activeMinersCount: totalMinersCount - freeMinersCount,
            totalHash,
            freeHash,
        };
    }

    async fetchParcelInfo (address, batchRequest = null, metaverseId = null) {
        let freeSlots = null;
        let totalParcelsCount = null;
        let upgradedParcelsCount = null;
        let claimedOnMap = null;

        if (!metaverseId) {
            metaverseId = this.metaverseId;
        }

        const municipalityContract = new (this.readWeb3().eth.Contract)([{
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address",
                },
            ],
            "name": "parcelInf",
            "outputs": $config.chainId === 56 || metaverseId === METAVERSE_IDS.KABUTOCHO ? [
                {
                    "internalType": "uint256",
                    "name": "parcelCount",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "freeSlots",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "NumUpgraded",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "claimedOnMap",
                    "type": "uint256",
                },
            ] : [
                {
                    "internalType": "uint256",
                    "name": "parcelCount",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "totalSlots",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "freeSlots",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "NumUpgraded",
                    "type": "uint256",
                },
                {
                    "internalType": "uint256",
                    "name": "claimedOnMap",
                    "type": "uint256",
                },
            ],
            "stateMutability": "view",
            "type": "function",
        }], this.allContracts[metaverseId].Municipality.options.address);

        if(batchRequest) {

            const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [{
                method: municipalityContract.methods.parcelInf(address).call,
                // method: municipalityContract.methods.parcelInf(address).call, // TODO use Municiaplity contract from this.contracts after testnet redeployment
                name: 'parcelInfo',
            }]);
            await Promise.all(promisesArray);
            ({ freeSlots, parcelCount: totalParcelsCount, NumUpgraded: upgradedParcelsCount, claimedOnMap } = resolvedValues.parcelInfo);
        } else {
            ({ freeSlots, parcelCount: totalParcelsCount, NumUpgraded: upgradedParcelsCount, claimedOnMap } = await municipalityContract.methods.parcelInf(address).call());
        }

        const upgradedSlots = upgradedParcelsCount * PARCEL_UPGRADED_MINERS_COUNT;
        const totalSlots = (totalParcelsCount - upgradedParcelsCount) * PARCEL_MINERS_COUNT + upgradedSlots;

        return {
            totalParcelsCount,
            standardParcelsCount: totalParcelsCount - upgradedParcelsCount,
            upgradedParcelsCount,
            totalSlots,
            freeSlots,
            upgradedSlots,
            standardSlots: totalSlots - upgradedSlots,
            claimedOnMap,
        };
    }

    convertGYMNETtoUSD (val, tokenPrice) {
        return parseFloat(this.web3().utils.fromWei(val)) * tokenPrice;
    }

    async readMinerDataFromBlockchain (userWalletAddress = null, batchRequest = null) {
        const batchRequestInternal = batchRequest || new (this.web3().BatchRequest)();

        const requests = [];

        if(userWalletAddress) {
            const isSwitchedToGymnet = {method: this.contracts.MiningSC.methods.switchedToNewMinting(userWalletAddress).call, name: 'isSwitchedToGymnet'};
            requests.push(isSwitchedToGymnet);
        }
        const rewardPerBlockGYMNET = {method: this.contracts.MiningSC.methods.rewardPerBlock().call, name: 'rewardPerBlockGYMNET'};
        const totalMintedRewards = {method: this.contracts.MiningSC.methods.totalMintedTokens().call, name: 'totalMintedRewards'};
        const minerRewardsUtilityInfo = {method: this.contracts.MiningSC.methods.utilInfo(userWalletAddress).call, name: 'utilInfo'};
        const minerRewardsData = {method: this.contracts.GymAgregator.methods.getUserMinerRewards(userWalletAddress || NULLABLE_WALLET_ADDRESS).call, name: 'minerRewardsData'};
        requests.push(rewardPerBlockGYMNET, totalMintedRewards, minerRewardsData, minerRewardsUtilityInfo);

        const {resolvedValues: result, promisesArray} = addAndPromisifyBatchCalls(batchRequestInternal, requests);


        if(!batchRequest) {
            batchRequestInternal.execute();
        }

        await Promise.all(promisesArray);

        result.userTotalMiners = result.minerRewardsData[0];
        result.userTotalHashPower = result.minerRewardsData[1];
        result.totalClaims = result.minerRewardsData[2];
        result.totalPendingRewardsInGYMNETWEI = result.minerRewardsData[3];
        result.globalHashpower = result.minerRewardsData[4];
        result.userActiveMiners = Number(result.minerRewardsData[5]) / MINER_HASH_POWER;
        result.tokenPrice = result.minerRewardsData[6];
        return result;
    }

    async fetchMinerRewardsData (batchRequest = null) {
        let result = null;
        try {
            const userWalletAddress = this.getWalletAddressForFetchInfo();

            const resolvedValues = await this.readMinerDataFromBlockchain(userWalletAddress, batchRequest);

            MetaInventoryContainer.sharedInstance().setMinerRewardsData(null);

            let userTotalMiners = 0;
            let userActiveMiners = 0;
            let isSwitchedToGymnet = false;
            let totalClaims = 0;
            let totalPendingRewardsInGYMNETWEI = '0';
            let minerRewardsUtilityInfo = null;
            if(userWalletAddress) {
                userTotalMiners = parseFloat(resolvedValues.userTotalMiners);
                userActiveMiners = parseFloat(resolvedValues.userActiveMiners);
                isSwitchedToGymnet = resolvedValues.isSwitchedToGymnet;
                totalClaims = parseFloat(this.web3().utils.fromWei(resolvedValues.totalClaims));
                totalPendingRewardsInGYMNETWEI = resolvedValues.totalPendingRewardsInGYMNETWEI;
                minerRewardsUtilityInfo = resolvedValues.utilInfo;
                minerRewardsUtilityInfo.realAvailable = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.available));
                minerRewardsUtilityInfo.available = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.available)) + parseFloat(this.web3().utils.fromWei(totalPendingRewardsInGYMNETWEI));
                minerRewardsUtilityInfo.available = this.web3().utils.toWei(minerRewardsUtilityInfo.available.toString());
                minerRewardsUtilityInfo.total = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.total)) + parseFloat(this.web3().utils.fromWei(totalPendingRewardsInGYMNETWEI));
                minerRewardsUtilityInfo.total = this.web3().utils.toWei(minerRewardsUtilityInfo.total.toString());
                minerRewardsUtilityInfo.usedBal = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.usedBal));
                minerRewardsUtilityInfo.usedBal = this.web3().utils.toWei(minerRewardsUtilityInfo.usedBal.toString());
            }

            const rewardPerBlockGYMNET = parseFloat(this.web3().utils.fromWei(resolvedValues.rewardPerBlockGYMNET));
            const tokenPrice = parseFloat(this.web3().utils.fromWei(resolvedValues.tokenPrice));
            const rewardPerBlockUSD = this.convertGYMNETtoUSD(resolvedValues.rewardPerBlockGYMNET, tokenPrice);
            const totalMintedRewards = parseFloat(this.web3().utils.fromWei(resolvedValues.totalMintedRewards));
            const maxSupply = 270000000;
            // maxSupply = parseFloat(this.web3().utils.fromWei(await this.contracts.GymNetwork.methods.MAX_SUPPLY().call()));

            const dailyBlocks = 28800;
            const globalHashpower = parseFloat(resolvedValues.globalHashpower);
            const dailyRewards = rewardPerBlockUSD * dailyBlocks * userActiveMiners * MINER_HASH_POWER / globalHashpower;
            const yearlyRewards = 365 * dailyRewards;
            const roi = (userActiveMiners) ? (yearlyRewards * 100) / (userActiveMiners * MINER_UNIT_PRICE) : 0;

            result = {
                isSwitchedToGymnet,
                minerRewards: {
                    userMiners: {
                        userActiveMiners,
                        userTotalMiners,
                    },
                    myHashpower: userActiveMiners * MINER_HASH_POWER,
                    totalClaims,
                    totalPendingRewards: {
                        pendingMinerRewards: parseFloat(this.web3().utils.fromWei(totalPendingRewardsInGYMNETWEI)),
                        usdPrice: this.convertGYMNETtoUSD(totalPendingRewardsInGYMNETWEI === '0' ? '1' : totalPendingRewardsInGYMNETWEI, tokenPrice),
                    },
                    dailyRewards,
                    weeklyRewards: 7 * dailyRewards,
                    monthlyRewards: 30 * dailyRewards,
                    yearlyRewards,
                    yearlyRewardsToken: 365 * rewardPerBlockGYMNET * dailyBlocks * userActiveMiners * MINER_HASH_POWER / globalHashpower,
                    roi,
                    minerUnitPrice: MINER_UNIT_PRICE,
                    minerRewardsUtilityInfo,
                },
                globalStatistics: {
                    rewardPerBlockGYMNET,
                    globalHashpower,
                    userShares: (userActiveMiners * MINER_HASH_POWER * 100) / globalHashpower,
                    dailyGlobalRewards: rewardPerBlockGYMNET * dailyBlocks,
                    price: tokenPrice,
                    totalMinted: {
                        totalMintedRewards,
                        maxSupply,
                        totalMintedRewardsPercentage: 100 * totalMintedRewards / maxSupply,
                    },
                },
            };

        } catch (e) {
            console.error('MetaWorldManager.js fetchMinerRewardsData', e);
        }

        MetaInventoryContainer.sharedInstance().setMinerRewardsData(result);
    }

    async registerUMUser ({oldAddress, newAddress, partners, salt, signature}) {
        try {
            const userBalance = await this.contracts.USDT.methods.balanceOf(oldAddress).call();
            const weiBalance = this.web3().utils.fromWei(userBalance);
            const balance = parseFloat(weiBalance);
            const payValue = balance > 0 ? '160000000000000' : '0';
            await this.writeContracts.SignatureValidatorUM.methods.seedDataFromBack([[newAddress, oldAddress, partners, salt],signature]).send({
                value: payValue,
                from: newAddress,
            });
        } catch (e) {
            throw new Error("Something went wrong while interaction with blockchain");
        }
    }

    async claimAllRewards () {
        try {
            this.showLoading();
            const transactionAllowance = await this.assertWallet();

            if (!transactionAllowance.isAllowed) return;

            const readWeb3 = this.readWeb3();
            const gasPrice = await readWeb3.eth.getGasPrice();
            const tx = this.writeContracts.MiningSC.methods.claimAll();
            const estimatedGas = await tx.estimateGas({
                from: transactionAllowance.userWallet,
            });
            await tx.send({
                from: transactionAllowance.userWallet,
                gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
                gasPrice,
            });

            PopupHelper.showSuccessNotification(TranslationHelper.translate('Transaction Success'));

            return true;
        } catch(e) {
            switch (e.code) {
                case WALLET_ERROR_CODES.USER_REJECTED:
                    throw new TxRejectedByUserException();
                case WALLET_ERROR_CODES.METAMASK_INTERNAL_ERROR:
                    PopupHelper.showInfoAlert(TranslationHelper.translate('Transaction in Process'), TranslationHelper.translate('Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.'));
                    return;
                default:
                    throw e;
            }
        } finally {
            this.hideLoading();
        }
    }

    async onLogin () {}

    async attachMinersToParcels (countToAttach) {
        diagLog(this, 'attachMinersToParcels triggered', {countToAttach});

        const mic = MetaInventoryContainer.sharedInstance();
        if (!countToAttach) {
            countToAttach = mic.userInventoryInfo.getInactiveMinersCount();
        }

        const hashpower = countToAttach * MINER_HASH_POWER;

        return await this.approveMunicipalityTransaction({
            price: 0,
            purchaseName: 'Attach miners',
            method: 'attachMiners',
            params: [hashpower],
        });
    }

    async web3MintMiners (minerData) {
        if ($config.isBuyDisabled) {
            PopupHelper.showErrorAlert('Purchases are disabled, please wait for them to be enabled againa');
            return;
        }
        const user = MetaInventoryContainer.sharedInstance().authUserData;
        const referralId = await user.getReferrerCode();
        const walletAddress = user.walletAddress ?? user.relatedWalletAddress;
        diagLog(this, `Will purchase ${minerData.count} miners for ${minerData.price} USDT`);
        diagLog(this, 'REFERRER_CODE', referralId);
        const price = minerData.price;
        let purchaseName = 'Dual Miners';
        if (minerData.count === 0) {
            purchaseName = 'Claim NFT Balance';
        }
        return await this.approveMunicipalityTransaction({
            price,
            purchaseName,
            method: 'web3MintMiners',
            params: [minerData.count, referralId, walletAddress, false],
            product_type: PRODUCT_TYPES.MINER,
            quantity: minerData.count,
        });
    }

    async web3MintParcels (parcelData, isCalledFromMap = false) {
        if ($config.isBuyDisabled) {
            PopupHelper.showErrorAlert('Purchases are disabled, please wait for them to be enabled again');
            return;
        }
        const user = MetaInventoryContainer.sharedInstance().authUserData;
        const referralId = await user.getReferrerCode();
        const walletAddress = user.walletAddress ?? user.relatedWalletAddress;
        const paymentTypeMethod = PaymentType.REGULAR;
        diagLog(this, `Will purchase ${parcelData.count} parcels for ${parcelData.price} USDT`);
        diagLog(this, 'REFERRER_CODE', referralId);
        const price = parcelData.price;
        let purchaseName = 'Parcels';
        if(parcelData.count === 0) {
            purchaseName = 'Claim NFT Balance';
        }
        return await this.approveMunicipalityTransaction({
            price,
            purchaseName,
            method: 'web3MintParcels',
            params: [parcelData.count, referralId, walletAddress, paymentTypeMethod, false],
            product_type: PRODUCT_TYPES.PARCEL,
            quantity: parcelData.count,
            selectedToken: parcelData.selectedToken ? parcelData.selectedToken : USDT_UTILITY_SWITCHER.USDT
        }, {
            isCalledFromMap,
        });
    }

    async upgradeSelectedParcels (parcelsCount, price = 0) {
        if ($config.isBuyDisabled) {
            PopupHelper.showErrorAlert('Purchases are disabled, please wait for them to be enabled againa');
            return;
        }
        diagLog(this, 'upgradeSelectedParcels with PARCELSDATA:', {parcelsCount});

        const user = MetaInventoryContainer.sharedInstance().authUserData;
        const referralId = await user.getReferrerCode();
        const userWalletAddress = user.walletAddress || user.relatedWalletAddress;

        let purchaseName = 'Upgrade parcels';
        if(parcelsCount === 0) {
            purchaseName = 'Claim NFT Balance';
        }

        return await this.approveMunicipalityTransaction({
            price,
            purchaseName,
            method: 'upgradeStandardParcelsGroup',
            params: [0, referralId, userWalletAddress, PaymentType.REGULAR, false],
        });
    }

    async purchaseBundle (payload) {
        if ($config.isBuyDisabled) {
            PopupHelper.showErrorAlert('Purchases are disabled, please wait for them to be enabled againa');
            return;
        }
        let methodName = null;
        if (payload.smartContractId <= BUNDLE_TYPE_PARCELS_MINERS_MAX) {
            methodName = 'web3PurchaseBasicBundle';
        } else if (payload.smartContractId <= BUNDLE_TYPE_PARCELS_MAX) {
            methodName = 'web3PurchaseParcelsBundle';
        } else if (payload.smartContractId <= BUNDLE_TYPE_MINERS_MAX) {
            methodName = 'web3PurchaseMinersBundle';
        } else if (payload.isStarterBundle()) {
            methodName = 'web3PurchaseStarterBundle';
        } else if (payload.isSuperBundle()) {
            methodName = 'web3PurchaseSuperBundle';
        } else if (payload.isCashFtBundle) {
            methodName = 'web3PurchaseCardBundle';
        }
        if (!methodName) {
            throw new Error(`Cannot determine contract method to call`);
        }
        const user = MetaInventoryContainer.sharedInstance().authUserData;
        const referralId = await user.getReferrerCode();
        const walletAddress = user.walletAddress ?? user.relatedWalletAddress;
        const paymentTypeMethod = PaymentType.REGULAR;
        const params = [payload.smartContractId, referralId, walletAddress, paymentTypeMethod, false];
        if(payload.isOnlyMinerBundle() || payload.isCashFtBundle) {
            params.splice(3, 1);
        } else if (payload.isStarterBundle()) {
            params.splice(0, 1);
            params.splice(2, 1);
        }
        return await this.approveMunicipalityTransaction({
            purchaseName: payload.name,
            price: payload.discountedPrice,
            method: methodName,
            params,
            product_type: PRODUCT_TYPES.BUNDLE,
            bundle_sc_id: payload.smartContractId,
            bundleData: {
                numberOfParcels: payload.numberOfParcels,
            },
            selectedToken: payload.selectedToken ? payload.selectedToken : USDT_UTILITY_SWITCHER.USDT,
            benefitsCommissionRank: payload.benefitsCommissionRank,
        });
    }

    async getSponsorInfo (referralId = null) {
        // TODO: do we need it?
        let result = {
            code: 'xkyqk',
            wallet: '0x8169319270f1a82334822fc0190A047F5C7226a5',
        };

        return result; // TODO temporary solution for purchases to work

        if (referralId) {
            referralId = referralId.trim().split('/')[0];
            try {
                const decodedId = HashIdService.decode(referralId);
                const wallet = await this.contracts.MLM.methods.idToAddress(decodedId).call();
                if (wallet) {
                    result = {
                        code: referralId,
                        wallet,
                    };
                } else {
                    throw new Error('Invalid referral ID');
                }
            } catch (e) {
                result = null;
                console.error('MetaWorldManager getSponsorInfo', e); // TODO check error handling
            }
        }

        return result;
    }

    async validateIsCredentialExists ({email, wallet_address, username}) {
        let isExists;
        try {
            const isExistsRes = await ApiService.query('user/check-user-exists', {params: {email, wallet_address, username}});
            if (isExistsRes && isExistsRes.status === 200) {
                isExists = isExistsRes.data.exists;
            } else {
                throw new Error('Existing email');
            }
        } catch (e) {
            isExists = false;
            console.error('MetaWorldManager validateIsCredentialExists'); // TODO check error handling
        }

        return isExists;
    }

    async isUserHasPayment () {
        let canChange = false;
        try {
            ApiService.setHeader();
            const isUserHasPayment = await ApiService.get('user/can-change-related-wallet');
            if (isUserHasPayment && isUserHasPayment.status === 200) {
                canChange = !isUserHasPayment.data.can_change;
            } else {
                console.error('MetaWorldManager isUserHasPayment', isUserHasPayment); // TODO check error handling
            }
        } catch (e) {
            console.error('MetaWorldManager isUserHasPayment', e);
        }
        diagLog(this, {'isUserHasPayment': canChange});

        return canChange;
    }

    async fetchUserSponsorWalletByReferralId (ref) {
        const res = await ApiService.query('user/get-wallet-by-ref', {params: {referral_id: ref}});

        if (res && res.status === 200) {
            return res.data.wallet_address;
        }
        return null;
    }

    async getAllowance (contractName) {
        let allowance = 0;
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const userAddress = authUser?.walletAddress;
        if(userAddress) {
            allowance = parseFloat(
                this.web3().utils.fromWei(
                    await this.contracts.USDT.methods.allowance(userAddress, this.contracts[contractName]._address).call(),
                ),
            );
        }
        return allowance;
    }

    async setupAllowance (price, spenderContract = 'Municipality') {
        const mic = MetaInventoryContainer.sharedInstance();
        const authUser = mic.authUserData;
        if (!authUser.isFullyWeb2User) {
            const allowance = await this.getAllowance(spenderContract);
            diagLog(this, 'ALLOWANCE IS', allowance);
            const userAddress = authUser?.walletAddress;

            if(allowance <= price) {
                const spenderAddress = this.contracts[spenderContract]._address;
                const requestPrice = this.web3().utils.toWei(String(price));
                try {
                    const readWeb3 = this.readWeb3();
                    const gasPrice = await readWeb3.eth.getGasPrice();
                    const tx = this.writeContracts.USDT.methods.approve(spenderAddress, requestPrice);
                    const estimatedGas = await tx.estimateGas({
                        from: userAddress,
                    });
                    await tx.send({
                        from: userAddress,
                        gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
                        gasPrice,
                    });
                    return true;
                } catch(error) {
                    // TODO Add error handling
                    console.error('MetaWorldManager.js setupAllowance', error); // TODO check error handling
                    alert(error.message);
                    throw error;
                }
            } else {
                return true;
            }
        } else {
            const allowance = await GlobalMakerService.$store.dispatch('application/driver/checkAllowance', {
                tokenAddress: this.contracts.USDT._address,
                spenderAddress: this.contracts[spenderContract]._address,
            });
            if(allowance) {
                diagLog(this, 'Web2 user ALLOWANCE IS SET');
                return true;
            } else {
                await GlobalMakerService.$store.dispatch('application/popup-control/showWeb2AllowancePopup', true);

                await GlobalMakerService.$store.dispatch('application/popup-control/web2AllowancePopupData', {
                    show: true,
                    contractName: 'USDT',
                    contractAddress: this.contracts.USDT._address,
                    spenderAddress: this.contracts[spenderContract]._address,
                });
                return false;
            }
        }

    }

    async getMunicipalityTransactionAllowanceInfo () {
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        let currentWallet;
        if(!authUser.isFullyWeb2User) {
            try {
                currentWallet = await GlobalMakerService.$store.dispatch('auth/getCurrentWallet'); // TODO check getCurrentWallet function
            } catch (e) {
                console.warn('MetaWorldManager getMunicipalityTransactionAllowanceInfo pending user confirmation', e);
            }
        }
        const result = {
            isAllowed: authUser.isFullyWeb2User || authUser?.walletAddress && authUser.walletAddress?.toLowerCase() === currentWallet?.toLowerCase(),
            userWallet: authUser?.walletAddress,
            currentWallet,
        };
        diagLog(this, 'allowanceInfo' , result);
        return result;
    }

    async approveMunicipalityTransaction (txData, { isCalledFromMap = false } = {}) {
        // eslint-disable-next-line camelcase
        const { price, method, params, purchaseName, product_type, quantity, bundle_sc_id, bundleData, selectedToken, benefitsCommissionRank } = txData;
        diagLog(this, 'approveMunicipalityTransaction method params', { price, method, params });
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const methods = [
            'web3MintMiners',
            'web3MintParcels',
            'web3PurchaseSuperBundle',
            'web3PurchaseBasicBundle',
            'web3PurchaseParcelsBundle',
            'web3PurchaseMinersBundle',
            'web3PurchaseStarterBundle',
            'web3PurchaseCardBundle',
        ];
        const contract = 'Municipality';
        let response;
        if (!process.env.IS_PAYMENT_ALLOWED_ONLY_FOR_WEB3 &&
            authUser && methods.includes(method) &&
            price > 0) {
            let infoTexts;
            let canBuyFor;
            if (method === 'web3MintParcels') {
                canBuyFor = (forMeOrAddress, fromAddress) => {
                    if (typeof forMeOrAddress === 'boolean' && forMeOrAddress ||
                        typeof forMeOrAddress === 'string' && forMeOrAddress.toLowerCase() === authUser.walletAddress.toLowerCase()) {
                        return true;
                    }
                    return !isCalledFromMap || forMeOrAddress.toLowerCase?.() === authUser.walletAddress.toLowerCase();
                };
            } else if (method === 'web3PurchaseStarterBundle') {
                canBuyFor = async (forMeOrAddress, fromAddress) => {
                    if (typeof forMeOrAddress === 'boolean' && forMeOrAddress) {
                        const result = await this.contracts.Municipality.methods.starterBundleCheck(authUser.walletAddress).call();
                        return !result;
                    }
                    if (typeof forMeOrAddress === 'boolean' && !forMeOrAddress) {
                        return true;
                    }
                    const result = await this.contracts.Municipality.methods.starterBundleCheck(forMeOrAddress).call();
                    if (result) {
                        throw new Error('The user already owns this bundle. Please choose a different bundle to proceed.');
                    }
                    return !result;
                };
                infoTexts = [
                    this.metaverseId === METAVERSE_IDS.GYMSTREET && {
                        text: 'Ownership of this bundle is limited to one per user. Please purchase for yourself and for those who do not have it.',
                        step: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
                    },
                ];
            } else if (method === 'web3PurchaseCardBundle') {
                canBuyFor = async (forMeOrAddress, fromAddress) => {
                    if (typeof forMeOrAddress === 'boolean' && forMeOrAddress) {
                        return true;
                    }

                    if (typeof forMeOrAddress === 'boolean' && !forMeOrAddress) {
                        return false;
                    }

                    const isBuyerWalletIdenticalToCurrentUsers = fromAddress.toLowerCase() === authUser.walletAddress.toLowerCase();

                    if (!isBuyerWalletIdenticalToCurrentUsers) {
                        throw new Error('This bundle must be purchased with a wallet that is connected to current account.');
                    }

                    return isBuyerWalletIdenticalToCurrentUsers &&
                           (!forMeOrAddress || authUser.walletAddress.toLowerCase() === forMeOrAddress.toLowerCase());
                };
                infoTexts = [
                    {
                        text: 'This bundle cannot be purchased for others. Please purchase for yourself.',
                        step: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
                    },
                ];
            }
            response = await this.startPurchase({
                purchaseName,
                price,
                method,
                params,
                // eslint-disable-next-line camelcase
                product_type,
                quantity,
                // eslint-disable-next-line camelcase
                bundle_sc_id,
                bundleData,
                canBuyFor,
                infoTexts,
                contract,
                selectedToken,
                benefitsCommissionRank,
                isCalledFromMap,
            });
        } else {
            const contractMethod = method === 'upgradeStandardParcelsGroup' ? 'web3MintParcels' : method;
            response = await this.handleMunicipalityTransaction(price, contractMethod, contract, {
                title: purchaseName,
                // eslint-disable-next-line camelcase
                bundle_sc_id,
            }, ...params);
        }

        if (!authUser.isUsedFastStartIncentive &&
            new Date() < new Date(authUser.fastStartIncentiveDate) &&
            Number(price || 0) + Number(authUser.totalOwnPurchaseAmount || 0) >= 100) {
            GlobalMakerService.$store.dispatch('user/subscription/updateUserSubscriptionExpires', null, {root: true});
            GlobalMakerService.$store.dispatch('user/subscription/updateFrozenAutoship', null, {root: true});
        }

        if (this.municipalityEventsToSendToGA().includes(method)) {
            const productQuantity = quantity || 1;
            let eventType = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS_TRANSACTION;
            let eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS;
            const abandonmentStep = GlobalMakerService.$store.state.application['purchase-control'].finishStep;
            if (response === FinishReasonEnum.FAILED) {
                eventType = GOOGLE_ANALYTICS_EVENT_TYPES.FAIL_TRANSACTION;
                eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.FAILED + ` at step ${abandonmentStep}`;
            } else if (response === FinishReasonEnum.CLOSED_BY_USER) {
                eventType = GOOGLE_ANALYTICS_EVENT_TYPES.USER_CANCEL;
                GlobalMakerService.$store.dispatch('application/popup-control/showWeb2Popup', false);
                eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.CANCEL + ` at step ${abandonmentStep}`;
            }
            this.sendMunicipalityApprovalEvent(method, txData, eventType, eventNameModifier, productQuantity);
        }

        return response;

    }

    async assertWallet () {
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const transactionAllowance = await this.getMunicipalityTransactionAllowanceInfo();
        if (!transactionAllowance.isAllowed) {
            if (transactionAllowance.currentWallet) {
                const text = `<div class="attached-wallet-alert">${TranslationHelper.translate("Your attached wallet is {walletAddress}. You have tried to make request with wallet {currentWallet}. Please change connected wallet to {walletAddress}", {
                    walletAddress: getHidedAccountAddress(authUser.walletAddress),
                    currentWallet: getHidedAccountAddress(transactionAllowance.currentWallet),
                })}</div>`
                PopupHelper.showInfoAlert('', text, true)
            } else {
                PopupHelper.showInfoAlert("", TranslationHelper.translate("Web3 wallet not connected. Please connect to proceed."))
            }
        }
        return transactionAllowance;
    }

    async handleMunicipalityTransaction (price, method, contractName, additionalData, ...params) {
        diagLog(this, "handleMunicipalityTransaction method params", {price, method, additionalData, params});
        const transactionAllowance = await this.assertWallet();

        if (!transactionAllowance.isAllowed) return;

        const balanceInWei = await this.contracts.USDT.methods.balanceOf(transactionAllowance.userWallet).call();
        const balanceInEth = this.web3().utils.fromWei(balanceInWei);
        const busdBalance = parseFloat(balanceInEth);
        if (busdBalance < price) {
            this.hideLoading();
            throw new InsufficientFundsError(`Insufficient USDT amount`);
        } else {
            this.showLoading();

            try {
                let allowance = true;
                if (price > 0) {
                    allowance = await this.setupAllowance(Number(price) + 1, contractName);
                }
                const mic = MetaInventoryContainer.sharedInstance();
                if (!mic.authUserData.isFullyWeb2User) {
                    const readWeb3 = this.readWeb3();
                    const gasPrice = await readWeb3.eth.getGasPrice();
                    const tx = this.writeContracts[contractName].methods[method](...params);

                    const estimatedGas = await tx.estimateGas({
                        from: transactionAllowance.userWallet,
                    });

                    const result = await tx.send({
                        from: transactionAllowance.userWallet,
                        gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
                        gasPrice,
                    });
                    diagLog(this, "handleMunicipalityTransaction result", result);
                    return FinishReasonEnum.SUCCESS;
                } else {
                    await GlobalMakerService.$store.dispatch('application/popup-control/web2TransactionPopupData', {
                        show: true,
                        title: additionalData.title,
                        contractName,
                        method,
                        amount: price,
                        transactionArguments: params,
                        busdBalance,
                    });

                    if (allowance) {
                        await GlobalMakerService.$store.dispatch('application/popup-control/showWeb2Popup', true);
                    }

                    return new Promise((resolve) => {
                        // make watcher that wait isFinished and return to flow
                        const unwatch = GlobalMakerService.$store.watch(
                            (_, getters) => {
                                return getters['application/popup-control/isWeb2ClaimPopupVisible'];
                            },
                            () => {
                                unwatch();
                                const txSuccess = GlobalMakerService.$store.state.application.driver.isSendTxSuccess;
                                if(txSuccess === -1) {
                                    resolve(FinishReasonEnum.CLOSED_BY_USER);
                                } else if (txSuccess) {
                                    resolve(FinishReasonEnum.SUCCESS);
                                } else {
                                    resolve(FinishReasonEnum.FAILED);
                                }
                            },
                        );
                    });
                }
            } catch (e) {
                if (e.code !== WALLET_ERROR_CODES.METAMASK_INTERNAL_ERROR &&
                    e.code !== WALLET_ERROR_CODES.USER_REJECTED &&
                    e.code !== WALLET_ERROR_CODES.USER_DISAPPROVED_REQUESTED_METHOD) { // TODO check why this alert is needed
                    alert(e.message);
                }
                let eventType = GOOGLE_ANALYTICS_EVENT_TYPES.FAIL_TRANSACTION;
                let eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.FAILED;
                if (e.code === WALLET_ERROR_CODES.USER_REJECTED ||
                    e.code === WALLET_ERROR_CODES.USER_DISAPPROVED_REQUESTED_METHOD) {
                    eventType = GOOGLE_ANALYTICS_EVENT_TYPES.USER_CANCEL;
                    eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.CANCEL + ` at approval popup`;
                }
                this.sendMunicipalityApprovalEvent(
                    method,
                    { purchase_price: price, purchase_name: additionalData?.title, bundle_sc_id: additionalData?.bundle_sc_id },
                    eventType,
                    eventNameModifier,
                );
                switch (e.code) {
                    case WALLET_ERROR_CODES.USER_DISAPPROVED_REQUESTED_METHOD:
                    case WALLET_ERROR_CODES.USER_REJECTED:
                        throw new TxRejectedByUserException();
                    case WALLET_ERROR_CODES.METAMASK_INTERNAL_ERROR:
                        PopupHelper.showInfoAlert(TranslationHelper.translate('Transaction in Process'), TranslationHelper.translate('Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.'));
                        return;
                    default:
                        throw e;
                }
            } finally {
                this.hideLoading();
            }
        }
    }

    municipalityEventsToSendToGA () {
        return [
            'web3MintMiners',
            'web3MintParcels',
            'web3PurchaseBasicBundle',
            'web3PurchaseParcelsBundle',
            'web3PurchaseMinersBundle',
            'web3PurchaseSuperBundle',
            'web3PurchaseStarterBundle',
        ];
    }

    sendMunicipalityApprovalEvent (
        method,
        data,
        GAEventType = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS_TRANSACTION,
        purchaseNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS,
        productQuantity = 1
    ) {
        let eventName = null;
        // eslint-disable-next-line camelcase
        const purchase_name = [ `(${purchaseNameModifier})`, data.purchaseName].join(' ');
        const purchase_price = Number(data.price) / productQuantity;

        switch(method) {
            case 'web3MintParcels':
                eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_PARCEL, GAEventType);
                break;
            case 'web3MintMiners':
                eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_MINER, GAEventType);
                break;
            case 'web3PurchaseBasicBundle':
            case 'web3PurchaseParcelsBundle':
            case 'web3PurchaseMinersBundle':
                eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_BUNDLE, GAEventType);
                break;
            case 'web3PurchaseSuperBundle':
                eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_SUPER_BUNDLE, GAEventType);
                break;
            case 'web3PurchaseStarterBundle':
                eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_STARTER_BUNDLE, GAEventType);
                break;
        }
        if(eventName) {
            let transaction_id = `P-${String(new Date().valueOf())}`;
            let eventData = {
                transaction_id,
                value: Number(data.price),
                purchase_name: data.purchaseName,
                currency: "USD",
                items: [{
                    item_name: data.purchaseName,
                    quantity: productQuantity,
                    price: purchase_price
                }]
            };
            try {
                if (purchaseNameModifier === GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS) {
                    GlobalMakerService.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.PURCHASE, eventData);
                }
                GlobalMakerService.$gtag.event(eventName, {
                    transaction_id,
                    purchase_name,
                    purchase_price: Number(data.price),
                });
            } catch(e) {
                console.warn('>>>>>>>>>>>> Probably no $gtag found <<<<<<<<<<<<<<<', e);
            }
        } else {
            console.warn('No municipality event found for method: ', method);
        }
    }

    setWeb3Properties (web3, readWeb3, contracts, write, ethereum, chainId, address) {
        this.web3 = web3;
        this.readWeb3 = readWeb3;
        this.allContracts = contracts;
        this.allWriteContracts = write;
        this.ethereum = ethereum;
        this.chainId = chainId;
        this.address = address;
    }

    setMetaverseId (metaverseId) {
        this.metaverseId = metaverseId;
    }

    getWeb3Contract (contractName) {
        return this.contracts[contractName];
    }

    setMenuSizeGetters (leftSideSizeGetter, headerSizeGetter) {
        this.leftSideSizeGetter = leftSideSizeGetter;
        this.headerSizeGetter = headerSizeGetter;
    }

    showLoading (title = null, message = null) {
        GlobalMakerService.$store.dispatch('application/popup-control/showLoadingPopup', {
            title: title || 'Please Wait',
            message,
            showIcon: true,
            type: 'success',
        });
    }

    hideLoading () {
        GlobalMakerService.$store.dispatch('application/popup-control/hideLoadingPopup');
    }

    showFailureMessage (message = 'Something Went Wrong') {
        PopupHelper.showErrorAlert(TranslationHelper.translate(message));
    }

    showSuccessPopup (message = 'Transaction Success') {
        PopupHelper.showSuccessNotification(TranslationHelper.translate(message));
    }

    async getBundlesDataFromContract(walletAddress) {
        let bundlesData = this.bundlesCache.get(this.metaverseId);
        if(!bundlesData) {
            bundlesData = await this.contracts.Municipality.methods.getBundles(walletAddress).call();
            this.bundlesCache.set(this.metaverseId, bundlesData);
        }
        return bundlesData;
    }

    destroyData () {
        MetaInventoryContainer.sharedInstance().destroyUserInventoryData();
        MetaNotificationsContainer.sharedInstance().emptyNotifications();
        MetaNotificationsContainer.sharedInstance().emptyNewNotifications();
    }

    // eslint-disable-next-line camelcase
    startPurchase (
        {
            purchaseName,
            price,
            method,
            params,
            product_type,
            quantity,
            bundle_sc_id,
            bundleData = {},
            canBuyFor,
            infoTexts,
            contract,
            selectedToken = USDT_UTILITY_SWITCHER.USDT,
            benefitsCommissionRank,
            isCalledFromMap,
        }
    ) {
        GlobalMakerService.$store.dispatch('application/purchase-control/show', {
            price,
            method,
            params,
            purchaseName,
            // eslint-disable-next-line camelcase
            product_type,
            quantity,
            // eslint-disable-next-line camelcase
            bundle_sc_id,
            bundleData,
            canBuyFor,
            infoTexts,
            contract,
            selectedToken,
            benefitsCommissionRank,
            isCalledFromMap,
        });
        return new Promise((resolve) => {
            // make watcher that wait isFinished and return to flow
            const unwatch = GlobalMakerService.$store.watch(
                (_, getters) => {
                    return getters['application/purchase-control/isFinished'];
                },
                () => {
                    unwatch();
                    const finishReason = GlobalMakerService.$store.state.application['purchase-control'].finishReason;
                    resolve(finishReason);
                },
                {
                    deep: true,
                },
            );
        });
    }
}

MetaWorldManager.instance = null;
