import { MapBuilder } from "./MapBuilder";
import { MapBoundariesStep } from "./map-creation-steps/MapBoundariesStep";
import { MapCreationStep } from "./map-creation-steps/MapCreationStep";
import { MapOwnedParcelsStep } from "./map-creation-steps/MapOwnedParcelsStep";
import { MapReservedAreaSpritesStep } from "./map-creation-steps/MapReservedAreaSpritesStep";
import { MapReservedAreasStep } from "./map-creation-steps/MapReservedAreasStep";
import { MetaUser } from "~/core/models/MetaUser";
import { MetaParcel } from "~/core/services/map2/models/MetaParcel";
import { ParcelOwner } from "./models/ParcelOwner";

export class MetaMapDirector {
    public mapBuilder: MapBuilder;
    public mapCreationFlow: MapCreationStep;

    constructor (
        public readonly metaverseId: number,
        public readonly config: { reservedAreaSpritesHost: string, reservedAreaSpritesPath: string },
        private user: MetaUser | null = null,
    ) {
        this.mapBuilder = new MapBuilder();
        this.createMapCreationFlow();
    }

    public getOwnerById(userId: number = this.user?.id): ParcelOwner | undefined {
        return this.mapBuilder.getOwnerById(userId);
    }

    public getOwnedParcelsOfUser (userId: number = this.user?.id): MetaParcel[] {
        const owner = this.getOwnerById(userId);
        return owner?.ownedParcels || [];
    }

    private createMapCreationFlow () {
        const mapBuilder = this.mapBuilder;
        const metaverseId = this.metaverseId;
        const ownedParcelsStep = new MapOwnedParcelsStep(metaverseId, mapBuilder, null, () => this.user?.id);
        const reservedAreasStep = new MapReservedAreasStep(metaverseId, mapBuilder, ownedParcelsStep);
        const boundariesStep = new MapBoundariesStep(metaverseId, mapBuilder, reservedAreasStep);
        const reservedAreasSpritesStep = new MapReservedAreaSpritesStep(metaverseId, this.config, mapBuilder, boundariesStep);
        this.mapCreationFlow = reservedAreasSpritesStep;
    }

    public constructMap () {
        return this.mapCreationFlow.execute();
    }

    public updateReservedAreas () {
        return this.mapCreationFlow.findInstance(MapReservedAreasStep).execute();
    }

    public switchUser (user: MetaUser) {
        if (this.user?.id !== user?.id) {
            const mapBuilder = this.mapBuilder;
            if (this.user) {
                mapBuilder.makeOwnerInactiveById(this.user.id);
            }

            if (user) {
                mapBuilder.makeOwnerActiveById(user.id);
            }

            this.user = user;
        }
    }
}