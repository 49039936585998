export const STATE = {
    BUNDLES_DATA: 'bundlesData',
    BUNDLES_DATA_LOADING: 'bundlesDataLoading',
    PARCELS_SLOTS_DATA: 'parcelSlotsData',
    PARCELS_SLOTS_DATA_LOADING: 'parcelSlotsDataLoading',
} as const;

export const MUTATIONS = {
    SET_BUNDLES_DATA: 'setBundlesData',
    SET_BUNDLES_DATA_LOADING: 'setBundlesDataLoading',
    SET_PARCELS_SLOTS_DATA: 'setParcelSlotsData',
    SET_PARCELS_SLOTS_DATA_LOADING: 'setParcelSlotsDataLoading',
} as const;

export const ACTIONS = {
    GET_BUNDLES_DATA: 'getBundlesData',
    ON_BUNDLES_DATA_LOADING: 'onBundlesDataLoading',
    GET_BUNDLES_SPLIT_PRICES: 'getBundlesSplitPrices',
    GET_PARCELS_SLOTS_DATA: 'getParcelSlotsData',
    ON_PARCELS_SLOTS_DATA_LOADING: 'onParcelSlotsDataLoading',
    ON_CLEAR_SHOP_DATA: 'onClearShopData',
} as const;

export const GETTERS = {
    BUNDLES_DATA: 'bundlesData',
    BUNDLES_DATA_LOADING: 'bundlesDataLoading',
    PARCELS_SLOTS_DATA: 'parcelSlotsData',
    PARCELS_SLOTS_DATA_LOADING: 'parcelSlotsDataLoading',
} as const;
