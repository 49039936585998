import { ParcelType } from "../constants/EnumParcelType";
import { isNoParcel } from "./isNoParcel";

// if any one of these parcels is empty,
// then the current parcel is of an near ocean type
const MatrixDeltasForNearOcean = [
    [-1, -1], // left up
    [-1, 1], // left down
    [1, -1], // right up 
    [1, 1], // right down
    [-2, 0], // double up
    [2, 0], // double down
    [0, -2], // double left
    [0, 2], // double right 
];

// if any one of these parcels is empty,
// then the current parcel is of an ocean type
const MatrixDeltasForOcean = [
    [-1, 0], // top
    [1, 0], // bottom
    [0, -1], // left
    [0, 1], // right
];

export function getParcelLandType (
    matrixBuffer: Uint8Array,
    matrixWidth: number,
    x: number,
    y: number,
): ParcelType {
    const isOcean = MatrixDeltasForOcean.some(
        ([i, j]) => isNoParcel(matrixBuffer, matrixWidth, x + j, y + i),
    );
    const isNearOcean = MatrixDeltasForNearOcean.some(
        ([i, j]) => isNoParcel(matrixBuffer, matrixWidth, x + j, y + i),
    );
    let type = ParcelType.INLAND;
    if (isOcean) {
        type = ParcelType.OCEAN;
    } else if (isNearOcean) {
        type = ParcelType.NEAR_OCEAN;
    }
    return type;
}