import { MUTATIONS, STATE } from "./names";
import { MetaBundle } from "~/core/models/MetaBundle";
import { ParcelStats, State } from "./types";

export default {
    [MUTATIONS.SET_BUNDLES_DATA](state: State, bundles: []) {
        if (bundles.length > 0) {
            state[STATE.BUNDLES_DATA] = bundles.map(bundle => new MetaBundle(bundle));
        }
    },
    [MUTATIONS.SET_BUNDLES_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.BUNDLES_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_PARCELS_SLOTS_DATA](state: State, payload: ParcelStats) {
        state[STATE.PARCELS_SLOTS_DATA] = payload;
    },
    [MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.PARCELS_SLOTS_DATA_LOADING] = payload;
    },
}
