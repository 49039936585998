import ApiService from "../api-interaction/ApiService";
import { Gallery } from "./Gallery";
import { GalleryImage } from "./GalleryImage";
import {PopupHelper} from "~/core/helpers/PopupHelper";

export class GalleryManager {
    static instance = null;
    static sharedInstance () {
        if(GalleryManager.instance === null) {
            GalleryManager.instance = new GalleryManager();
        }
        return GalleryManager.instance;
    }

    constructor () {
        this.gallery = new Gallery();
        this.waitingForRemove = new Set();
    }

    isImageApproved(imageId) {
        return this.gallery.approved.some(image => image.id === imageId);
    }

    async fetchData () {
        try {
            const response = await ApiService.get(`user/gallery/all`);
            if(response.status == 200) {
                this.gallery.update(response.data);
            } else {
                throw new Error(response);
            }
        } catch(e) {
            console.error('GalleryManager.js fetchData', e);
            PopupHelper.showErrorAlert('Something went wrong, please refresh the page and try again');
        }
    }

    async uploadImage (file) {
        const uploadData = await getSignedUrl();
        await uploadToS3(uploadData.postParams, file);
        const imageData = await getUploadedImage(uploadData.image_id);
        const image = new GalleryImage(imageData);
        this.gallery.add(image);


        async function getSignedUrl (){
            const response = await ApiService.query(`user/gallery/upload-init`);
            if(response.status !== 200) {
                throw new Error();
            }
            return response.data;
        }

        async function uploadToS3 (postParams, file) {
            try {
                ApiService.deleteAuthorizationToken();
                const formData = new FormData();
                formData.append('acl', 'public-read');
                for(const field in postParams.fields) {
                    formData.append(field, postParams.fields[field]);
                }
                formData.append('file', file);
                const response = await ApiService.post(postParams.url, formData);
                if(response.status != 204) {
                    throw new Error();
                }
            } finally {
                ApiService.setHeader();
            }
        }

        async function getUploadedImage (image_id) {
            const response = await ApiService.post(`user/gallery/uploaded`, { image_id });
            if(response.status != 200) {
                throw new Error();
            }
            return response.data;
        }
    }

    async removeImage (image) {
        if(!this.waitingForRemove.has(image)) {
            this.waitingForRemove.add(image);
            const response = await ApiService.post(`user/gallery/delete`, {
                image_id: image.id
            });
            this.waitingForRemove.delete(image);
            if(response.status != 204) {
                throw new Error();
            }
            this.gallery.remove(image);
        }
    }

    async assignImage (image, cropperCoordinates, parcelGroup) {
        const assignedResponse = await ApiService.post('/user/gallery/assign-parcel-group', {
            parcels_group_x: parcelGroup.x,
            parcels_group_y: parcelGroup.y,
            parcels_group_width: parcelGroup.width,
            parcels_group_height: parcelGroup.height,
            image_id: image.id,
            image_crop_x: cropperCoordinates.left,
            image_crop_y: cropperCoordinates.top,
            image_crop_width: cropperCoordinates.width,
            image_crop_height: cropperCoordinates.height,
        });
        return assignedResponse.data;
    }

    async removeAssignedImage (assignmentId) {
        await ApiService.post('/user/gallery/remove-assignment', {
            assignment_id: assignmentId
        });
    }
}
