
import GymNewButton from "~/components/utils/form-components/GymNewButton.vue";
import {PaymentMethodEnum} from "~/core/types/purchase-popup-2/PaymentMethodEnum";

export default {
    name: 'PaymentSuccesful',
    components: {
        GymNewButton,
    },
    props: {
        paymentMethod: {
            type: [String],
        },
        isCalledFromMap: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['onGoToNFTs', 'onFlowFinished'],
    data() {
        return {
            PaymentMethodEnum,
        }
    },
    methods: {
        handleGoToNFTs () {
            this.$emit('onGoToNFTs')
        },
        handleFlowFinished () {
            this.$emit('onFlowFinished')
        },
    }
}
