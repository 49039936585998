import { IDBPDatabase, openDB } from "idb";

export abstract class MapCreationStep {
    protected static idb: IDBPDatabase<unknown> | Promise<IDBPDatabase<unknown>>;
    public readonly metaverseId: number;

    constructor (
        metaverseId: number,
        public nextStep?: MapCreationStep,
    ) {
        this.metaverseId = metaverseId;
        MapCreationStep.initConnection();
    }

    public static initConnection () {
        if (!MapCreationStep.idb) {
            MapCreationStep.idb = MapCreationStep.connectToIndexedDB();
        }
    }

    public static getIDB () {
        return MapCreationStep.idb;
    }

    private static async connectToIndexedDB () {
        const idb = await openDB('meta-verse', 1, {
            upgrade (database) {
                const mapBoundaries = database.createObjectStore('map-boundaries', {keyPath: 'metaverseId'});
                const reservedAreasStore = database.createObjectStore('reserved-areas', {keyPath: 'metaverseId'});
                const ownedParcelsStore = database.createObjectStore('owned-parcels', {keyPath: 'metaverseId'});
                const imageParcelsStore = database.createObjectStore('image-parcels', {keyPath: 'metaverseId'});
                const reservedAreaSpritesStore = database.createObjectStore('reserved-area-sprites', {keyPath: 'id'});

                mapBoundaries.createIndex('metaverseId', 'metaverseId', {unique: true});
                reservedAreasStore.createIndex('metaverseId', 'metaverseId', {unique: true});
                ownedParcelsStore.createIndex('metaverseId', 'metaverseId', {unique: true});
                imageParcelsStore.createIndex('metaverseId', 'metaverseId', {unique: true});
                reservedAreaSpritesStore.createIndex('metaverseId', 'metaverseId', {unique: false, multiEntry: true});
                reservedAreaSpritesStore.createIndex('id', 'id', {unique: true});
            },
            terminated: () => {
                MapCreationStep.idb = this.connectToIndexedDB();
            },
            blocked (currentVersion, blockedVersion, event) {
                // eslint-disable-next-line no-console
                console.log('Blocked', currentVersion, blockedVersion, event);
            },
            blocking (currentVersion, blockingVersion, event) {
                // eslint-disable-next-line no-console
                console.log('Blocking', currentVersion, blockingVersion, event);
            },
        });
        MapCreationStep.idb = idb;
        return idb;
    }

    
    public findInstance<T extends abstract new (...args: unknown[]) => unknown>(type: T): InstanceType<T> | null {
        let currentStep: MapCreationStep | null = this;
        while (currentStep) {
            if (currentStep instanceof type) {
                return currentStep as InstanceType<T>;
            }
            currentStep = currentStep.nextStep;
        }
        return null;
    }

    protected abstract loadFromIndexedDB (): Promise<unknown>;
    protected abstract loadFromAPI (): Promise<unknown>;
    protected abstract process (data: unknown): unknown;
    protected abstract setupOnMap (data: unknown): void;
    protected abstract save (data: unknown): void;
    public abstract restoreFromIndexedDB (): Promise<unknown>;
    public abstract createFromAPI (): Promise<unknown>;
    public abstract execute (): Promise<void>;
}