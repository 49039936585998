import { GETTERS, STATE } from "./names";
import { State } from "./types";

export default {
    [GETTERS.IS_SUCCESS_MODAL_OPEN] (state: State) {
        return state[STATE.IS_SUCCESS_MODAL_OPEN];
    },
    [GETTERS.SUCCESS_MODAL_BTN_TEXT] (state: State) {
        return state[STATE.SUCCESS_MODAL_BTN_TEXT];
    },
    [GETTERS.SUCCESS_MODAL_BTN_ATTRS] (state: State) {
        return state[STATE.SUCCESS_MODAL_BTN_ATTRS];
    },
    [GETTERS.SUCCESS_MODAL_DESC] (state: State) {
        return state[STATE.SUCCESS_MODAL_DESC];
    },
    [GETTERS.SUCCESS_MODAL_TITLE] (state: State) {
        return state[STATE.SUCCESS_MODAL_TITLE];
    },
    [GETTERS.SUCCESS_MODAL_BUTTON_VISIBLE] (state: State) {
        return state[STATE.SUCCESS_MODAL_BUTTON_VISIBLE];
    },
    [GETTERS.SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL] (state: State) {
        return state[STATE.SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL];
    },
    [GETTERS.SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE] (state: State) {
        return state[STATE.SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE];
    },
    [GETTERS.AGGREGATED_MODAL_STATE] (state: State) {
        return {
            visible: state[STATE.IS_SUCCESS_MODAL_OPEN],
            title: state[STATE.SUCCESS_MODAL_TITLE],
            desc: state[STATE.SUCCESS_MODAL_DESC],
            btnText: state[STATE.SUCCESS_MODAL_BTN_TEXT],
            btnAttrs: state[STATE.SUCCESS_MODAL_BTN_ATTRS],
            buttonVisible: state[STATE.SUCCESS_MODAL_BUTTON_VISIBLE],
            closeOnClickModal: state[STATE.SUCCESS_MODAL_CLOSE_BY_CLICK_MODAL],
            closeOnPressEscape: state[STATE.SUCCESS_MODAL_CLOSE_ON_PRESS_ESCAPE],
        };
    },
};
