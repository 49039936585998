import { MetaverseId } from "./MetaverseId";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";

const $config = getClientRuntimeConfig();
const XmasMode = $config.xmasMode;
const affilityLogoPath = XmasMode ? "affilityNY.gif" : "affility.svg";

export const Metaverses = {
    [MetaverseId.AFFILITY]: {
        id: MetaverseId.AFFILITY,
        name: 'Affility',
        icon: require(`~/assets/affility/images/metaverses/${affilityLogoPath}`),
    },
    [MetaverseId.KABUTOCHO]: {
        id: MetaverseId.KABUTOCHO,
        name: 'Kabutocho',
        icon: require('~/assets/affility/images/metaverses/kabutocho.svg'),
    },
};