import { STATE } from "./names";
import { MetaBundle } from "~/core/models/MetaBundle";
import { ParcelStats } from "./types";

export default () => {
    return {
        [STATE.BUNDLES_DATA]: [] as MetaBundle[],
        [STATE.BUNDLES_DATA_LOADING]: true,
        [STATE.PARCELS_SLOTS_DATA]: null as ParcelStats | null,
        [STATE.PARCELS_SLOTS_DATA_LOADING]: true,
    }
}
