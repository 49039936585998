import type { WalletWatchableAsset } from "~/core/types/affility/web3/WalletWatchableAsset";
import { ACTIONS } from "./names";
import { WALLET_ERROR_CODES } from "~/core/services/utils/Constants";
import { GETTERS as GETTERS_WEB3_CONNECTION } from "../connection/names";

export default {
    async [ACTIONS.WATCH_ASSET]({ rootGetters, dispatch }, { asset, network }: { asset: WalletWatchableAsset; network: any }) {
        const message = "Failed to add address to your wallet";

        try {
            const isChainSwitched = await handleNetworkSwitch(dispatch, network);
            if (!isChainSwitched) {
                throw new Error(message);
            }

            const provider = rootGetters[`affility/web3/connection/${GETTERS_WEB3_CONNECTION.PROVIDER}`];
            await addAssetToWallet(provider, asset);

        } catch (error) {
            throw message;
        }
    },

    async [ACTIONS.ON_REQUEST_TO_CHANGE_CHAIN]({ rootGetters }, chainId: any) {
        const provider = rootGetters[`affility/web3/connection/${GETTERS_WEB3_CONNECTION.PROVIDER}`];
        return provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: formatChainId(chainId) }],
        });
    },

    async [ACTIONS.ON_REQUEST_TO_ADD_CHAIN]({ rootGetters }, network: any) {
        const provider = rootGetters[`affility/web3/connection/${GETTERS_WEB3_CONNECTION.PROVIDER}`];
        return provider.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: formatChainId(network.chainId),
                chainName: network.name,
                nativeCurrency: network.nativeCurrency,
                rpcUrls: [network.url],
                blockExplorerUrls: [network.blockExplorerUrl],
            }],
        });
    },
};

function formatChainId(chainId: any): string {
    return typeof chainId === "number" ? `0x${chainId.toString(16)}` : chainId;
}

async function handleNetworkSwitch(dispatch: any, network: any): Promise<boolean> {
    try {
        await dispatch(ACTIONS.ON_REQUEST_TO_CHANGE_CHAIN, network.chainId);
        return true;
    } catch (error) {
        if (error.code === WALLET_ERROR_CODES.USER_REJECTED) {
            return false;
        }

        console.error("Error switching chain:", error);

        try {
            await dispatch(ACTIONS.ON_REQUEST_TO_ADD_CHAIN, network);
            return true;
        } catch (addError) {
            return false;
        }
    }
}

function addAssetToWallet(provider: any, asset: WalletWatchableAsset): Promise<void> {
    return new Promise((resolve, reject) => {
        provider.sendAsync({
            method: "wallet_watchAsset",
            params: asset,
        }, (error: any, added: boolean) => {
            if (error) {
                reject(error);
            } else if (added) {
                resolve();
            } else {
                reject("Asset addition was not confirmed.");
            }
        });
    });
}
