import { getSupportedGPUTextureFormats } from '@loaders.gl/textures';
import { MetaMatrix } from "./models/MetaMatrix";
import { MetaParcel } from "./models/MetaParcel";
import { MetaReservedArea } from "./models/MetaReservedArea";
import { LandBoundariesProcessingResult } from "./types/LandBoundariesProcessingResult";
import { ParcelOwner } from "./models/ParcelOwner";
import { TextureLevel } from "./types/TextureLevel";
import { WebGLExtensionsByFormat } from './constants/EnumWebGLExtensionsByFormat';
import { ImageSpriteInfo } from './types/ImageSpriteInfo';
import { isReactive } from 'vue';

export class MapBuilder extends EventTarget {
    public gl: WebGL2RenderingContext | null = null;
    public parcelOwners = new Map<number, ParcelOwner>();
    public matrix: MetaMatrix = new MetaMatrix();
    public reservedAreas = new Map();
    public reservedAreaSprites: {[key: string]: ImageSpriteInfo} = {};
    public placeholderSprite: Partial<ImageSpriteInfo> = {
        spriteIndex: -1,
        texture: {
            compressed: false,
            width: 1,
            height: 1,
            webglTexture: null,
            data: new Uint8Array([0, 0, 0, 0]),
        },
    };
    private activeOwnerIds: Set<number> = new Set();

    constructor () {
        super();
        Object.defineProperty(this, 'reservedAreaSprites', {enumerable: false});
    }

    private uploadTextureToGPU (
        image: TextureLevel,
        textureToActivate: number = this.gl.TEXTURE15,
        textureToReactivate: number = this.gl.TEXTURE0,
    ) {
        const gl = this.gl;
        const texture = gl.createTexture();
        gl.activeTexture(textureToActivate);
        gl.bindTexture(gl.TEXTURE_2D, texture);
        if (image.compressed) {
            gl.compressedTexImage2D(gl.TEXTURE_2D, 0, image.format, image.width, image.height, 0, image.data);
        } else {
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA8, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, image.data, 0);
        }
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        image.webglTexture = texture;
        gl.bindTexture(gl.TEXTURE_2D, null);
        gl.activeTexture(textureToReactivate);
    }

    private async uploadSpriteToGPU (
        sprite: ImageSpriteInfo,
        textureToActivate: number = this.gl?.TEXTURE15,
        textureToReactivate: number = this.gl?.TEXTURE0,
    ) {
        if (sprite.texture && this.gl) {
            this.uploadTextureToGPU(sprite.texture, textureToActivate, textureToReactivate);
        }
        if (sprite.updatingPromise) {
            const updatedSprite = await sprite.updatingPromise;
            if (this.gl) {
                const textureToActivateInternal = textureToActivate || this.gl.TEXTURE15;
                const textureToReactivateInternal = textureToReactivate || this.gl.TEXTURE0;
                this.uploadTextureToGPU(
                    updatedSprite.texture,
                    textureToActivateInternal,
                    textureToReactivateInternal,
                );
            }
        }
    }

    public setGlContext (gl: WebGL2RenderingContext) {
        this.gl = gl;
        const formats = getSupportedGPUTextureFormats(gl);
        for (const format of formats) {
            if (WebGLExtensionsByFormat[format]) {
                const extension = WebGLExtensionsByFormat[format];
                gl.getExtension(extension); // enabling webgl compressed textures extension
            }
        } // enabling webgl compressed textures extensions
    }

    public uploadAllSpritesToGPU (textureToActivate: number = this.gl?.TEXTURE15, textureToReactivate: number = this.gl?.TEXTURE0) {
        for (const sprite of Object.values(this.reservedAreaSprites)) {
            this.uploadSpriteToGPU(sprite, textureToActivate, textureToReactivate);
        }
    }

    public createMatrix (landBoundaries: LandBoundariesProcessingResult) {
        this.matrix.createMatrix(landBoundaries.processedBoundaries, landBoundaries.matrixSize);
    }
    
    public setupReservedAreas (reservedAreas: MetaReservedArea[]) {
        const matrix = this.matrix;
        const newReservedAreas = new Map();
        for (const reservedArea of reservedAreas) {
            newReservedAreas.set(reservedArea.id, reservedArea);
            matrix.addReservedArea(reservedArea);
        }
        this.reservedAreas = newReservedAreas;
    }

    public setupOwnedParcels (parcels: MetaParcel[]) {
        const rows = this.matrix.rows;
        for (const parcel of parcels) {
            const row = rows[parcel.y];
            row.addOwnedParcel(parcel);
        }
    }

    public setOwnersMap (ownersMap: Map<number, ParcelOwner>) {
        const activeOwnerIds = new Set(this.activeOwnerIds); // using a copy to avoid issues as the origin set is being mutated
        activeOwnerIds.forEach(id => this.makeOwnerInactiveById(id));
        this.parcelOwners = ownersMap;
        activeOwnerIds.forEach(id => this.makeOwnerActiveById(id));
    }

    public addReservedAreaSprite (sprite: ImageSpriteInfo) {
        this.reservedAreaSprites[sprite.spriteIndex] = sprite;
        this.uploadSpriteToGPU(sprite);
    }

    public getOwnerById (id: number) {
        return this.parcelOwners.get(id) || null;
    }

    public addOwner (owner: ParcelOwner) {
        return this.parcelOwners.set(owner.id, owner);
    }

    public makeOwnerActiveById (id: number) {
        const owner = this.getOwnerById(id);
        this.makeOwnerActive(owner);
    }

    public makeOwnerActive (owner?: ParcelOwner) {
        if (owner) {
            this.activeOwnerIds.add(owner.id);
            if (!isReactive(owner)) {
                owner = owner.makeReactive();
            }
            owner.makeOwnerActive();
            this.setupOwnedParcels(owner.ownedParcels);
            this.addOwner(owner);
        }
    }

    public makeOwnerInactiveById (id: number) {
        const owner = this.getOwnerById(id);
        this.makeOwnerInactive(owner);
    }

    public makeOwnerInactive (owner?: ParcelOwner) {
        if (owner) {
            this.activeOwnerIds.delete(owner.id);
            if (isReactive(owner)) {
                owner = owner.makeNonReactive();
            }
            owner.makeOwnerInactive();
            this.setupOwnedParcels(owner.ownedParcels);
            this.addOwner(owner);
        }
    }

    public redraw () {
        this.dispatchEvent(new Event('redraw'));
    }
}