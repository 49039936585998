import { reactive } from "vue";
import { MetaParcel } from "./MetaParcel";

export class ParcelOwner {

    public static create(
        id: number,
        walletAddress?: string,
        isCurrentUser: boolean = false,
        ownedParcels: Array<MetaParcel> = [],
    ) {
        return new ParcelOwner(
            id,
            walletAddress,
            isCurrentUser,
            ownedParcels,
        );
    }

    private reactiveProxy: ReturnType<typeof reactive<ParcelOwner>> = null;

    constructor(
        public id: number,
        public walletAddress?: string,
        public isCurrentUser: boolean = false,
        public ownedParcels: Array<MetaParcel> = [],
    ) {
        if (ownedParcels && !Array.isArray(ownedParcels)) {
            throw new Error('Owned parcels must be an array, null, or undefined');
        }
        ownedParcels?.forEach?.(parcel => parcel.claimBy(this));
    }

    private get self(): ParcelOwner {
        return (this.reactiveProxy || this) as ParcelOwner;
    }

    private reclaimOwnedParcels(): void {
        this.ownedParcels.forEach(parcel => parcel.claimBy(this.self));
    }

    public makeReactive(): ParcelOwner {
        this.reactiveProxy = reactive(this);
        this.reclaimOwnedParcels();
        return this.reactiveProxy as ParcelOwner;
    }

    public makeNonReactive(): ParcelOwner {
        this.reactiveProxy = null;
        this.reclaimOwnedParcels();
        return this;
    }

    public makeOwnerActive(): void {
        this.self.isCurrentUser = true;
    }

    public makeOwnerInactive(): void {
        this.self.isCurrentUser = false;
    }

    public ownsParcel(parcel: MetaParcel): boolean {
        return this.self.ownedParcels.includes(parcel);
    }

    public addToOwnedParcelsUnsafe(parcel: MetaParcel): void {
        this.self.ownedParcels.push(parcel);
    }

    public removeFromOwnedParcelsUnsafe(parcel: MetaParcel): void {
        this.self.ownedParcels.splice(this.self.ownedParcels.indexOf(parcel), 1);
    }

    public ownParcelUnsafe(parcel: MetaParcel): void {
        parcel.owner = this;
        this.self.addToOwnedParcelsUnsafe(parcel);
    }

    public disownParcelUnsafe(parcel: MetaParcel): void {
        parcel.owner = null;
        this.self.removeFromOwnedParcelsUnsafe(parcel);
    }

    public releaseOwnership(parcels: MetaParcel | MetaParcel[], strict: boolean = true): void {
        if (!Array.isArray(parcels)) {
            parcels = [parcels];
        }
        for (const p of parcels) {
            p.release(strict, this);
        }
    }    

    public claimOwnership(parcels: MetaParcel | MetaParcel[], force: boolean = false): void {
        if (!Array.isArray(parcels)) {
            parcels = [parcels];
        }
        for (const p of parcels) {
            p.claimBy(this, force);
        }
    }
}