import {diagLog} from "~/core/services/utils/Constants";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import {GlobalMakerService} from "~/core/services/GlobalMakerService";
import {MetaMapDirector} from "~/core/services/map2/MetaMapDirector";
import ApiService from "~/core/services/api-interaction/ApiService";
import { FinishReasonEnum } from "~/core/types/purchase-popup-2/FinishReasonEnum";

const state = () => {
    return {
        imageToAssign: null,
        metaverseId: GlobalMakerService.metaverseId || 1,
        metaMapDirector: null,
        metaWorldInitializationPromise: null,
        metaMapInitializers: new Map(),
    };
};

// Actions
const actions = {
    async mintAndClaimParcels ({ dispatch, getters }, payload) {
        const parcels = payload.stats.free;
        if (parcels.length === 0) {
            return;
        }
        const updateData = parcels.map((pc) => {
            return {
                x: pc.x,
                y: pc.y,
            };
        });
        const price = payload.price;
        const claimableAmount = getters.claimableParcelsCount;

        if (claimableAmount < parcels.length) {
            const parcelsToBuy = parcels.length - claimableAmount;
            const finalReason = await MetaWorldManager
                .sharedInstance()
                .web3MintParcels(
                    {
                        price,
                        count: parcelsToBuy,
                    },
                    true,
                );

            if (finalReason !== FinishReasonEnum.SUCCESS) {
                diagLog(payload, 'User aborted purchase. Reason:', finalReason);
                return finalReason;
            }
            diagLog(payload, 'Parcels purchase completed');
        } else {
            diagLog(payload, 'Parcels purchase skipped - no need to buy');
        }

        return dispatch('claimParcels', updateData);
    },
    async claimParcels(_, payload) {
        const response = await ApiService.post('map/claim-parcels', { parcels: payload });
        return !!response.status;
    },
    selectImageToAssign ({commit}, payload) {
        commit('SET_IMAGE_TO_ASSIGN', payload);
    },
    async reinitMap ({commit, state}) {
        const metaverseId = this.$metaverseId();
        let metaMapDirector = null;
        let metaMapPromise = null;
        if (state.metaMapInitializers.has(metaverseId)) {
            const mapInitializer = state.metaMapInitializers.get(metaverseId);
            metaMapDirector = mapInitializer.metaMapDirector;
            metaMapPromise = mapInitializer.promise;
        } else {
            const newMetaMapDirector = new MetaMapDirector(
                metaverseId,
                {
                    reservedAreaSpritesHost: this.$config.mapSpritesHost,
                    reservedAreaSpritesPath: `reserved-area-images/${metaverseId}/basis/`,
                },
            );
            const newMetaWorldCreationPromise = newMetaMapDirector.constructMap();
            const mapInitializer = {
                promise: newMetaWorldCreationPromise,
                metaMapDirector: newMetaMapDirector,
                metaverseId,
            };
            state.metaMapInitializers.set(metaverseId, mapInitializer);
            metaMapDirector = newMetaMapDirector;
            metaMapPromise = newMetaWorldCreationPromise;
        }
        commit('SET_META_MAP_DIRECTOR', metaMapDirector);
        commit('SET_META_MAP_CREATION_PROMISE', metaMapPromise);
        await metaMapPromise;
        const currentUser = this.getters['application/driver/authUser'];
        commit('SWITCH_USER', currentUser);
        state.metaMapInitializers.delete(metaverseId);
    },
    async refetchReservedAreas ({ state }) {
        return await state.metaMapDirector.updateReservedAreas();
    },
};

// Mutations
const mutations = {
    SET_IMAGE_TO_ASSIGN (state, image) {
        state.imageToAssign = image;
    },
    SET_METAVERSE_ID (state, metaverseId) {
        state.metaverseId = metaverseId;
    },
    SET_META_MAP_DIRECTOR (state, metaMapDirector) {
        state.metaMapDirector = metaMapDirector;
    },
    SET_META_MAP_CREATION_PROMISE (state, promise) {
        state.metaWorldInitializationPromise = promise;
    },
    SWITCH_USER (state, user) {
        state.metaMapDirector.switchUser(user);
    },
};

// Getters
const getters = {
    imageToAssign: (state) => state.imageToAssign,
    metaverseId: (state) => state.metaverseId,
    metaMapDirector: (state) => state.metaMapDirector,
    metaWorldInitializationPromise: (state) => state.metaWorldInitializationPromise,
    metaMapInitializers: (state) => state.metaMapInitializers,
    userOwnedParcels: (state) => state.metaMapDirector.getOwnedParcelsOfUser(),
    claimableParcelsCount: (state, getters) => {
        const inventoryInfo = MetaInventoryContainer.sharedInstance().userInventoryInfo;
        const claimedParcelsCount = getters['userOwnedParcels'].length;
        const totalUsersParcelsCountWithUnclaimed = inventoryInfo.getParcelsCount() || 0;
        return totalUsersParcelsCountWithUnclaimed - claimedParcelsCount;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

