import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/transactions.js'), 'transactions.js')
  resolveStoreModules(require('../store/user/index.js'), 'user/index.js')
  resolveStoreModules(require('../store/actions/index.js'), 'actions/index.js')
  resolveStoreModules(require('../store/application/driver.js'), 'application/driver.js')
  resolveStoreModules(require('../store/application/maintenances.js'), 'application/maintenances.js')
  resolveStoreModules(require('../store/application/my-products.js'), 'application/my-products.js')
  resolveStoreModules(require('../store/application/popup-control.js'), 'application/popup-control.js')
  resolveStoreModules(require('../store/application/purchase-control.ts'), 'application/purchase-control.ts')
  resolveStoreModules(require('../store/career-status/cash-bonus.js'), 'career-status/cash-bonus.js')
  resolveStoreModules(require('../store/career-status/feature-prioritization.js'), 'career-status/feature-prioritization.js')
  resolveStoreModules(require('../store/cashFT/cashFT-KYC-verification.js'), 'cashFT/cashFT-KYC-verification.js')
  resolveStoreModules(require('../store/cashFT/products.js'), 'cashFT/products.js')
  resolveStoreModules(require('../store/incentives/globalPoolIncentive.js'), 'incentives/globalPoolIncentive.js')
  resolveStoreModules(require('../store/incentives/productAutoshipIncentive.js'), 'incentives/productAutoshipIncentive.js')
  resolveStoreModules(require('../store/incentives/thirdAnniversaryIncentive.js'), 'incentives/thirdAnniversaryIncentive.js')
  resolveStoreModules(require('../store/map/mapModule.js'), 'map/mapModule.js')
  resolveStoreModules(require('../store/user/gymstreetLevelPool.js'), 'user/gymstreetLevelPool.js')
  resolveStoreModules(require('../store/user/levelpool.js'), 'user/levelpool.js')
  resolveStoreModules(require('../store/user/singlepool.js'), 'user/singlepool.js')
  resolveStoreModules(require('../store/user/streamlineBonus.js'), 'user/streamlineBonus.js')
  resolveStoreModules(require('../store/user/subscription.js'), 'user/subscription.js')
  resolveStoreModules(require('../store/user/turnoverPool.js'), 'user/turnoverPool.js')
  resolveStoreModules(require('../store/finances/minerRewards/index.js'), 'finances/minerRewards/index.js')
  resolveStoreModules(require('../store/application/mystery-box/index.ts'), 'application/mystery-box/index.ts')
  resolveStoreModules(require('../store/affility/metaverse/actions.ts'), 'affility/metaverse/actions.ts')
  resolveStoreModules(require('../store/affility/metaverse/getters.ts'), 'affility/metaverse/getters.ts')
  resolveStoreModules(require('../store/affility/metaverse/mutations.ts'), 'affility/metaverse/mutations.ts')
  resolveStoreModules(require('../store/affility/metaverse/state.ts'), 'affility/metaverse/state.ts')
  resolveStoreModules(require('../store/affility/promos/actions.ts'), 'affility/promos/actions.ts')
  resolveStoreModules(require('../store/affility/promos/getters.ts'), 'affility/promos/getters.ts')
  resolveStoreModules(require('../store/affility/promos/helpers.ts'), 'affility/promos/helpers.ts')
  resolveStoreModules(require('../store/affility/promos/mutations.ts'), 'affility/promos/mutations.ts')
  resolveStoreModules(require('../store/affility/promos/state.ts'), 'affility/promos/state.ts')
  resolveStoreModules(require('../store/affility/user/actions.ts'), 'affility/user/actions.ts')
  resolveStoreModules(require('../store/affility/user/getters.ts'), 'affility/user/getters.ts')
  resolveStoreModules(require('../store/affility/user/mutations.ts'), 'affility/user/mutations.ts')
  resolveStoreModules(require('../store/affility/user/state.ts'), 'affility/user/state.ts')
  resolveStoreModules(require('../store/affility/layout/sidebar/actions.ts'), 'affility/layout/sidebar/actions.ts')
  resolveStoreModules(require('../store/affility/layout/sidebar/getters.ts'), 'affility/layout/sidebar/getters.ts')
  resolveStoreModules(require('../store/affility/layout/sidebar/mutations.ts'), 'affility/layout/sidebar/mutations.ts')
  resolveStoreModules(require('../store/affility/layout/sidebar/state.ts'), 'affility/layout/sidebar/state.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/actions.ts'), 'affility/pages/dashboard/actions.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/getters.ts'), 'affility/pages/dashboard/getters.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/mutations.ts'), 'affility/pages/dashboard/mutations.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/state.ts'), 'affility/pages/dashboard/state.ts')
  resolveStoreModules(require('../store/affility/pages/my-team/actions.ts'), 'affility/pages/my-team/actions.ts')
  resolveStoreModules(require('../store/affility/pages/my-team/getters.ts'), 'affility/pages/my-team/getters.ts')
  resolveStoreModules(require('../store/affility/pages/my-team/mutations.ts'), 'affility/pages/my-team/mutations.ts')
  resolveStoreModules(require('../store/affility/pages/my-team/state.ts'), 'affility/pages/my-team/state.ts')
  resolveStoreModules(require('../store/affility/pages/shop/actions.ts'), 'affility/pages/shop/actions.ts')
  resolveStoreModules(require('../store/affility/pages/shop/bundleConfig.ts'), 'affility/pages/shop/bundleConfig.ts')
  resolveStoreModules(require('../store/affility/pages/shop/getters.ts'), 'affility/pages/shop/getters.ts')
  resolveStoreModules(require('../store/affility/pages/shop/mutations.ts'), 'affility/pages/shop/mutations.ts')
  resolveStoreModules(require('../store/affility/pages/shop/state.ts'), 'affility/pages/shop/state.ts')
  resolveStoreModules(require('../store/affility/user/auth/actions.ts'), 'affility/user/auth/actions.ts')
  resolveStoreModules(require('../store/affility/user/auth/getters.ts'), 'affility/user/auth/getters.ts')
  resolveStoreModules(require('../store/affility/user/auth/mutations.ts'), 'affility/user/auth/mutations.ts')
  resolveStoreModules(require('../store/affility/user/auth/state.ts'), 'affility/user/auth/state.ts')
  resolveStoreModules(require('../store/affility/user/binary/actions.ts'), 'affility/user/binary/actions.ts')
  resolveStoreModules(require('../store/affility/user/binary/getters.ts'), 'affility/user/binary/getters.ts')
  resolveStoreModules(require('../store/affility/user/binary/mutations.ts'), 'affility/user/binary/mutations.ts')
  resolveStoreModules(require('../store/affility/user/binary/state.ts'), 'affility/user/binary/state.ts')
  resolveStoreModules(require('../store/affility/user/feedback/actions.ts'), 'affility/user/feedback/actions.ts')
  resolveStoreModules(require('../store/affility/user/feedback/getters.ts'), 'affility/user/feedback/getters.ts')
  resolveStoreModules(require('../store/affility/user/feedback/mutations.ts'), 'affility/user/feedback/mutations.ts')
  resolveStoreModules(require('../store/affility/user/feedback/state.ts'), 'affility/user/feedback/state.ts')
  resolveStoreModules(require('../store/affility/user/flows/actions.ts'), 'affility/user/flows/actions.ts')
  resolveStoreModules(require('../store/affility/user/flows/getters.ts'), 'affility/user/flows/getters.ts')
  resolveStoreModules(require('../store/affility/user/flows/mutations.ts'), 'affility/user/flows/mutations.ts')
  resolveStoreModules(require('../store/affility/user/flows/state.ts'), 'affility/user/flows/state.ts')
  resolveStoreModules(require('../store/affility/user/gallery/actions.ts'), 'affility/user/gallery/actions.ts')
  resolveStoreModules(require('../store/affility/user/gallery/getters.ts'), 'affility/user/gallery/getters.ts')
  resolveStoreModules(require('../store/affility/user/gallery/mutations.ts'), 'affility/user/gallery/mutations.ts')
  resolveStoreModules(require('../store/affility/user/gallery/state.ts'), 'affility/user/gallery/state.ts')
  resolveStoreModules(require('../store/affility/user/notifications/actions.ts'), 'affility/user/notifications/actions.ts')
  resolveStoreModules(require('../store/affility/user/notifications/getters.ts'), 'affility/user/notifications/getters.ts')
  resolveStoreModules(require('../store/affility/user/notifications/mutations.ts'), 'affility/user/notifications/mutations.ts')
  resolveStoreModules(require('../store/affility/user/notifications/state.ts'), 'affility/user/notifications/state.ts')
  resolveStoreModules(require('../store/affility/user/rank/actions.ts'), 'affility/user/rank/actions.ts')
  resolveStoreModules(require('../store/affility/user/rank/getters.ts'), 'affility/user/rank/getters.ts')
  resolveStoreModules(require('../store/affility/user/rank/mutations.ts'), 'affility/user/rank/mutations.ts')
  resolveStoreModules(require('../store/affility/user/rank/state.ts'), 'affility/user/rank/state.ts')
  resolveStoreModules(require('../store/affility/user/team/actions.ts'), 'affility/user/team/actions.ts')
  resolveStoreModules(require('../store/affility/user/team/getters.ts'), 'affility/user/team/getters.ts')
  resolveStoreModules(require('../store/affility/user/team/mutations.ts'), 'affility/user/team/mutations.ts')
  resolveStoreModules(require('../store/affility/user/team/state.ts'), 'affility/user/team/state.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/actions.ts'), 'affility/user/unilevel/actions.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/getters.ts'), 'affility/user/unilevel/getters.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/mutations.ts'), 'affility/user/unilevel/mutations.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/state.ts'), 'affility/user/unilevel/state.ts')
  resolveStoreModules(require('../store/affility/web3/connection/actions.ts'), 'affility/web3/connection/actions.ts')
  resolveStoreModules(require('../store/affility/web3/connection/getters.ts'), 'affility/web3/connection/getters.ts')
  resolveStoreModules(require('../store/affility/web3/connection/mutations.ts'), 'affility/web3/connection/mutations.ts')
  resolveStoreModules(require('../store/affility/web3/connection/state.ts'), 'affility/web3/connection/state.ts')
  resolveStoreModules(require('../store/affility/web3/tokens/actions.ts'), 'affility/web3/tokens/actions.ts')
  resolveStoreModules(require('../store/affility/web3/tokens/getters.ts'), 'affility/web3/tokens/getters.ts')
  resolveStoreModules(require('../store/affility/web3/tokens/mutations.ts'), 'affility/web3/tokens/mutations.ts')
  resolveStoreModules(require('../store/affility/web3/tokens/state.ts'), 'affility/web3/tokens/state.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/income/actions.ts'), 'affility/pages/dashboard/income/actions.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/income/getters.ts'), 'affility/pages/dashboard/income/getters.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/income/mutations.ts'), 'affility/pages/dashboard/income/mutations.ts')
  resolveStoreModules(require('../store/affility/pages/dashboard/income/state.ts'), 'affility/pages/dashboard/income/state.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/actions.ts'), 'affility/user/binary/commissions/actions.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/getters.ts'), 'affility/user/binary/commissions/getters.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/mutations.ts'), 'affility/user/binary/commissions/mutations.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/state.ts'), 'affility/user/binary/commissions/state.ts')
  resolveStoreModules(require('../store/affility/user/binary/progress/actions.ts'), 'affility/user/binary/progress/actions.ts')
  resolveStoreModules(require('../store/affility/user/binary/progress/getters.ts'), 'affility/user/binary/progress/getters.ts')
  resolveStoreModules(require('../store/affility/user/binary/progress/mutations.ts'), 'affility/user/binary/progress/mutations.ts')
  resolveStoreModules(require('../store/affility/user/binary/progress/state.ts'), 'affility/user/binary/progress/state.ts')
  resolveStoreModules(require('../store/affility/user/flows/email-verification/actions.ts'), 'affility/user/flows/email-verification/actions.ts')
  resolveStoreModules(require('../store/affility/user/flows/email-verification/getters.ts'), 'affility/user/flows/email-verification/getters.ts')
  resolveStoreModules(require('../store/affility/user/flows/email-verification/mutations.ts'), 'affility/user/flows/email-verification/mutations.ts')
  resolveStoreModules(require('../store/affility/user/flows/email-verification/state.ts'), 'affility/user/flows/email-verification/state.ts')
  resolveStoreModules(require('../store/affility/user/flows/terms-and-conditions/actions.ts'), 'affility/user/flows/terms-and-conditions/actions.ts')
  resolveStoreModules(require('../store/affility/user/flows/terms-and-conditions/getters.ts'), 'affility/user/flows/terms-and-conditions/getters.ts')
  resolveStoreModules(require('../store/affility/user/flows/terms-and-conditions/mutations.ts'), 'affility/user/flows/terms-and-conditions/mutations.ts')
  resolveStoreModules(require('../store/affility/user/flows/terms-and-conditions/state.ts'), 'affility/user/flows/terms-and-conditions/state.ts')
  resolveStoreModules(require('../store/affility/user/flows/upgrade-to-affiliate/actions.ts'), 'affility/user/flows/upgrade-to-affiliate/actions.ts')
  resolveStoreModules(require('../store/affility/user/flows/upgrade-to-affiliate/getters.ts'), 'affility/user/flows/upgrade-to-affiliate/getters.ts')
  resolveStoreModules(require('../store/affility/user/flows/upgrade-to-affiliate/mutations.ts'), 'affility/user/flows/upgrade-to-affiliate/mutations.ts')
  resolveStoreModules(require('../store/affility/user/flows/upgrade-to-affiliate/state.ts'), 'affility/user/flows/upgrade-to-affiliate/state.ts')
  resolveStoreModules(require('../store/affility/user/notifications/history/actions.ts'), 'affility/user/notifications/history/actions.ts')
  resolveStoreModules(require('../store/affility/user/notifications/history/getters.ts'), 'affility/user/notifications/history/getters.ts')
  resolveStoreModules(require('../store/affility/user/notifications/history/mutations.ts'), 'affility/user/notifications/history/mutations.ts')
  resolveStoreModules(require('../store/affility/user/notifications/history/state.ts'), 'affility/user/notifications/history/state.ts')
  resolveStoreModules(require('../store/affility/user/notifications/sidebar/actions.ts'), 'affility/user/notifications/sidebar/actions.ts')
  resolveStoreModules(require('../store/affility/user/notifications/sidebar/getters.ts'), 'affility/user/notifications/sidebar/getters.ts')
  resolveStoreModules(require('../store/affility/user/notifications/sidebar/mutations.ts'), 'affility/user/notifications/sidebar/mutations.ts')
  resolveStoreModules(require('../store/affility/user/notifications/sidebar/state.ts'), 'affility/user/notifications/sidebar/state.ts')
  resolveStoreModules(require('../store/affility/user/notifications/unread/actions.ts'), 'affility/user/notifications/unread/actions.ts')
  resolveStoreModules(require('../store/affility/user/notifications/unread/getters.ts'), 'affility/user/notifications/unread/getters.ts')
  resolveStoreModules(require('../store/affility/user/notifications/unread/mutations.ts'), 'affility/user/notifications/unread/mutations.ts')
  resolveStoreModules(require('../store/affility/user/notifications/unread/state.ts'), 'affility/user/notifications/unread/state.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/progress/actions.ts'), 'affility/user/unilevel/progress/actions.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/progress/getters.ts'), 'affility/user/unilevel/progress/getters.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/progress/mutations.ts'), 'affility/user/unilevel/progress/mutations.ts')
  resolveStoreModules(require('../store/affility/user/unilevel/progress/state.ts'), 'affility/user/unilevel/progress/state.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/info/actions.ts'), 'affility/user/binary/commissions/info/actions.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/info/getters.ts'), 'affility/user/binary/commissions/info/getters.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/info/mutations.ts'), 'affility/user/binary/commissions/info/mutations.ts')
  resolveStoreModules(require('../store/affility/user/binary/commissions/info/state.ts'), 'affility/user/binary/commissions/info/state.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/full-loader/actions.ts'), 'affility/user/feedback/modals/full-loader/actions.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/full-loader/getters.ts'), 'affility/user/feedback/modals/full-loader/getters.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/full-loader/mutations.ts'), 'affility/user/feedback/modals/full-loader/mutations.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/full-loader/state.ts'), 'affility/user/feedback/modals/full-loader/state.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/success/actions.ts'), 'affility/user/feedback/modals/success/actions.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/success/getters.ts'), 'affility/user/feedback/modals/success/getters.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/success/mutations.ts'), 'affility/user/feedback/modals/success/mutations.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/success/state.ts'), 'affility/user/feedback/modals/success/state.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/toast-notification/actions.ts'), 'affility/user/feedback/modals/toast-notification/actions.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/toast-notification/getters.ts'), 'affility/user/feedback/modals/toast-notification/getters.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/toast-notification/mutations.ts'), 'affility/user/feedback/modals/toast-notification/mutations.ts')
  resolveStoreModules(require('../store/affility/user/feedback/modals/toast-notification/state.ts'), 'affility/user/feedback/modals/toast-notification/state.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
