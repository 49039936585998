import { RawReservedAreasData } from "../types/RawReservedAreasData";
import { MetaReservedArea } from "../models/MetaReservedArea";
import ApiService from "../../api-interaction/ApiService";
import { MAP_ENDPOINTS } from "../../utils/Constants";
import { RawReservedAreaDimensions } from "../types/RawReservedAreaDimensions";
import { RawReservedArea } from "../types/RawReservedArea";
import { MapBuilder } from "../MapBuilder";
import { MapCreationStep } from "./MapCreationStep";

export class MapReservedAreasStep extends MapCreationStep {

    constructor (
        metaverseId: number,
        private map: MapBuilder,
        nextStep?: MapCreationStep,
    ) {
        super(metaverseId, nextStep);
    }

    protected async loadFromIndexedDB (): Promise<MetaReservedArea[] | null> {
        const idb = await MapCreationStep.idb;
        const result = await idb.get('reserved-areas', this.metaverseId);
        return (result?.data as MetaReservedArea[]) || null;
    }

    protected async loadFromAPI (): Promise<RawReservedAreasData> {
        const promises = [
            ApiService.get(MAP_ENDPOINTS.reserved_areas_data),
            ApiService.get(MAP_ENDPOINTS.reserved_areas),
        ];
        const [reservedAreasDimensions, reservedAreasAndPartnerships] = await Promise.all(promises);
        return {
            reservedAreasDimensions: reservedAreasDimensions.data as RawReservedAreaDimensions[],
            reservedAreasAndPartnerships: reservedAreasAndPartnerships.data as RawReservedArea[],
        };
    }

    protected process (data: RawReservedAreasData): MetaReservedArea[] {
        const matrixBuffer = this.map.matrix.matrixBuffer;
        let reservedAreasWithImagesCount = 0;
        const areasCount = data.reservedAreasDimensions.length;
        const metaReservedAreas: MetaReservedArea[] = new Array(areasCount);
        for (let i = 0; i < areasCount; i++) {
            const dimensions = data.reservedAreasDimensions[i];
            const reservedArea = data.reservedAreasAndPartnerships[i];
            const metaReservedArea = new MetaReservedArea(
                reservedArea.id,
                reservedArea.name,
                dimensions.area_start_x,
                dimensions.area_start_y,
                dimensions.area_end_x,
                dimensions.area_end_y,
                matrixBuffer,
                reservedArea.image_path,
                reservedArea.images_sprite_num,
                reservedArea.image_left_top_x,
                reservedArea.image_left_top_y,
                reservedArea.image_right_bottom_x,
                reservedArea.image_right_bottom_y,
                reservedArea.info_image,
                reservedArea.info_video,
                reservedArea.link,
                reservedArea.affiliate_link,
                reservedArea.partnership_id,
                reservedArea.partner_logo,
                reservedArea.partner_name,
                reservedArea.partner_url,
                reservedArea.partnership_data_url,
                reservedArea.images_sprite_num ? reservedAreasWithImagesCount++ : null,
            );
            metaReservedAreas[i] = metaReservedArea;
        }
        return metaReservedAreas;
    }

    protected setupOnMap (data: MetaReservedArea[]): void {
        this.map.setupReservedAreas(data);
        this.map.redraw();
    }

    protected async save (data: MetaReservedArea[]): Promise<IDBValidKey> {
        const idb = await MapCreationStep.idb;
        return idb.put('reserved-areas', {
            metaverseId: this.metaverseId,
            data,
        });
    }

    public async restoreFromIndexedDB (): Promise<boolean> {
        const savedReservedAreas = await this.loadFromIndexedDB();
        if (savedReservedAreas) {
            const matrixBuffer = this.map.matrix.matrixBuffer;
            const processedReservedAreas = savedReservedAreas.map((area) => MetaReservedArea.fromAnotherArea(area, {matrixBuffer}));
            this.setupOnMap(processedReservedAreas);
        }
        return !!savedReservedAreas;
    }

    public async createFromAPI (): Promise<void> {
        const rawReservedAreasData = await this.loadFromAPI();
        const processedReservedAreas = this.process(rawReservedAreasData);
        this.setupOnMap(processedReservedAreas);
        this.save(processedReservedAreas);
    }

    public async execute (): Promise<void> {
        await this.restoreFromIndexedDB();
        this.createFromAPI();
        if (this.nextStep) {
            return this.nextStep.execute();
        }
    }
}