import { GETTERS } from "./names";
import { GETTERS as GETTERS_WEB3_CONNECTION } from "../connection/names";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { TranslationHelper } from "~/core/services/TranslationHelper";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import { ETH_CONTRACTS } from "~/contracts/addresses";
import { BinanceSmartChainNetwork, EthereumNetwork } from "~/pages/finances/wallet/constants/WalletNetworkConstants";

const $config = getClientRuntimeConfig();

export default {
    [GETTERS.MAIN_CONTRACT_ADDRESSES](_state, _getters, _rootState, rootGetters) {
        const mwm = MetaWorldManager.sharedInstance();
        const areContractsClickable = !!rootGetters[`affility/web3/connection/${GETTERS_WEB3_CONNECTION.PROVIDER}`];
        const usdtAddress = mwm.getWeb3Contract('USDT').options.address;
        const gymnetAddress = mwm.getWeb3Contract('GymNetwork').options.address;
        const btcAddress = mwm.getWeb3Contract('BTCB').options.address;
        const moonbergAddress = ETH_CONTRACTS['Moonberg'][$config.chainId];

        return [
            {
                icon: {
                    name: 'gymnet',
                    path: 'currency',
                },
                title: areContractsClickable ?
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'GYMNET'}) :
                    'GYMNET',
                address: gymnetAddress,
                clickable: areContractsClickable,
                network: BinanceSmartChainNetwork,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: gymnetAddress,
                        symbol: 'GYMNET',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/gymnet.png',
                    }
                },
            },
            {
                icon: {
                    name: 'usdt',
                    path: 'currency',
                },
                title: areContractsClickable ?
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'USDT'}) :
                    'USDT',
                address: usdtAddress,
                clickable: areContractsClickable,
                network: BinanceSmartChainNetwork,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: usdtAddress,
                        symbol: 'USDT',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/usdt.png',
                    }
                },
            },
            {
                icon: {
                    name: 'btc',
                    path: 'currency',
                },
                title: areContractsClickable ?
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'BTCB'}) :
                    'BTCB',
                address: btcAddress,
                clickable: areContractsClickable,
                network: BinanceSmartChainNetwork,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: btcAddress,
                        symbol: 'BTCB',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/btc.png',
                    }
                },
            },
            ...(moonbergAddress ? [{
                icon: {
                    name: 'moonberg',
                    path: 'currency',
                },
                title: areContractsClickable ?
                    TranslationHelper.translate('Add TOKEN to wallet', {token: 'MNBRG'}) :
                    'MNBRG',
                address: moonbergAddress,
                clickable: areContractsClickable,
                network: EthereumNetwork,
                walletInfo: {
                    type: 'ERC20',
                    options: {
                        address: moonbergAddress,
                        symbol: 'MNBRG',
                        decimals: 18,
                        image: 'https://images.gymstreet.io/moonberg_large.png',
                    }
                },
            }] : []),
        ];
    }
}
